import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import "../../../css/parentPortal.css";
import ParentStudentCardWidget from './studentCardWidget';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-panel">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const students_info = { 
  "0": {
    studentData: {
      studentName: "Grace Langmore",
      school: "Kwenda Secondary School",
      studentClass: "Form 1 Green",
      studentLevel: "Form 1 Green",
      term: "Term 2",
      year: "2024",
      classPosition: "3",
      firstName: "Tendai",
      lastName: "Maximoff",
      results: {
        "English": {
          score: "95%",
          grade: "A",
          teacherComment: "Excellent"
        },
        "Mathematics": {
          score: "95%",
          grade: "A",
          teacherComment: "Superb"
        },
        "Physical Education and Arts": {
          score: "88%",
          grade: "A",
          teacherComment: "Excellent"
        },
        "Shona": {
          score: "60%",
          grade: "C",
          teacherComment: "Good"
        },
        "Social Studies": {
          score: "72%",
          grade: "B",
          teacherComment: "Very Good"
        },
        "Agriculture": {
          score: "99%",
          grade: "A",
          teacherComment: "Excellent"
        },
      },
      attendance: 179,
      totalDaysOfSchool: 180,
      headComment: "Student with a promising future"
    },
    schoolData: {
      schoolLogoUrl: "https://picsum.photos/seed/picsum/200/300",
      name: "Kwenda Secondary School", 
      terms: ["Term 1", "Term 2", "Term 3"],
      gradingSystem: {
        "A": {
          lowerLimit: "75%",
          upperLimit: "100%"
        },
        "B": {
          lowerLimit: "74%",
          upperLimit: "65%"
        },
        "C": {
          lowerLimit: "64%",
          upperLimit: "50%"
        },
        "D": {
          lowerLimit: "49%",
          upperLimit: "40%"
        },
        "E": {
          lowerLimit: "39%",
          upperLimit: "30%"
        },
        "U": {
          lowerLimit: "Below 30%",
          upperLimit: ""
        },
      },
      dateResultsPublished: "20/08/2024"
    }
  }, 
  "1": { 
    studentData: {
      studentName: "Elon Maximoff",
      school: "Chekugona High Secondary School",
      studentClass: "Form 6Sciences",
      studentLevel: "Form 6Sciences",
      term: "Term 2",
      year: "2024",
      classPosition: "1",
      firstName: "Elon",
      lastName: "Maximoff",
      results: { 
        "Pure Mathematics": {
          score: "95%",
          grade: "A",
          teacherComment: "Superb"
        },
        "Chemistry": {
          score: "88%",
          grade: "A",
          teacherComment: "Excellent"
        },
        "Physics": {
          score: "90%",
          grade: "A",
          teacherComment: "Excellent"
        }, 
      },
      attendance: 180,
      totalDaysOfSchool: 180,
      headComment: "An excellent student"
    },
    schoolData: {
      schoolLogoUrl: "https://picsum.photos/seed/picsum/200/300",
      name: "Chekugona High Secondary School", 
      terms: ["Term 1", "Term 2", "Term 3"],
      gradingSystem: {
        "A": {
          lowerLimit: "75%",
          upperLimit: "100%"
        },
        "B": {
          lowerLimit: "74%",
          upperLimit: "65%"
        },
        "C": {
          lowerLimit: "64%",
          upperLimit: "50%"
        },
        "D": {
          lowerLimit: "49%",
          upperLimit: "40%"
        },
        "E": {
          lowerLimit: "39%",
          upperLimit: "30%"
        },
        "U": {
          lowerLimit: "Below 30%",
          upperLimit: ""
        },
      },
      dateResultsPublished: "12/08/2024"
    }
  }
}

export default function StudentProfile() {
  const [tabValue, setTabValue] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState("0")

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedStudent(newValue);
  };

  useEffect(() => {
    console.log(tabValue)
  }, [tabValue])

  return (
    <Box sx={{ width: '100%' }}>
      {/* Tabs Header */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className="tabs-container"
        TabIndicatorProps={{ style: { display: 'none' } }} // Hide default indicator
      >
        {
          Object.keys(students_info).map((info, i) => (
            <Tab label={students_info[info].studentData.studentName} className="custom-tab" />
          ))
        } 
      </Tabs>

      {/* Tab Panels */}
      { 
        <TabPanel value={tabValue} index={tabValue}>
          <ParentStudentCardWidget
            theKey={tabValue}
            studentName={students_info[selectedStudent].studentData.studentName}
            school={students_info[selectedStudent].studentData.school}
            studentClass={students_info[selectedStudent].studentData.studentClass}
          />
        </TabPanel>  
      } 
    </Box>
  );
}