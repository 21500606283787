import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { handleForgetPwd } from '../../controllers/authenticationAPIs/forgetPwdAPI';
import '../../css/tempLoginCSS.css';
import Nav from '../../components/layout/Nav';
import Footer from '../../components/layout/Footer';

const ConfirmOTP = () => {
    const [email, setEmail] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(null);

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = await handleForgetPwd(email);

        if (data.error) {
            setError(data.error);
        } else {
            setResponseData(data);
            setError(null);
        }
    };

    return (
        <section className="login-auth-main-container">
            <Nav />
            <h1>Confirm the OTP</h1>
            <div className="login-auth-container">
        
                <h2>Confirm the OTP sent to your email</h2>
                <form onSubmit={onSubmit}>
                    <div className="login-auth-form-group">
                        <label>One Time Password (OTP):</label>
                        <input
                            type="email"
                            placeholder='Enter OTP'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="login-auth-form-input" 
                        />
                    </div>
                    
                    <div>
                        <button type="submit" className="login-auth-submit-button">Proceed</button>
                    </div>

         

                    {responseData && <div className="login-auth-success-message">Success: {JSON.stringify(responseData)}</div>}
                    {error && <div className="login-auth-error-message">Error: {error}</div>}
                </form>
            </div>
            <Footer />
        </section>
    );
};

export default ConfirmOTP;


  