import StudentPortalSideBar from "../../components/layout/studentPortalSideBar";
import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import StudentPortalWidgets from "../../components/widgets/studentPortalWidget";
import "../../css/admin.css";
import { firstName, lastName } from "../../globalConfigs/userLoginInfo";
import '../../css/studentPortal.css';
import { imageIcons } from "../../components/imageComponents";

const StudentPortal = () => {
  const school_logo_url = "https://picsum.photos/seed/picsum/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const school_name = "Jesse Pinkman High School"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_name = "Jane Doe"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_profile_pic = "https://picsum.photos/id/28/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const school_address = "123 Josiah Tongogara Way Ruwa Harare Zimbabwe"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
 
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <StudentPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Dashboard</h1>
            <h3 className="studentName">{ firstName } { lastName }</h3>
            <div className="info">
              
              <StudentPortalWidgets
                total="11"
                description="Subjects"
              />
              <StudentPortalWidgets
                total="3 Green"
                description="Class"
              />
              <StudentPortalWidgets
                total="Marimo"
                description="Teacher"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default StudentPortal;
