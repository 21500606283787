// src/components/Pagination.js
import React from 'react';
import '../../../../css/manageResults.css';

const Pagination = ({ itemsPerPage, totalItems, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="pagination">
      <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>◀</button>
      {[...Array(totalPages).keys()].map(page => (
        <button key={page + 1} onClick={() => onPageChange(page + 1)} className={page + 1 === currentPage ? 'active' : ''}>
          {page + 1}
        </button>
      ))}
      <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>▶</button>
    </div>
  );
};

export default Pagination;
