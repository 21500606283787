import "../../css/total_stats.css";

const TotalStats = ({ total, description, icon_url, custom_bg_color }) => {
  const styles = {
    icon_styles: {
      backgroundImage: icon_url,
      backgroundSize: "30px auto",
      backgroundPosition: "center calc(100% - 12px)",
      backgroundRepeat: "no-repeat",
    },
    total_stats_styles: {
      backgroundColor: custom_bg_color,
    },
  };

  const { icon_styles, total_stats_styles } = styles;

  return (
    <div className="total_stats" style={total_stats_styles}>
      <div className="total_stats__left">
        <span>{total}</span>
        <span>{description}</span>
      </div>
      <div className="total_stats__right" style={icon_styles}></div>
    </div>
  );
};

export default TotalStats;
