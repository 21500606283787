import React from 'react';
import NavBar from '../../../components/layout/navBar';
import Sidebar from '../../../components/layout/sideBar';
import '../../../css/mainDashboard.css';
import Footer from '../../../components/layout/Footer';
import SchoolRequests from './schoolRequests';
import SchoolEnrolmentStats from './requestsStats';

const SchoolEnrolmentRequests = () => {
    return (
        <div className="app">
            <NavBar />
            <div className="main-content">
                <Sidebar />
                <div className="dashboard">
                    <SchoolEnrolmentStats />


                    {/* Dashboard content */}
                    <SchoolRequests />

                </div>
            </div>
            
        </div>
    );
};

export default SchoolEnrolmentRequests;
