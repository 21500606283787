import React, { useState, useEffect } from 'react';
import '../../../../css/manageResults.css';
import Pagination from '../../resultsManagement/resultsUtils/pagination.js';
import ExamPopup from './createExamPopUp.js';
import { Alert, CircularProgress } from '@mui/material';
import { API_URL } from '../../../../globalConfigs/apiConfigs.js';
import { role } from '../../../../globalConfigs/userLoginInfo.js';
import MarkingExamsPopup from './markingPopUp.js';
import { token } from '../../../../globalConfigs/userLoginInfo.js';
import { apiHeaders } from '../../../../globalConfigs/apiConfigs.js';
import { schoolID } from '../../../../globalConfigs/userLoginInfo.js';
let API_TOKEN = "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6W3siYXV0aG9yaXR5IjoiREVMRVRFX1BSSVZJTEVHRSJ9LHsiYXV0aG9yaXR5IjoiUkVBRF9QUklWSUxFR0UifSx7ImF1dGhvcml0eSI6IlVQREFURV9QUklWSUxFR0UifSx7ImF1dGhvcml0eSI6IldSSVRFX1BSSVZJTEVHRSJ9LHsiYXV0aG9yaXR5IjoiUk9MRV9BRE1JTiJ9XSwic3ViIjoiZWxvZXRoQGdtYWlsLmNvbSIsImlhdCI6MTczMTU3ODMyNSwiZXhwIjoxNzMxNTk2MzI1fQ.m4BuUO20VahlaiBNz25VaA9frMItL8f5LXOio5HDdNw";

const ManageExamList = () => {
    const [exams, setExams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [filterStatus, setFilterStatus] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMarkingExamsPopupOpen, setIsMarkingExamsPopupOpen] = useState(false);
    const [selectedExam, setSelectedExam] = useState(null);
    const teacherId = 'YOUR_TEACHER_ID'; // Replace with the actual teacher ID
    const [studentClasses, setClasses] = useState([]);
    const [studentsSchool, setStudentSchool] = useState([]);

    const [grades, setGrades] = useState([]);
  
  
      useEffect(() => {
          const fetchGrades = async () => {
              setLoading(true);
              try {
                  const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/levels`, { headers: apiHeaders });
                  const data = await response.json();
                  console.log("--------------------grades-------------------")
                  console.log(data);
                  setGrades(data);
                  console.log("--------------------grades-------------------")
              } catch (err) {
                  console.error('Error fetching grades:', err);
              } finally {
                  setLoading(false);
              }
          };
  
          fetchGrades();
      }, []);



    //   ===========================set students for a school=================================
    useEffect(() => {
        const fetchStudentsSchool = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/students/school/51`, 
                    { headers: {
                        // 'Content-Type': 'application/json',
                        Authorization: `Bearer ${API_TOKEN}`,
                      }});
                const data = await response.json();
                console.log("--------------------students for school-------------------");
                setStudentSchool(data);
                console.log(data);
                console.log(studentsSchool);
                console.log("students for a school");

            } catch (err) {
                console.error('Error fetching students for a particular school:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchStudentsSchool();
    }, []);
    // =======================================================================================
  
      // display the name part of the levelgradeID not the UUID
    const findLevelGradeName = (levelGradeId) => {
      const levelGrade = grades.find(item => item.levelGradeId === levelGradeId);
      return levelGrade ? levelGrade.name : 'N/A';
    };
  
  
  

    useEffect(() => {
        const fetchExams = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/teachers/75/exams`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                console.log("exams=============================================")
                console.log(data);
                console.log("exams=============================================")
                
                // Ensure that `data` is an array before setting it to `exams`
                if (Array.isArray(data)) {
                    setExams(data);
                } else {
                    console.error("Expected data to be an array, but got:", data);
                    setExams([]); // Set to an empty array if data is not an array
                }
            } catch (error) {
                console.error("Error fetching exams:", error);
                setExams([]); // Set to an empty array on error
            } finally {
                setLoading(false);
            }
        };
    
        fetchExams();
    }, [teacherId]);
    

    const handleGradeClick = (exam) => {
        setSelectedExam(exam);
        examsMarkingOpenPopup(true);
    };

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    const examsMarkingOpenPopup = () => setIsMarkingExamsPopupOpen(true);
    const examsMarkingClosePopup = () => setIsMarkingExamsPopupOpen(false);
    

    const filteredData = filterStatus ? exams.filter(item => item.exam_status === filterStatus) : exams;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const handleFilterChange = (event) => setFilterStatus(event.target.value);
    const applyFilter = () => setCurrentPage(1);

    return (
        <div className="table-container">
            <div className="manage_teachers_add_teacher_heading_container">
                <div>Exam Creation:</div>
                <div onClick={openPopup} className="manage_teachers_add_teacher_heading_item">Create an Exam</div>
            </div>
            <br />

            <div className="manage_teachers_sort_by_filter_by_container">
                <div className="manage_teachers_sort_by_filter_by_item">
                    <div className="manage_teachers_sort_by_filter_by_forms_title">Sort By</div>
                    <form className="manage_teachers_sort_by_filter_by_forms">
                        <select>
                            <option>None</option>
                            <option>Class Name</option>
                            <option>Level</option>
                        </select>
                    </form>
                </div>
                <div className="manage_teachers_sort_by_filter_by_item">
                    <div className="manage_teachers_sort_by_filter_by_forms_title">Filter By</div>
                    <form className="manage_teachers_sort_by_filter_by_forms">
                        <select onChange={handleFilterChange}>
                            <option>None</option>
                            <option>Form 1</option>
                            <option>Form 2</option>
                            <option>Form 3</option>
                            <option>Form 4</option>
                            <option>Form 5</option>
                            <option>Form 6</option>
                        </select>
                    </form>
                </div>
            </div>
            <br />

            {loading ? (
                <CircularProgress />
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Exam Title</th>
                            <th>Weight</th>
                            <th>Out of</th>
                            <th>Paper No.</th>
                            <th>Exam Date</th>
                            <th>Level</th>
                            <th>Academic Year</th>
                            <th>Exam Status</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.examName}</td>
                                <td>{item.weight}</td>
                                <td>{item.outOf}</td>
                                <td>{item.paperNo}</td>
                                <td>{new Date(item.examDate).toLocaleString()}</td>
                                <td>{item.levelId}</td>
                                {/* <td>{findLevelGradeName(item.levelId)}</td> */}
                                {role === "TEACHER" ? <td>{item.year}</td> :
                                <td>{item.year.value}</td>}
                               
                                <td>{item.exam_status || 'Pending'}</td>
                                <td onClick={() => handleGradeClick(item)}><button className="edit-btn">✎</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            
            {<ExamPopup isOpen={isPopupOpen} onClose={closePopup} />}



            {<MarkingExamsPopup
              isOpen={isMarkingExamsPopupOpen}
              onClose={examsMarkingClosePopup}
            // //   className={selectedExam.classes.join(', ')}
            // //   examTitle={selectedExam.title}
            // //   totalMarks={selectedExam.totalMarks}
            // />
            all_students = {studentsSchool}
             />}

            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={filteredData.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ManageExamList;
