import AdminLink from "../widgets/AdminLink";
import { role } from "../../globalConfigs/userLoginInfo";

const AdminLeftSidebarNav = () => {
  return (
    <div className="left_side_nav">
     {role === 'TEACHER' ? 
     <>
     <AdminLink
        url_link="/admin/exam-management"
        icon_url={"url('/images/exam-mgt.png')"}
        description="Exam Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/results-management"
        icon_url={"url('/images/results-mgt.png')"}
        description="Results Management"
        is_sidebar_link={true}
      />


    
     </> : <>
     <AdminLink
        url_link="/admin"
        icon_url={"url('/images/chart.png')"}
        description="Dashboard"
        is_sidebar_link={true}
      />
      
      <AdminLink
        url_link="/admin/student-management"
        icon_url={"url('/images/student_avatar.png')"}
        description="Student Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/teacher-management"
        icon_url={"url('/images/teacher_avatar.png')"}
        description="Teacher Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/class-management"
        icon_url={"url('/images/classroom.png')"}
        description="Class Management"
        is_sidebar_link={true}
      />

      <AdminLink
        url_link="/admin/parent-management"
        icon_url={"url('/images/paent_icon.png')"}
        description="Parent Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/exam-management"
        icon_url={"url('/images/exam-mgt.png')"}
        description="Exam Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/results-management"
        icon_url={"url('/images/results-mgt.png')"}
        description="Results Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/communications_centre"
        icon_url={"url('/images/comments_speech.png')"}
        description="Communications Centre"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/menage-school-info"
        icon_url={"url('/images/gear_solid.png')"}
        description="School Settings"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/school_events_and_announcements"
        icon_url={"url('/images/announcement.png')"}
        description="Events and Announcements"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/profile_section"
        icon_url={"url('/images/profile.png')"}
        description="Profile Section"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/documents_section"
        icon_url={"url('/images/document.png')"}
        description="Documents Section"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/settings_section"
        icon_url={"url('/images/gear_solid.png')"}
        description="Settings Section"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/statistics"
        icon_url={"url('/images/chart.png')"}
        description="Statistics"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/getting_started"
        icon_url={"url('/images/getting_started.png')"}
        description="Getting Started"
        is_sidebar_link={true}
      />

      </>}
    </div>
  );
};

export default AdminLeftSidebarNav;
