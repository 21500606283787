import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import '../../../../css/classManagement.css';
import '../../../../css/schoolInfo.css';
import { API_URL } from '../../../../globalConfigs/apiConfigs';
import { schoolID, token } from '../../../../globalConfigs/userLoginInfo';


const AssignTeacher = ({ isOpen, onClose, selectedTeacher }) => {

    const [selectedGrade, setSelectedGrade] = useState('');
    const [error, setError] = useState('');
    const [grades, setGrades] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [getClass, setClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [subjectName, setSelectedSubjects] = useState([]);
    const [selectClass, setSelectedClass] = useState('');
    const [selectedTerm, setSelectedTerm] = useState([]);
    const [terms, setTerms] = useState([]);
    const [examName, setExamName] = useState([]);
    const [paperNumber, setPaperNumber] = useState([]);
    const [paperMark, setPaperMark] = useState([]);
    const [paperWeight, setPaperWeight] = useState([]);
    const [examDate, setExamDate] = useState([]);
    const [examYear, setExamYear] = useState([]);
    const [teachers, setTeachers] = useState([]);
 

    useEffect(() => {
        const fetchGrades = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/levels`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setGrades(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchGrades();
    }, []);


    useEffect(() => {
        const fetchClasses = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/classes`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setClasses(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchClasses();
    }, []);


    useEffect(() => {
        const fetchSubjects = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/subjects`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setSubjects(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchSubjects();
    }, []);

    useEffect(() => {
        const fetchTerm = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/terms`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setTerms(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTerm();
    }, []);

    useEffect(() => {
        const fetchTeachers = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/teachers/all/schools/${schoolID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setTeachers(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTeachers();
    }, []);

    if (!isOpen) return null;

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            const response = await fetch(`${API_URL}/api/v1/teachers/${selectedTeacher.id}/assign/subject_class`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(
                    {
                        classId: selectClass,
                        subjectId: subjectName,
                        termId: selectedTerm,
                        levelId: selectedGrade,
                        year: "2024",
                    }
                ),
            });

            console.log(JSON.stringify(
                {
                    classId: selectClass,
                    subjectId: subjectName,
                    termId: selectedTerm,
                    levelId: selectedGrade,
                    year: "2024",
                }
            ),);
              
            if (response.ok) {
                console.log('Teacher assigned to subject created successfully');
                onClose();
            } else {
                const errorData = await response.json();
                console.log(errorData);
                console.error('Error creating class:', errorData);
                setError('Failed to create class');
            }
        } catch (err) {
            console.error('Error creating class:', err);
            setError('Failed to create class');
        } finally {
            setSubmitting(false);
        }
    };

    console.log(token);

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <button className="popup-close-btn" onClick={onClose}>✕</button>
                <h2>Assign Teacher
                    {selectedTeacher ? (
                        <>
                            <p>{selectedTeacher.firstName} {selectedTeacher.lastName}</p>
                        </>
                    ) : (
                        <p>No teacher selected</p>
                    )}
                </h2>

                <div className="popup-input">
                    <label>Select level/grade</label>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <select
                            value={selectedGrade}
                            onChange={(e) => setSelectedGrade(e.target.value)}
                        >
                            <option value="">Select the level/grade</option>
                            {grades.map((grade) => (
                                <option key={grade.levelGradeId} value={grade.levelGradeId}>
                                    {grade.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="popup-input">
                    <label>Select Class</label>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <select
                            value={selectClass}
                            onChange={(e) => setSelectedClass(e.target.value)}
                        >
                            <option value="">Select class</option>
                            {getClass.map((cl) => (
                                <option key={cl.classId} value={cl.classId}>
                                    {cl.className}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="popup-input">
                    <label>Select subject</label>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <select
                            value={subjectName}
                            onChange={(e) => setSelectedSubjects(e.target.value)}
                        >
                            <option value="">Select Subject</option>
                            {subjects.map((sub) => (
                                <option key={sub.subjectId} value={sub.subjectId}>
                                    {sub.subjectName}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="popup-input">
                    <label>Select term</label>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <select
                            value={selectedTerm}
                            onChange={(e) => setSelectedTerm(e.target.value)}
                        >
                            <option value="">Select Term</option>
                            {terms.map((term) => (
                                <option key={term.schoolTermId} value={term.schoolTermId}>
                                    {term.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>


                <button className="submit-btn" onClick={handleSubmit}>
                    {submitting ? "Please wait..." : "Submit"}
                    {submitting && (
                        <CircularProgress size={20} color='white' />
                    )}
                </button>

            </div>
        </div>
    );
};

export default AssignTeacher;
