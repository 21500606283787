import "../../../css/cardStyle.css";
import React, { useState } from 'react';
import { imageIcons } from "../../../components/imageComponents";
import ReportCardModal from "../studentReportCardModal";

const ParentStudentCardWidget = ({ studentName, school, studentClass, theKey, setSelectedStudent, setIsSchoolReportHidden, keyVal  }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };


    return (
        <div className="total_stats2" key={theKey} da={theKey} >
            <div className="total_stats2__left">
                <span>
                    <img
                        src={imageIcons.graduate}
                        alt="Graduate"
                        className='widgetImg'
                    />
                    {studentName}
                </span>
                <span>
                    <img
                        src={imageIcons.school_premise}
                        alt="Graduate"
                        className='widgetImg'
                    />
                    {school}
                </span>

                <span>
                    <img
                        src={imageIcons.classroom_icon}
                        alt="Graduate"
                        className='widgetImg'
                    />
                    {studentClass}
                </span>

                <div className="subjectInfo">
                    <div className="pHeaderIcon">
                        <span>
                            <img
                                src={imageIcons.book_icon}
                                alt="Graduate"
                                className='widgetImg'
                            />
                            <strong className="pHeading">Current subjects</strong>
                        </span>
                    </div>
                    <span>
                        <div className="subjectList">Test Test test</div>
                        <div className="subjectList">Test</div>
                        <div className="subjectList">Test</div>
                        <div className="subjectList">Test</div>
                    </span>

                </div>

                <div className="subjectInfo1">
                    <div className="pHeaderIcon">
                        <span>
                            <img
                                src={imageIcons.exam_ico}
                                alt="Graduate"
                                className='widgetImg'
                            />
                            <strong className="pHeading">Exam Results</strong>
                        </span>
                    </div>
                    <span>
                        <div className="subjectList1">
                            Test Test test <br></br>
                            <hr/><br></br>
                            Grade: A<br></br>
                            Score: B<br></br>
                        </div>

                        <div className="subjectList1">
                            Test Test test <br></br>
                            <hr/><br></br>
                            Grade: A<br></br>
                            Score: B<br></br>
                        </div>

                        <div className="subjectList1">
                            Test Test test <br></br>
                            <hr/><br></br>
                            Grade: A<br></br>
                            Score: B<br></br>
                        </div>

                        <div className="subjectList1">
                            Test Test test <br></br>
                            <hr/><br></br>
                            Grade: A<br></br>
                            Score: B<br></br>
                        </div>
                    </span>

                </div>

                <div className="reportCard1" onClick={openModal}>
                    <span>
                        <img
                            src={imageIcons.graduate_cap}
                            alt="Graduate"
                            className='widgetImg'
                        />
                        {/* View Report Card */}
                        <button
                            onClick={() => {
                                setSelectedStudent(keyVal)
                                setIsSchoolReportHidden(false)
                            }}
                        >View Latest Report Card</button>
                    </span>

                </div>


            </div>
            
            <ReportCardModal isOpen={isModalOpen} onClose={closeModal} />
        </div>

    );
};

export default ParentStudentCardWidget;

