import React from 'react';
import '../../../css/utils/requestStats.css';
import { imageIcons } from '../../../components/imageComponents';
import FetchSchoolRequests from '../../../controllers/superAdminAPIs/fetchSchoolRequests';
import ProgressIndicator from '../../../globalConfigs/progressIndicator';

const SchoolEnrolmentStats = () => {
    const { approvedCount, pendingCount, rejectedCount, loading, error } = FetchSchoolRequests();

    if (loading) {
        return <div><ProgressIndicator/></div>;
    }

    if (error) {
        return <div>Error loading statistics: {error.message}</div>;
    }

    return (
        <div className="my-cards-wrapper">
            <div className="card-container">
                <div className="my-card card-pending-requests">
                    <div className="my-card-text-1">
                        <strong>{pendingCount}</strong><br />Pending Requests
                    </div>
                    <img src={imageIcons.enrollment_black} alt="Pending" className="card-icon" />
                </div>
                <div className="my-card card-approved-requests">
                    <div className="my-card-text">
                        <strong>{approvedCount}</strong><br />Approved Requests
                    </div>
                    <img src={imageIcons.enrollment_black} alt="Approved" className="card-icon" />
                </div>
                <div className="my-card card-rejected-requests">
                    <div className="my-card-text">
                        <strong>{rejectedCount}</strong><br />Rejected Requests
                    </div>
                    <img src={imageIcons.enrollment_black} alt="Rejected" className="card-icon" />
                </div>
            </div>
        </div>
    );
};

export default SchoolEnrolmentStats;

