import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { imageIcons } from "../imageComponents";
import '../../css/sideBar.css';

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();


  // Function to check if the current item is active
  const isActive = (itemPath) => {
    return location.pathname === itemPath;
  };


  return (
    <div className="divSideBar">
      <div className="menuItem"
        style={{
          backgroundColor: isActive("/") ? "white" : "transparent",
        }}
        onClick={() => setActiveItem("/")}
      >
        <img
          src={imageIcons.stats}
          alt=""
          className="sideBarImg"
        />
        Statistics
      </div>
      <hr />
      <Link className="menuItem"
        to="/superadmin-dashboard/school-enrolment-requests"
        style={{
          backgroundColor: isActive("/superadmin-dashboard/school-enrolment-requests")
            ? "white"
            : "transparent",
        }}
        onClick={() =>
          setActiveItem("/superadmin-dashboard/school-enrolment-requests")
        }
      >
        <img
          src={imageIcons.enrollment}
          alt=""
          className="sideBarImg"
        />
        School Enrollment
      </Link>
      <hr />
      <Link className="menuItem"
        to="/superadmin-dashboard/enquiries-from-schools"
        style={{
          backgroundColor: isActive("/superadmin-dashboard/enquiries-from-schools")
            ? "white"
            : "transparent",
        }}
        onClick={() =>
          setActiveItem("/superadmin-dashboard/enquiries-from-schools")
        }
      >
        <img
          src={imageIcons.enquiry}
          alt=""
          className="sideBarImg"
        />
        Enquiries
      </Link>
      <hr />

      {/* Footer menu item: Get Started */}
      <div className="footerItem">
        <div
          style={{
            backgroundColor: isActive("/get-started") ? "white" : "transparent",
          }}
          onClick={() => setActiveItem("/get-started")}
        >
          <img
            src={imageIcons.get_start}
            alt=""
            className="sideBarImg"
          />
          Getting Started
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
