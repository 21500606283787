import React, { useState } from 'react';
import "../../../../css/admin.css";
import  "../../../../css/teacherManagement.css";
import manage_teacher_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import TeacherManagementModalPopUp from './teacherManagementModalPopUp';
import TeacherManagementAddTeacherLoader from './teacherManagementAddTeacherLoader';
import default_user from "../../../../svg/user.svg"

export default function TeacherManagementViewTeacherDetails({ onClose, teacher_id }){
    const [displaymodalpopup, setDisplayModalPopup] = useState(false);

    let all_teachers = JSON.parse(localStorage.getItem("all_teachers"));
    let selected_teacher = all_teachers.find(item => item.id === teacher_id);

    const qualificationsText = selected_teacher.qualifications.map(qual =>
        `${qual.name} from ${qual.acquiredFrom} (Certified: ${qual.dateCertified})`
    ).join(', ');

    const certifications =  selected_teacher.certifications.map(cert =>
        `${cert.name} from ${cert.acquiredFrom} (Certified: ${cert.dateCertified})`
    ).join(', ');

    const subjectsSpecialization = selected_teacher.subjectsSpecialization.map(sub =>
        `${sub.name})`
    ).join(', ');

    const coursesAssigned = selected_teacher.coursesAssigned.map(sub =>
        `${sub.name})`
    ).join(', ');

    const languagesSpoken = selected_teacher.languagesSpoken.map(lang =>
        `${lang.name})`
    ).join(', ');


    const handleModalPopUp = () => {
        setDisplayModalPopup(!displaymodalpopup)
       }
    return <div className="manage_teachers_view_edit_teacher_details_main_container">
               <div>
                    {displaymodalpopup && <TeacherManagementModalPopUp onClose={onClose} text={"Teacher being deleted please wait ..."} icon={<TeacherManagementAddTeacherLoader />} />}
                </div>
                <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_teacher_icon_cancelbutton} />
                </div>
                <div className="manage_teachers_addteacher_editstatus_sub_container_title">
                    <h3>View/Edit details for Ron Beasley</h3>
                </div>


               
                <div className='manage_teachers_view_edit_teacher_details_container'>
                    <form className='manage_teachers_view_edit_teacher_details_form'>
                        <div className='manage_teachers_view_edit_teacher_details_picture_section_container'>
                            <div className='manage_teachers_view_edit_teacher_details_picture_section_sub_container'>
                                <div>
                                    <img src={selected_teacher.photo || default_user} />
                                </div>
                                <div>
                                    <label>Upload a profile picture</label>
                                    <input type='file' />
                                </div>
                            </div>
                            <div>
                                <label>Name</label>
                                <input type='text' defaultValue={selected_teacher.firstName} />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Email</label>
                                <input type='text' defaultValue={selected_teacher.email} placeholder='Enter alternate phone ' />
                            </div>
                            <div>
                                <label>Phone Number</label>
                                <input type='text' defaultValue={selected_teacher.phone} placeholder='+263 733888883' />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Alternate phone number</label>
                                <input type='text' defaultValue={selected_teacher.alternatePhoneNumber} placeholder='Enter alternate phone ' />
                            </div>
                            <div>
                                <label>Birthday</label>
                                <input defaultValue={selected_teacher.birthday} type='date' />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Address</label>
                                <textarea defaultValue={selected_teacher.address}></textarea>
                            </div>
                            <div>
                                <label>Nationality</label>
                                <input type='text' defaultValue={selected_teacher.nationality} placeholder='Zimbabwe' />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Qualifications/Degrees (separated by commas)</label>
                                <textarea defaultValue={qualificationsText}></textarea>
                            </div>
                            <div>
                                <label>Certifications/Accreditations (separated by commas)</label>
                                <textarea defaultValue={certifications}></textarea>
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Subjects Specialisation (separated by commas)</label>
                                <textarea defaultValue={subjectsSpecialization}></textarea>
                            </div>
                            <div>
                                <label>Courses/Subjects Assigned (grade/level) (separated by commas)</label>
                                <textarea  defaultValue={coursesAssigned}></textarea>
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Hire date (when the teacher was hired)</label>
                                <input type='date' placeholder='05/10/2019' />
                            </div>
                            <div>
                                <label>Emergency contact name</label>
                                <input type='text' placeholder='Godwin Konami' />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Emergency contact relationship</label>
                                <input type='text' placeholder='Cousin' />
                            </div>
                            <div>
                                <label>Gender</label>
                                <select>
                                    <option>Male</option>
                                    <option>FeMale</option>
                                </select>
                            </div>
                        </div>


                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Select employment type</label>
                                <select>
                                    <option>Full-time</option>
                                    <option>Part Time</option>
                                </select>
                            </div>
                            <div>
                                <label>Years of experience</label>
                                <select>
                                    <option>none</option>
                                </select>
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label> Languages spoken (separated by commas)</label>
                                <textarea defaultValue={languagesSpoken}></textarea>
                            </div>
                        </div>
                       
                        <div className="manage_teachers_deletez_teacher_form_submit_button_container">
                            <div  onClick={handleModalPopUp} className="manage_teachers_view_edit_teacher_details_submit_button">
                                Submit
                            </div>
                        </div>
                    </form>
                </div>

            </div>

}