import manage_teacher_icon_cancelbutton from "../../../../svg/cancelbutton.svg"

export default function AdminParentManagementModalPopUp({ onClose, text, icon }){
          
    return <div className="manage_teachers_modal_popup_container">
                <div className="manage_teachers_modal_popup_overflow_container">
                </div>
                <div className="manage_teachers_modal_popup_sub_container">
                    <div className="manage_teachers_modal_popup">
                        <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                            <img src={manage_teacher_icon_cancelbutton} />
                        </div>
                        <div className="manage_teachers_modal_popup_text_icon">
                            <h3>{text}</h3>
                            <div>{icon}</div>
                        </div>
                    </div>
                </div>
            </div>
}