import React, { useState } from 'react';
import '../../../css/studentPortal.css';

const ExamTable = () => {
  const [selectedExam, setSelectedExam] = useState(null);

  const exams = [
    { title: 'Statistics Paper 2', course: 'Statistics - O\'Level', date: 'Friday 22 Nov 2024', status: 'Not Attempted', mark: 100 },
    { title: 'Agriculture Paper 2', course: 'Agriculture - O\'Level', date: 'Tuesday 10 Sept 2024', status: 'Not Attempted', mark: 90 },
    { title: 'Pure Mathematics', course: 'Pure Mathematics - O\'Level', date: 'Thursday 5 Sept 2024', status: 'Attempted', mark: 85 },
    { title: 'Shona Paper 1', course: 'Shona - O\'Level', date: 'Thursday 5 Sept 2024', status: 'Not Attempted', mark: 70 },
    { title: 'Geography Paper 2', course: 'Geography - O\'Level', date: 'Monday 2 Sept 2024', status: 'Attempted', mark: 95 },
  ];

  const handleIconClick = (exam) => {
    setSelectedExam(exam);
  };

  const closeModal = () => {
    setSelectedExam(null);
  };

  return (
    <div className="exam-table-container">
      <p>Here are this term's exams</p>
      <span>
      <div className="filters">
        <div className="sort-filter">
          <label>Sort by:</label>
          <input type="text" placeholder="None" />
        </div>
        <div className="sort-filter">
          <label>Filter by:</label>
          <input type="text" placeholder="None" />
        </div>
      </div>
      </span>
      <table className="exam-table">
        <thead>
          <tr>
            <th>Exam title</th>
            <th>Course/Subject</th>
            <th>Date of exam</th>
            <th>Exam Status</th>
            <th>View exam details</th>
          </tr>
        </thead>
        <tbody>
          {exams.map((exam, index) => (
            <tr key={index}>
              <td>{exam.title}</td>
              <td><span className="course-label">{exam.course}</span></td>
              <td>{exam.date}</td>
              <td className={exam.status === 'Attempted' ? 'status attempted' : 'status not-attempted'}>{exam.status}</td>
              <td><span className="view-icon" onClick={() => handleIconClick(exam)}>👁️</span></td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for exam details */}
      {selectedExam && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>×</span>
            <h2>Exam details for <span className="exam-title">{selectedExam.title}</span></h2>
            <p><span className="icon">🕒</span> {selectedExam.date}</p>
            <p>Exam Status: <strong className={selectedExam.status === 'Attempted' ? 'attempted' : 'not-attempted'}>{selectedExam.status}</strong></p>
            <p>Total Mark: <strong>{selectedExam.mark}</strong></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamTable;
