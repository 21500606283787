
import { API_URL, forgetPwd } from '../../globalConfigs/apiConfigs';

export const handleForgetPwd = async (email) => {
    const requestData = {
        email
    };

    try {
        const response = await fetch(API_URL + forgetPwd + email, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data; // Return the data on success
    } catch (err) {
        return { error: err.message }; // Return the error on failure
    }
};