import ParentPortalSideBar from "../../components/layout/parentPortalSideBar";
import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import "../../css/admin.css";
import StudentProfile from "./parentPortalUtils/tabbedActivity";
import {
  are_there_new_notifications,
  user_name,
} from "../../globalConfigs/userLoginInfo";
import StudentReport from "../../components/widgets/StudentReport.js";
import { useState } from "react";

const ParentStudents = () => {
  const [isSchoolReportHidden, setIsSchoolReportHidden] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState("0");
  const [tabValue, setTabValue] = useState(0);

  const students_info = JSON.parse(localStorage.getItem("students_info"));

  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={"/images/logo_container.svg"}
        school_name={""}
        user_name={user_name}
        user_profile_pic={""}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <ParentPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Students</h1>
            <div className="info">
              <StudentProfile
                setIsSchoolReportHidden={setIsSchoolReportHidden}
                setSelectedStudent={setSelectedStudent}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`school_report_container ${
          !isSchoolReportHidden && "displayed_flex"
        }`}
      >
        <StudentReport
          isSchoolReportAPopup={true}
          setIsSchoolReportHidden={setIsSchoolReportHidden}
          studentData={students_info[selectedStudent].studentData}
          schoolData={students_info[selectedStudent].schoolData}
          isSchoolReportHidden={isSchoolReportHidden}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </div>
      <Footer />
    </section>
  );
};

export default ParentStudents;
