import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../../../../css/admin.css";
import  "../../../../css/teacherManagement.css";
import manage_teacher_icon_question_mark from "../../../../svg/Group 374.svg"
import AdminParentmanegementTooltip from './adminParentmanegementTooltip';
import manage_teacher_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import AdminParentManagementModalPopUp from './adminParentManagementModalPopUp';
import AdminParentManagementAddParentLoader from './adminParentManagementAddParentLoader';

export default function AdminParentManagementAddParentBulk({ onClose }){
    const [displaymodalpopup, setDisplayModalPopup] = useState(false)

    const handleModalPopUp = () => {
        setDisplayModalPopup(!displaymodalpopup)
       }
    return <div className="manage_teachers_addteacher_sub_container">
               <div>
                    {displaymodalpopup && <AdminParentManagementModalPopUp onClose={onClose} text={"Parents being added please wait ..."} icon={<AdminParentManagementAddParentLoader />} />}
                </div>
                <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_teacher_icon_cancelbutton} />
                </div>
                <div className="manage_teachers_addteacher_bulk_sub_container_title">
                    <h3>Bulk add teachers via a CSV file</h3>
                    <div>
                        <AdminParentmanegementTooltip text={"A CSV file is a simple text file used to store data in rows and columns. The columns required are First Name, Last Name, Phone Number, Date of birth, Nationality, Address, Email and Subject(s) (subjects separated by commas if a teacher teaches more than one subject)"} >
                        <Link to={"#"}>
                            <img src={manage_teacher_icon_question_mark} alt="Question Mark" style={{ width: '20px', height: '22px' }}  />
                        </Link>
                        </AdminParentmanegementTooltip>
                    </div>
                </div>
               
                <div>
                    <form>
                        <div className="manage_teachers_addteacher_bulk_form_item">
                            <div>
                                <label>Upload the csv file</label>
                                <input type="file" name="phone-of-teacher" placeholder="" />
                            </div>
                        </div>
                        <div className="manage_teachers_addteacher_form_submit_button_container">
                            <div  onClick={handleModalPopUp} className="manage_teachers_addteacher_form_submit_button">
                                Upload
                            </div>
                        </div>
                    </form>
                </div>

            </div>

}