import Footer from "../../components/layout/Footer";
import Nav from "../../components/layout/Nav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FormInput from "../../components/widgets/FormInput";
import {
  addInputDataToFormData,
  zimbabweanPhoneNumberValidator,
} from "../../utility_functions/util";
import { useEffect, useState } from "react";
import "../../css/register_parent.css";
import SuccessErrorModal from "../../components/widgets/SuccessErrorModal";
import { API_TOKEN, API_URL } from "../../globalConfigs/apiConfigs";
import Loading from "../../components/widgets/Loading";

const RegisterParent = () => {
  const { dynamicPath } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const phonenumber = queryParams.get("phoneNumber");

  const pathName = location.pathname;

  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState(false);
  const [hasFormSubmittedSuccessfully, setHasFormSubmittedSuccessfully] =
    useState(false);

  const [isProvinceSelectionErrorHidden, setIsProvinceSelectionErrorHidden] =
    useState(true);
  const [isDistrictSelectionErrorHidden, setIsDistrictSelectionErrorHidden] =
    useState(true);
  const [districtError, setDistrictError] = useState("");

  const [showLoading, setShowLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");

  const [parentPhoneNumber, setParentPhoneNumber] = useState(phonenumber);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log(pathName)
    if (!phonenumber || !zimbabweanPhoneNumberValidator(phonenumber)) {
      navigate("/");
      return;
    }
    setParentPhoneNumber(phonenumber);
  }, [phonenumber, navigate]);

  const [registrationFormData, setRegistrationFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    phonenumber: phonenumber,
    whatsappNumber: "",
    email: "",
    address: "",
    district: "",
    province: "",
    password: "",
    country: "",
  });

  function addInputDataToFormData(input, input_name) {
    setRegistrationFormData((prevFormData) => ({
      ...prevFormData,
      [input_name]: input,
    }));
    console.log(registrationFormData);
  }

  const registerParent = async () => {
    console.log(registrationFormData);
    setShowLoading(true);
    console.log(`${API_URL}/api/v1/parent${pathName}/${phonenumber}`);

    const response = await fetch(
      `${API_URL}/api/v1/parent${pathName}/${phonenumber}`,
      {
        body: JSON.stringify(registrationFormData),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_TOKEN}`,
        },
      }
    );
    // .then(() => {
    console.log("RRR", response);
    if (response.ok) {
      setShowLoading(false);
      setHasFormSubmittedSuccessfully(true);
      setShowSuccessErrorModal(true);
      setTimeout(() => {
        navigate("/login")
      }, 4000);
      // clearInputs();
    } else {
      setShowLoading(false);
      setHasFormSubmittedSuccessfully(false);
      setShowSuccessErrorModal(true);
    }
    // })
    // .catch((err) => {
    //   setShowLoading(false);
    //   setHasFormSubmittedSuccessfully(false);
    //   setShowSuccessErrorModal(true);
    //   // clearInputs();
    // })
    // .finally(() => {
    //   setShowLoading(false);
    // });
  };

  return (
    <section className="register_parent">
      <Nav />
      <h1>Welcome to Brainstake eSchools</h1>
      <div className="register_parent__form_container">
        <form action="#" className="register_parent__form_container__form">
          <h2>Please complete registration to access the platform</h2>
          <FormInput
            type="text"
            required
            id={`firstName`}
            placeholder="Enter your first name"
            title="Enter your first name"
            name="firstName"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "firstName",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.firstName}
          />
          <FormInput
            type="text"
            required
            id={`middleName`}
            placeholder="Enter your middle name"
            title="Enter your middle name"
            name="middleName"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "middleName",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.middleName}
          />
          <FormInput
            type="text"
            required
            id={`lastName`}
            placeholder="Enter your last name"
            title="Enter your last name"
            name="lastName"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "lastName",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.lastName}
          />
          <FormInput
            type="text"
            disabled
            required
            id={`parentPhoneNumber`}
            placeholder="Phone number"
            title="Phone number"
            name="parentPhoneNumber"
            value={parentPhoneNumber}
          />
          <FormInput
            type="text"
            id={`whatsappNumber`}
            placeholder="Enter your whatsapp number"
            title="Enter your whatsapp number"
            name="whatsappNumber"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "whatsappNumber",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.whatsappNumber}
          />
          <FormInput
            type="email"
            required
            id={`email`}
            placeholder="Enter your emai"
            title="Enter your email address"
            name="email"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "email",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.email}
          />
          <FormInput
            type="password"
            required
            id={`password`}
            placeholder="Enter a password"
            title="Enter a password"
            name="password"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "password",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.password}
          />
          <FormInput
            type="password"
            required
            id={`repeatPassword`}
            placeholder="Repeat your password"
            title="Repeat your password"
            name="repeatPassword"
            onChange={(e) => {
              setRepeatPassword(e.target.value);
            }}
            value={repeatPassword}
          />
          <FormInput
            type="address"
            required
            id={`address`}
            placeholder="Enter your address"
            title="Enter your address"
            name="address"
            onChange={(e) => {
              addInputDataToFormData(
                e.target.value,
                "address",
                setRegistrationFormData,
                registrationFormData,
                true
              );
            }}
            value={registrationFormData?.address}
          />
          <div className="form_input_container">
            <label htmlFor="country">Select home country</label>
            <select
              id={`country`}
              name="country"
              onChange={(e) => {
                addInputDataToFormData(e.target.value, "country");
              }}
              value={registrationFormData["country"]}
            >
              <option value="">Select home country</option>
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
          <div className="province_container">
            <p
              className={`province_error ${
                !isProvinceSelectionErrorHidden ? " displayed_flex" : ""
              }`}
            >
              <span>Select the country first</span>
              <button
                className="close_parent"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement.classList.remove("displayed_flex");
                  setIsProvinceSelectionErrorHidden(true);
                }}
              >
                +
              </button>
            </p>
            {registrationFormData?.country === "Zimbabwe" ? (
              <div className="form_input_container">
                <label htmlFor="province">Select the province</label>
                <select
                  id={`province`}
                  name="province"
                  onChange={(e) => {
                    addInputDataToFormData(
                      e.target.value,
                      "province",
                      setRegistrationFormData,
                      registrationFormData,
                      true
                    );
                  }}
                  required
                >
                  <option value="">Select the province</option>
                  <option value="Bulawayo">Bulawayo</option>
                  <option value="Harare">Harare</option>
                  <option value="Manicaland">Manicaland</option>
                  <option value="Mashonaland Central">
                    Mashonaland Central
                  </option>
                  <option value="Mashonaland East">Mashonaland East</option>
                  <option value="Mashonaland West">Mashonaland West</option>
                  <option value="Masvingo">Masvingo</option>
                  <option value="Matabeleland North">Matabeleland North</option>
                  <option value="Matabeleland South">Matabeleland South</option>
                  <option value="Midlands">Midlands</option>
                </select>
              </div>
            ) : (
              <FormInput
                type="text"
                id={`province`}
                placeholder="Enter your school province"
                title="Name of Province"
                name="province"
                onChange={(e) => {
                  if (
                    !registrationFormData?.country ||
                    registrationFormData?.country === ""
                  ) {
                    setIsProvinceSelectionErrorHidden(false);
                    return;
                  }
                  addInputDataToFormData(
                    e.target.value,
                    "province",
                    setRegistrationFormData,
                    registrationFormData,
                    true
                  );
                }}
                value={registrationFormData?.province}
              />
            )}
          </div>
          <div className="district_container">
            <p
              className={`district_error ${
                !isDistrictSelectionErrorHidden ? " displayed_flex" : ""
              }`}
            >
              <span>{districtError}</span>
              <button
                className="close_parent"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement.classList.remove("displayed_flex");
                  setIsDistrictSelectionErrorHidden(true);
                }}
              >
                +
              </button>
            </p>
            {registrationFormData?.country === "Zimbabwe" ? (
              <div className="form_input_container">
                <label htmlFor="district">
                  Select the name of the school district
                </label>
                <select
                  name="district"
                  id="district"
                  onChange={(e) => {
                    const selected_option = e.target.selectedOptions[0];
                    if (
                      selected_option.getAttribute("data_province") !==
                      registrationFormData?.province
                    ) {
                      setDistrictError(
                        `${
                          !registrationFormData?.province
                            ? "Please select a province first"
                            : `${selected_option.value} district does not belong in ${registrationFormData?.province} province`
                        }`
                      );
                      addInputDataToFormData("", "district");
                      setIsDistrictSelectionErrorHidden(false);
                      return;
                    }
                    addInputDataToFormData(e.target.value, "district");
                  }}
                  className="zim_district selectd"
                >
                  <option value="">Select the school district</option>
                  <optgroup label="Manicaland">
                    <option value="Buhera" data_province="Manicaland">
                      Buhera
                    </option>
                    <option value="Chimanimani" data_province="Manicaland">
                      Chimanimani
                    </option>
                    <option value="Chipinge" data_province="Manicaland">
                      Chipinge
                    </option>
                    <option value="Makoni" data_province="Manicaland">
                      Makoni
                    </option>
                    <option value="Mutare Rural" data_province="Manicaland">
                      Mutare Rural
                    </option>
                    <option value="Mutasa" data_province="Manicaland">
                      Mutasa
                    </option>
                    <option value="Nyanga" data_province="Manicaland">
                      Nyanga
                    </option>
                    <option value="Mutare Urban" data_province="Manicaland">
                      Mutare (Urban)
                    </option>
                    <option value="Rusape" data_province="Manicaland">
                      Rusape
                    </option>
                    <option value="Chipinge Urban" data_province="Manicaland">
                      Chipinge (Urban)
                    </option>
                  </optgroup>
                  <optgroup label="Mashonaland Central">
                    <option value="Bindura" data_province="Mashonaland Central">
                      Bindura
                    </option>
                    <option
                      value="Centenary"
                      data_province="Mashonaland Central"
                    >
                      Centenary
                    </option>
                    <option value="Guruve" data_province="Mashonaland Central">
                      Guruve
                    </option>
                    <option value="Mazowe" data_province="Mashonaland Central">
                      Mazowe
                    </option>
                    <option
                      value="Mount Darwin"
                      data_province="Mashonaland Central"
                    >
                      Mount Darwin
                    </option>
                    <option
                      value="Rushinga"
                      data_province="Mashonaland Central"
                    >
                      Rushinga
                    </option>
                    <option value="Shamva" data_province="Mashonaland Central">
                      Shamva
                    </option>
                    <option value="Mbire" data_province="Mashonaland Central">
                      Mbire
                    </option>
                    <option
                      value="Bindura Urban"
                      data_province="Mashonaland Central"
                    >
                      Bindura (Urban)
                    </option>
                    <option value="Mvurwi" data_province="Mashonaland Central">
                      Mvurwi
                    </option>
                  </optgroup>
                  <optgroup label="Mashonaland East">
                    <option value="Chikomba" data_province="Mashonaland East">
                      Chikomba
                    </option>
                    <option value="Goromonzi" data_province="Mashonaland East">
                      Goromonzi
                    </option>
                    <option value="Hwedza" data_province="Mashonaland East">
                      Hwedza
                    </option>
                    <option value="Marondera" data_province="Mashonaland East">
                      Marondera
                    </option>
                    <option value="Mudzi" data_province="Mashonaland East">
                      Mudzi
                    </option>
                    <option value="Murehwa" data_province="Mashonaland East">
                      Murehwa
                    </option>
                    <option value="Mutoko" data_province="Mashonaland East">
                      Mutoko
                    </option>
                    <option value="Seke" data_province="Mashonaland East">
                      Seke
                    </option>
                    <option
                      value="Uzumba-Maramba-Pfungwe"
                      data_province="Mashonaland East"
                    >
                      Uzumba-Maramba-Pfungwe
                    </option>
                    <option
                      value="Marondera Urban"
                      data_province="Mashonaland East"
                    >
                      Marondera (Urban)
                    </option>
                    <option
                      value="Chivhu Town Board"
                      data_province="Mashonaland East"
                    >
                      Chivhu Town Board
                    </option>
                    <option
                      value="Ruwa Local Board"
                      data_province="Mashonaland East"
                    >
                      Ruwa Local Board
                    </option>
                  </optgroup>
                  <optgroup label="Mashonaland West">
                    <option value="Chegutu" data_province="Mashonaland West">
                      Chegutu
                    </option>
                    <option value="Hurungwe" data_province="Mashonaland West">
                      Hurungwe
                    </option>
                    <option value="Kariba" data_province="Mashonaland West">
                      Kariba
                    </option>
                    <option value="Makonde" data_province="Mashonaland West">
                      Makonde
                    </option>
                    <option
                      value="Mhondoro-Ngezi"
                      data_province="Mashonaland West"
                    >
                      Mhondoro-Ngezi
                    </option>
                  </optgroup>
                  <optgroup label="Matabeleland North">
                    <option value="Binga" data_province="Matabeleland North">
                      Binga
                    </option>
                    <option value="Bubi" data_province="Matabeleland North">
                      Bubi
                    </option>
                    <option value="Hwange" data_province="Matabeleland North">
                      Hwange
                    </option>
                    <option value="Lupane" data_province="Matabeleland North">
                      Lupane
                    </option>
                    <option value="Nkayi" data_province="Matabeleland North">
                      Nkayi
                    </option>
                    <option
                      value="Tsholotsho"
                      data_province="Matabeleland North"
                    >
                      Tsholotsho
                    </option>
                    <option value="Umguza" data_province="Matabeleland North">
                      Umguza
                    </option>
                    <option
                      value="Victoria Falls"
                      data_province="Matabeleland North"
                    >
                      Victoria Falls
                    </option>
                  </optgroup>
                  <optgroup label="Matabeleland South">
                    <option
                      value="Beitbridge Rural"
                      data_province="Matabeleland South"
                    >
                      Beitbridge Rural
                    </option>
                    <option value="Bulilima" data_province="Matabeleland South">
                      Bulilima
                    </option>
                    <option value="Mangwe" data_province="Matabeleland South">
                      Mangwe
                    </option>
                    <option
                      value="Gwanda Rural"
                      data_province="Matabeleland South"
                    >
                      Gwanda Rural
                    </option>
                    <option value="Insiza" data_province="Matabeleland South">
                      Insiza
                    </option>
                    <option value="Matobo" data_province="Matabeleland South">
                      Matobo
                    </option>
                    <option
                      value="Umzingwane"
                      data_province="Matabeleland South"
                    >
                      Umzingwane
                    </option>
                    <option
                      value="Gwanda Urban"
                      data_province="Matabeleland South"
                    >
                      Gwanda (Urban)
                    </option>
                    <option
                      value="Beitbridge Urban"
                      data_province="Matabeleland South"
                    >
                      Beitbridge (Urban)
                    </option>
                    <option value="Plumtree" data_province="Matabeleland South">
                      Plumtree
                    </option>
                  </optgroup>
                  <optgroup label="Midlands">
                    <option value="Chirumhanzu" data_province="Midlands">
                      Chirumhanzu
                    </option>
                    <option value="Gokwe North" data_province="Midlands">
                      Gokwe North
                    </option>
                    <option value="Gokwe South" data_province="Midlands">
                      Gokwe South
                    </option>
                    <option value="Gweru Rural" data_province="Midlands">
                      Gweru Rural
                    </option>
                    <option value="Kwekwe Rural" data_province="Midlands">
                      Kwekwe Rural
                    </option>
                    <option value="Mberengwa" data_province="Midlands">
                      Mberengwa
                    </option>
                    <option value="Shurugwi" data_province="Midlands">
                      Shurugwi
                    </option>
                    <option value="Zvishavane" data_province="Midlands">
                      Zvishavane
                    </option>
                    <option value="Gweru Urban" data_province="Midlands">
                      Gweru (Urban)
                    </option>
                    <option value="Kwekwe Urban" data_province="Midlands">
                      Kwekwe (Urban)
                    </option>
                    <option value="Redcliff" data_province="Midlands">
                      Redcliff
                    </option>
                    <option value="Gokwe Centre" data_province="Midlands">
                      Gokwe Centre
                    </option>
                  </optgroup>
                  <optgroup label="Masvingo">
                    <option value="Bikita" data_province="Masvingo">
                      Bikita
                    </option>
                    <option value="Chiredzi" data_province="Masvingo">
                      Chiredzi
                    </option>
                    <option value="Chivi" data_province="Masvingo">
                      Chivi
                    </option>
                    <option value="Gutu" data_province="Masvingo">
                      Gutu
                    </option>
                    <option value="Masvingo Rural" data_province="Masvingo">
                      Masvingo Rural
                    </option>
                    <option value="Mwenezi" data_province="Masvingo">
                      Mwenezi
                    </option>
                    <option value="Zaka" data_province="Masvingo">
                      Zaka
                    </option>
                    <option value="Masvingo Urban" data_province="Masvingo">
                      Masvingo (Urban)
                    </option>
                    <option value="Chiredzi Town" data_province="Masvingo">
                      Chiredzi (Town)
                    </option>
                  </optgroup>
                  <optgroup label="Harare">
                    <option value="Harare Rural" data_province="Harare">
                      Harare Rural
                    </option>
                    <option value="Harare Urban" data_province="Harare">
                      Harare (Urban)
                    </option>
                    <option value="Chitungwiza" data_province="Harare">
                      Chitungwiza
                    </option>
                    <option value="Epworth" data_province="Harare">
                      Epworth
                    </option>
                  </optgroup>
                  <optgroup label="Bulawayo">
                    <option value="Bulawayo Urban" data_province="Bulawayo">
                      Bulawayo (Urban)
                    </option>
                  </optgroup>
                </select>
              </div>
            ) : (
              <FormInput
                type="text"
                id={`district`}
                placeholder="Enter the name of your school district"
                title="Enter the name of your school district"
                name="district"
                onChange={(e) => {
                  if (
                    !registrationFormData?.country ||
                    registrationFormData?.country === ""
                  ) {
                    setDistrictError("Select the country first");
                    setIsDistrictSelectionErrorHidden(false);
                    return;
                  }
                  addInputDataToFormData(e.target.value, "district");
                }}
                value={registrationFormData?.district}
              />
            )}
          </div>
          <p>
            By signing up, I agree to Brainstake&nbsp;
            <Link to="/terms" target="_blank" rel="noreferrer">
              Terms of service
            </Link>
            &nbsp;and&nbsp;
            <Link to="/privacy" target="_blank" rel="noreferrer">
              Privacy policy.
            </Link>
          </p>
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              registerParent();
            }}
          >
            Submit
          </button>
        </form>
      </div>
      {/* <div className="submission_state_modal_container">
        <SuccessErrorModal
          isSuccess={hasFormSubmittedSuccessfully}
          message={`${
            hasFormSubmittedSuccessfully
              ? "Account created successfully"
              : "There was a problem creating your account. Please try again"
          }`}
          setShowSuccessErrorModal={setShowSuccessErrorModal}
          hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully}
        />
      </div> */}
      <div>
        {showLoading && (
          <Loading message={"Your account is being created. Please wait"} />
        )}
        {showSuccessErrorModal && (
          <SuccessErrorModal
            message={
              hasFormSubmittedSuccessfully
                ? "Your account has been successfully created. You'll be redirected to the login page in a moment"
                : "There was an issue creating your account. Please try again."
            }
            hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully}
            setShowSuccessErrorModal={setShowSuccessErrorModal}
          />
        )}
      </div>
      <Footer />
    </section>
  );
};

export default RegisterParent;
