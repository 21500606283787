import "../../css/studentPortalSideBar.css";
import AdminLink from "../widgets/AdminLink";

const StudentPortalSideBar = () => {
  return (
    <div className="left_side_nav">
      <AdminLink
        url_link="/student-portal"
        icon_url={"url('/images/chart.png')"}
        description="Dashboard"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/sudent-portal/subjects"
        icon_url={"url('/images/student_avatar.png')"}
        description="Subjects"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/sudent-portal/exam-time-table"
        icon_url={"url('/images/teacher_avatar.png')"}
        description="Exam Time Table"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/class_management"
        icon_url={"url('/images/classroom.png')"}
        description="Exam Results"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/sudent-portal/report-card"
        icon_url={"url('/images/comments_speech.png')"}
        description="Report Card"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/admin/school_events_and_announcements"
        icon_url={"url('/images/announcement.png')"}
        description="Settings"
        is_sidebar_link={true}
      />
    </div>
  );
};

export default StudentPortalSideBar;
