import AdminLeftSidebarNav from "../../../components/layout/AdminLeftSidebarNav";
import Footer from "../../../components/layout/Footer";
import SchoolCommonNav from "../../../components/layout/SchoolCommonNav";
import "../../../css/admin.css";
import { firstName } from "../../../globalConfigs/userLoginInfo";
import ManageClassList from "./classManagementUtils/manageClasses";
import { user_name, school_logo_url, school_name, school_address, are_there_new_notifications, user_profile_pic } from "../../../globalConfigs/userLoginInfo";
import { useAuthRedirect } from "../../auth/auth";

const ClassManagement = () => {
    useAuthRedirect();


    return (
        <section className="admin admin_styles">

            <SchoolCommonNav
                school_logo_url={school_logo_url}
                school_name={school_name}
                user_name={firstName}
                user_profile_pic={user_profile_pic}
                are_there_new_notifications={are_there_new_notifications}
            />
            <div className="container">
                <AdminLeftSidebarNav />
                <div className="content">
                    <div className="search_container">
                        <form action="">
                            <input
                                type="search"
                                name="search"
                                id="search"
                                placeholder="Search for a class"
                            />
                        </form>
                    </div>
                    <div className="about_school">
                        <h1>Class Management</h1>
                        <h3>{school_name}</h3>
                        <div className="info">
                            <ManageClassList />
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </section>
    );
};

export default ClassManagement;
