import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import "../../css/admin.css";
import { firstName, lastName } from "../../globalConfigs/userLoginInfo";
import '../../css/studentPortal.css';
import '../../css/teacherPortal.css';
import TeacherPortalSideBar from "../../components/layout/teacherPortalSideBar";
import teacher_icon_eye from "../../svg/eye.svg";
import { useState } from "react";
import TeacherPortalStudentList from "./teacherPortalComponents/teacherPortalStudentList";
import { alertTitleClasses } from "@mui/material";
import manage_parent_icon_left_arrow from "../../svg/leftarrow.svg";
import manage_parent_icon_right_arrow from "../../svg/rightarrow.svg";
import TeacherPortalCreateExamForm from "./teacherPortalComponents/teacherPortalCreateExamForm";


const TeacherPortalExamManagement = () => {
  const school_logo_url = "https://picsum.photos/seed/picsum/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_profile_pic = "https://picsum.photos/id/28/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const [showcreateExamForm, setShowcreateExamForm] = useState(false);
  const [overlay, setOverlay] = useState("none");
  const [classID, setClassID] = useState(null);

  const showcreateExam = (class_id) => {
    setClassID(class_id);
    setOverlay("block");
    setShowcreateExamForm(true);
  }

  const hideshowcreateExam = () => {
    setShowcreateExamForm(false);
    setOverlay("none");
  }
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <TeacherPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Exam Management</h1>

            <div className="teacher-portal-exam-creation-button-tab">
              <div>Exam creation </div>
              <div onClick={() => { showcreateExam(100) }}>Create an exam</div>
            </div>

            <div className="teacher-portal-table-title">Current and past examinations</div>


            <div className="teacher-portal-tabs-container">
              <div style={{ display: `${overlay}` }} className="teacher-portal-student-list-container">
                {showcreateExamForm == true ? <TeacherPortalCreateExamForm onclose={hideshowcreateExam} /> : ""}
              </div>
              <div className="teacher-portal-tab">
                {/* ------------------------------------- */}
                <div className="teacher-portal-table-container">
                  <table className="teacher-portal-table">
                    <tr>
                      <th>Exam title</th>
                      <th>Course/Subject</th>
                      <th>Date of exam</th>
                      <th>Class(es)</th>
                      <th>Academic Year</th>
                      <th>Exam Status</th>
                      <th>View/edit exam details</th>
                    </tr>
                    <tr>
                      <td>Row 1</td>
                      <td>Row 1</td>
                      <td>Data 1</td>
                      <td>Data 2</td>
                      <td>Row 1</td>
                      <td>Data 1</td>
                      <td onClick={() => { showcreateExam(100) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                      <td>Row 2</td>
                      <td>Row 1</td>
                      <td>Row 1</td>
                      <td>Data 1</td>
                      <td>Data 4</td>
                      <td>Data 5</td>
                      <td onClick={() => { showcreateExam(10600) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                      <td>Row 3</td>
                      <td>Row 1</td>
                      <td>Row 1</td>
                      <td>Data 1</td>
                      <td>Data 7</td>
                      <td>Data 8</td>
                      <td onClick={() => { showcreateExam(10770) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                      <td>eee</td>
                      <td>Row 1</td>
                      <td>Row 1</td>
                      <td>Data 1</td>
                      <td>gggg</td>
                      <td>gggg</td>
                      <td onClick={() => { showcreateExam(12200) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                  </table>
                  {/* ------------------------------------- */}
                </div>

              </div>

            </div>


            {/* ------------------pagination--------------- */}
            <div className='teacher_portal_pagination_container'>
              <div className='teacher_portal_pagination_items_container'>
                <div className=''><img src={manage_parent_icon_left_arrow} /></div>
                <div className='teacher_portal_page_items'>1</div>
                <div className='teacher_portal_page_items'>2</div>
                <div className='teacher_portal_page_items'>3</div>
                <div className='teacher_portal_page_items'>4</div>
                <div className=''><img src={manage_parent_icon_right_arrow} /></div>
              </div>
            </div>
            {/* pagination ends---------------------------- */}

          </div>




        </div>

      </div>

      <Footer />
    </section>
  );
};

export default TeacherPortalExamManagement;