import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { handleForgetPwd } from '../../controllers/authenticationAPIs/forgetPwdAPI';
import '../../css/tempLoginCSS.css';
import Nav from '../../components/layout/Nav';
import Footer from '../../components/layout/Footer';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(null);

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = await handleForgetPwd(email);

        if (data.error) {
            setError(data.error);
        } else {
            setResponseData(data);
            setError(null);
        }
    };

    return (
        <section className="login-auth-main-container">
            <Nav />
            <h1>Forgot Password</h1>
            <div className="login-auth-container">
        
                <h2>Please enter your email address to get password reset instructions</h2>
                <form onSubmit={onSubmit}>
                    <div className="login-auth-form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="login-auth-form-input" 
                        />
                    </div>
                    
                    <div className="login-auth-forgot-password">
                        <div>
                            <Link to="/login">No, Cancel</Link>
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="login-auth-submit-button">Login</button>
                    </div>

         

                    {responseData && <div className="login-auth-success-message">Success: {JSON.stringify(responseData)}</div>}
                    {error && <div className="login-auth-error-message">Error: {error}</div>}
                </form>
            </div>
            <Footer />
        </section>
    );
};

export default ForgotPassword;


  