import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import AdminLeftSidebarNav from '../../../components/layout/AdminLeftSidebarNav';
import Footer from '../../../components/layout/Footer';
import SchoolCommonNav from '../../../components/layout/SchoolCommonNav';
import "../../../css/admin.css";
import "../../../css/teacherManagement.css";
import manage_teacher_icon_question_mark from "../../../svg/Group 374.svg";
import manage_teacher_icon_eye from "../../../svg/eye.svg";
import manage_teacher_icon_edit from "../../../svg/Group 362.svg";
import manage_teacher_icon_delete from "../../../svg/Group 368.svg";
import TeacherManagementTooltip from '../teacherManagement/teacherManagementComponents/teachermanegementTooltip';
import TeacherManagementViewTeacherDetails from '../teacherManagement/teacherManagementComponents/teacherManagementViewTeacherDetails';
import StudentBulkUpload from './manageStudent/bulkUploadStudent';
import AddStudent from './manageStudent/addStudent';
import DeleteStudent from './manageStudent/deleteStudent';
import EditStudentStatus from './editStudentStatus';
import CircularLoader from '../../../globalConfigs/circularProgressIndicator';
import { apiHeaders, firstName, schoolID } from '../../../globalConfigs/userLoginInfo';
import { API_URL, getAllStudents } from '../../../globalConfigs/apiConfigs';
import { user_name, school_logo_url, school_name, school_address, are_there_new_notifications, user_profile_pic } from "../../../globalConfigs/userLoginInfo";

const defaultPhoto = 'https://via.placeholder.com/50';

const StudentManagement = () => {
  const [student, setStudent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [newTeacher, setNewTeacher] = useState({ name: '', subject: '', email: '', status: 'Active', photo: '' });
  const [displayAddTeacherIndividualForm, setDisplayAddTeacherIndividualForm] = useState(false);
  const [displayAddTeacherBulkUploadlForm, setDisplayAddTeacherBulkUploadlForm] = useState(false);
  const [displayEditTeacherStatusForm, setDisplayEditTeacherStatusForm] = useState(false);
  const [displayDeleteTeacherForm, setDisplayDeleteTeacherForm] = useState(false);
  const [displayViewTeacherDetailForm, setDisplayViewTeacherDetailForm] = useState(false);
  const [grades, setGrades] = useState([]);
  const [studentClasses, setClasses] = useState([]);


    useEffect(() => {
        const fetchGrades = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/levels`, { headers: apiHeaders });
                const data = await response.json();
                console.log("--------------------grades-------------------")
                console.log(data);
                setGrades(data);
                console.log("--------------------grades-------------------")
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchGrades();
    }, []);


    useEffect(() => {
        const fetchClasses = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/classes`, {headers: apiHeaders });
                const data = await response.json();
                console.log("--------------------classes-------------------");
                console.log(data);
                console.log("--------------------classes-------------------")

                setClasses(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchClasses();
    }, []);


    // display the name part of the levelgradeID not the UUID
  const findLevelGradeName = (levelGradeId) => {
    const levelGrade = grades.find(item => item.levelGradeId === levelGradeId);
    return levelGrade ? levelGrade.name : 'N/A';
  };

  const findClassName = (classId) => {
    const studentClass = studentClasses.find(item => item.classId === classId);
    return studentClass ? studentClass.className : 'N/A';
  };

  

  // Fetch students data from API
  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/api/v1/students/school/${schoolID}`, { headers: apiHeaders });
        const data = await response.json();
        const filteredData = data.filter(stud => stud.schoolId === Number(schoolID));
        console.log(filteredData);
        setStudent(filteredData);
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchStudents();
  }, []);

  // Other functions for form toggling
  const displayTeacherIndividualFormToggle = () => {
    setDisplayAddTeacherIndividualForm(!displayAddTeacherIndividualForm);
  };
  const displayTeacherBulkFormToggle = () => {
    setDisplayAddTeacherBulkUploadlForm(!displayAddTeacherBulkUploadlForm);
  };
  const displayEditTeacherStatusFormToggle = () => {
    setDisplayEditTeacherStatusForm(!displayEditTeacherStatusForm);
  };
  const displayDeleteTeacherFormToggle = () => {
    setDisplayDeleteTeacherForm(!displayDeleteTeacherForm);
  };
  const displayViewTeacherDetailFormToggle = () => {
    setDisplayViewTeacherDetailForm(!displayViewTeacherDetailForm);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredStudents = student.filter(stud =>
    stud.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    stud.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const manage_teachers_hide_show = {
    display: displayViewTeacherDetailForm ? 'none' : 'block',
  };

  return (
    <section className="admin admin_styles">
      {loading && <CircularLoader />}
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        school_name={school_name}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />

      <div className="container">
        <AdminLeftSidebarNav />
        <div className="content">
          <div className="about_school">
            <h1>Manage Students</h1>

            <div className="manage_teachers_add_teacher_heading_container">
              <div>Add Student:</div>
              <Link onClick={displayTeacherIndividualFormToggle}>
                <div className="manage_teachers_add_teacher_heading_item">Add Individually</div>
              </Link>
              <div className="manage_teachers_add_teacher_heading_sub_container">
                <Link onClick={displayTeacherBulkFormToggle}>
                  <div className=" manage_teachers_add_teacher_heading_item">Bulk Add Via A csv File</div>
                </Link>
                <div>
                  <TeacherManagementTooltip text={"A CSV file is a simple text file used to store data in rows and columns. The columns required are First Name, Last Name, Phone Number, Date of birth, Nationality, Address, Email and Subject(s) (subjects separated by commas if a teacher teaches more than one subject)"} >
                    <Link to={"#"}>
                      <img src={manage_teacher_icon_question_mark} alt="Question Mark" style={{ width: '20px', height: '22px' }} />
                    </Link>
                  </TeacherManagementTooltip>
                </div>
              </div>
            </div>

            <div className="manage_teachers_addteacher_container">
              {displayAddTeacherIndividualForm && <AddStudent onClose={displayTeacherIndividualFormToggle} />}
              {displayAddTeacherBulkUploadlForm && <StudentBulkUpload onClose={displayTeacherBulkFormToggle} />}
              {displayEditTeacherStatusForm && <EditStudentStatus onClose={displayEditTeacherStatusFormToggle} />}
              {displayDeleteTeacherForm && <DeleteStudent onClose={displayDeleteTeacherFormToggle} />}
              {displayViewTeacherDetailForm && <TeacherManagementViewTeacherDetails onClose={displayViewTeacherDetailFormToggle} />}
            </div>

            <div style={manage_teachers_hide_show} className="search_container">
              <form action="">
                <input
                  type="search"
                  name="search"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search for a student by name or email"
                />
              </form>
            </div>

            <div style={manage_teachers_hide_show} className="manage_teachers_table_container">
              <table>
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Parent Phone</th>
                    <th>Parent Email</th>
                    <th>Level</th>
                    <th>Class</th>
                    <th>Enrolment Status</th>
                    <th>View Details</th>
                    <th>Edit Status</th>
                    <th>Delete Student</th>
                  </tr>
                </thead>
                <tbody>
                  {student.map(stud => (
                    <tr key={stud.id}>
                      <td>
                        <img src={stud.picture || defaultPhoto} alt="Student" className="student-photo" />
                      </td>
                      <td>{stud.firstName} {stud.lastName}</td>
                      <td>{stud.email}</td>
                      <td>{stud.parentPhone}</td>
                      <td>{stud.parentEmail}</td>
                      <td>{findLevelGradeName(stud.levelId)}</td>
                      {/* <td>{stud.classId || 'N/A'}</td> */}
                      <td>{findClassName(stud.classId)}</td>
                      <td>{stud.status}</td>
                      <td onClick={displayViewTeacherDetailFormToggle}>
                        <img src={manage_teacher_icon_eye} alt="View" />
                      </td>
                      <td onClick={displayEditTeacherStatusFormToggle}>
                        <img src={manage_teacher_icon_edit} alt="Edit" />
                      </td>
                      <td onClick={displayDeleteTeacherFormToggle}>
                        <img src={manage_teacher_icon_delete} alt="Delete" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default StudentManagement;
