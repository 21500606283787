import AdminLeftSidebarNav from "../../components/layout/AdminLeftSidebarNav";
import ParentPortalSideBar from "../../components/layout/parentPortalSideBar";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import Sidebar from "../../components/layout/sideBar";
import StudentPortalSideBar from "../../components/layout/studentPortalSideBar";
import "../../css/admin.css";
import "../../css/comingSoon404.css"
import { school_logo_url } from "../../globalConfigs/userLoginInfo";
import {user_name} from "../../globalConfigs/userLoginInfo";

const Error404 = ({ header, sidebar, body }) => {

  const role = localStorage.getItem("role") 
  const profile_pic_url = "/images/avatar.png"

  return (
    <div className="error404" >
      {role === "PARENT" || role === "SUPER_ADMIN" ? ( 
        <SchoolCommonNav 
          school_logo_url={"/images/logo_container.svg"}
          school_name={"aassaas"}
          user_name={user_name}
          user_profile_pic={profile_pic_url}
          are_there_new_notifications={false}
        />
        ) : (
          <SchoolCommonNav
            school_logo_url={school_logo_url}
            school_name={"aassaas"}
            user_name={user_name}
            user_profile_pic={profile_pic_url}
            are_there_new_notifications={false}
          />
        )
      }
      {header}
      <div className="container">
       {role === "SUPER_ADMIN" ?
          <Sidebar />
          : role === "ADMIN" ? 
          <AdminLeftSidebarNav /> :
          // role === "TEACHER" ? not yet developed
          // <TeacherLeftSidebarNav /> not yet developed
          role === "STUDENT" ?
          <StudentPortalSideBar /> :
          <ParentPortalSideBar />
        }
        <div className="content">
          {/* BODY GOES HERE */} 
          <div className="coming-soon-container">
              <div className="coming-soon-sub-container-error">ERROR 404</div>
              <div>...Oops, this page doesn’t exist</div>
              <div>Let’s get you back home</div>
          </div> 
        </div>
      </div>
    </div>
  );
};


export default Error404