import "../../../css/cardStyle.css";
import { imageIcons } from "../../../components/imageComponents";

const ParentCommunicationWidget = ({ heading, date, school }) => {


    return (
        <div className="total_stats1">
            <div className="total_stats1__left">

                {heading}

                <hr />
                <span>
                    <img
                        src={imageIcons.school_premise}
                        alt="Graduate"
                        className='widgetImg'
                    />
                    {date}
                </span>

                <span>
                    <img
                        src={imageIcons.classroom_icon}
                        alt="Graduate"
                        className='widgetImg'
                    />
                    {school}
                </span>

                <div className="subjectInfo">
                    <div className="pHeaderIcon">
                        <span>
                            <img
                                src={imageIcons.book_icon}
                                alt="Graduate"
                                className='widgetImg'
                            />
                            <strong className="pHeading">Current subjects</strong>
                        </span>
                    </div>
                    <span>
                        <div className="subjectList">Test Test test</div>
                        <div className="subjectList">Test</div>
                        <div className="subjectList">Test</div>
                        <div className="subjectList">Test</div>
                    </span>

                    <div className="viewMoreSubjects">
                        view more subjects
                    </div>

                </div>

                <div className="reportCard">
                    <span>
                        <img
                            src={imageIcons.report_card}
                            alt="Graduate"
                            className='widgetImg'
                        />
                        View Latest Report Card
                    </span>

                </div>
                <div className="reportCard1">
                    <span>
                        <img
                            src={imageIcons.graduate_cap}
                            alt="Graduate"
                            className='widgetImg'
                        />
                        View all information
                    </span>

                </div>
            </div>
        </div>

    );
};

export default ParentCommunicationWidget;

