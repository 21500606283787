import React, { useState, useEffect } from 'react';
import '../../../../css/manageResults.css';
import Pagination from '../../resultsManagement/resultsUtils/pagination.js';
import GradingPopup from '../../resultsManagement/resultsUtils/gradingPopUp.js';
import { Link } from 'react-router-dom';
import CreateClassPopup from './createClassPopUp.js';
import { CircularProgress } from '@mui/material';
import { API_URL } from '../../../../globalConfigs/apiConfigs.js';
import { schoolID, token } from '../../../../globalConfigs/userLoginInfo.js';

const ManageClassList = () => {
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [filterStatus, setFilterStatus] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedExam, setSelectedExam] = useState(null);

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/classes`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch classes');
                }
                const data = await response.json();
                setClasses(data);
            } catch (error) {
                console.error('Error fetching classes:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchClasses();
    }, []);

    const handleGradeClick = (exam) => {
        setSelectedExam(exam);
        setIsPopupOpen(true);
    };

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);

    const filteredData = filterStatus ? classes.filter(item => item.status === filterStatus) : classes;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleFilterChange = (event) => setFilterStatus(event.target.value);

    const applyFilter = () => setCurrentPage(1);

    return (
        <div className="table-container">
            <div className="manage_teachers_add_teacher_heading_container">
                <div>Add a class:</div>
                <div onClick={openPopup} className="manage_teachers_add_teacher_heading_item">Create Class</div>
            </div>
            <br />

            <div className="manage_teachers_sort_by_filter_by_container">
                <div className="manage_teachers_sort_by_filter_by_item">
                    <div className="manage_teachers_sort_by_filter_by_forms_title">Sort By</div>
                    <form className="manage_teachers_sort_by_filter_by_forms">
                        <select>
                            <option>None</option>
                            <option>Class Name</option>
                            <option>Level</option>
                        </select>
                    </form>
                </div>
                <div className="manage_teachers_sort_by_filter_by_item">
                    <div className="manage_teachers_sort_by_filter_by_forms_title">Filter By</div>
                    <form className="manage_teachers_sort_by_filter_by_forms">
                        <select>
                            <option>None</option>
                            <option>Form 1</option>
                            <option>Form 2</option>
                            <option>Form 3</option>
                            <option>Form 4</option>
                            <option>Form 5</option>
                            <option>Form 6</option>
                        </select>
                    </form>
                </div>
            </div>
            <br />

            {loading ? (
                <div className="loading-container">
                    <CircularProgress />
                </div>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Class Name</th>
                            <th>Level/Grade</th>
                            <th>Class Teacher</th>
                            <th>View Teachers</th>
                            <th>View Students</th>
                            <th>Edit Class</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item) => (
                            <tr key={item.classId}>
                                <td>{item.className}</td>
                                <td>Level/Grade</td>
                                <td>Teacher 1</td>
                                <td><button className="edit-btn">✎</button></td>
                                <td><button className="edit-btn">✎</button></td>
                                <td><button className="edit-btn">✎</button></td>
                                <td><button className="edit-btn">✎</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <CreateClassPopup isOpen={isPopupOpen} onClose={closePopup} />

            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={filteredData.length}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default ManageClassList;
