import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import "../../css/admin.css";
import { firstName, lastName } from "../../globalConfigs/userLoginInfo";
import '../../css/studentPortal.css';
import '../../css/teacherPortal.css';
import TeacherPortalSideBar from "../../components/layout/teacherPortalSideBar";
import teacher_icon_eye from "../../svg/eye.svg";
import { useState } from "react";
import TeacherPortalStudentList from "./teacherPortalComponents/teacherPortalStudentList";
import { alertTitleClasses } from "@mui/material";
import manage_parent_icon_left_arrow from "../../svg/leftarrow.svg";
import manage_parent_icon_right_arrow from "../../svg/rightarrow.svg";
import TeacherPortalCreateExamForm from "./teacherPortalComponents/teacherPortalCreateExamForm";
import TeacherPortalGradingResultsForm from "./teacherPortalComponents/teacherPortalGradingResultsForm";


const TeacherPortalSettings = () => {
  const school_logo_url = "https://picsum.photos/seed/picsum/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_profile_pic = "https://picsum.photos/id/28/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const [ showgradeExamForm, setShowgradeExamForm] = useState(false);
  const [ overlay, setOverlay] = useState("none");
  const [classID, setClassID] = useState(null);
  
  const showgradeExam = (class_id) => {
    setClassID(class_id);
    setOverlay("block");
    setShowgradeExamForm(true);
  }

  const hideshowgradeExam = () => {
    setShowgradeExamForm(false);
    setOverlay("none");
  }
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <TeacherPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Settings Section</h1>

            <div className="teacher-portal-settings-title">Welcome, Tuco Salamanca</div>
                <div className="teacher-portal-tabs-container">    
                
                    <form>
                        <label for="old-password">Old Password:</label>
                        <input type="password" id="old-password" className="teacher-portal-old-password" required />
                        <label for="new-password">New Password:</label>
                        <input type="password" id="new-password" name="new-password" required />
                        <label for="repeat-new-password">Repeat New Password:</label>
                        <input type="password" id="repeat-new-password" name="repeat-new-password" required />
                        <input type="submit" value="Change Password" />
                    </form>  

                </div>
            </div>
          
        </div>
        
      </div>
      
      <Footer />
    </section>
  );
};

export default TeacherPortalSettings;