import { useState } from "react";
import Footer from "../components/layout/Footer";
import Nav from "../components/layout/Nav";
import EnrolmentForm from "../components/widgets/EnrolmentForm";
import SuccessErrorModal from "../components/widgets/SuccessErrorModal";
import Loading from "../components/widgets/Loading";
import CircularLoader from "../globalConfigs/circularProgressIndicator";
import { API_URL, createSchool } from "../globalConfigs/apiConfigs";
import SchoolReport from "../components/widgets/StudentReport";

const EnrolYourSchool = () => {
  const [formData, setFormData] = useState({});
  const [previewDisplay, setPreviewDisplay] = useState(false);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [paymentReceipt, setPaymentReceipt] = useState(null);

  const [schoolLogoName, setSchoolLogoName] = useState("");
  const [paymentReceiptName, setPaymentReceiptName] = useState("");

  const [schoolOwnership, setSchoolOwnership] = useState("");

  const [isFormLoading, setIsFormLoading] = useState(null);

  const [showLoading, setShowLoading] = useState(false);

  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState(false);

  const [hasFormSubmittedSuccessfully, setHasFormSubmittedSuccessfully] =
    useState(false);

  const styles = {
    container_styles: {
      maxWidth: "100%",
      marginInline: "auto",
      backgroundImage: "url('/images/bg.png')",
      backgroundSize: "100% auto",
      backgroundPosition: "center 0",
      backgroundRepeat: "no-repeat repeat",
    },
  };
  const { container_styles } = styles;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setShowLoading(true);

    const formDataObject = new FormData();
    formDataObject.append("message", "Your message here");
    formDataObject.append("body", JSON.stringify(formData));

    if (schoolLogo) formDataObject.append("logo", schoolLogo);
    if (paymentReceipt) formDataObject.append("receipt", paymentReceipt);

    try {
      const response = await fetch(API_URL + createSchool, {
        method: "POST",
        body: formDataObject,
      });

      setHasFormSubmittedSuccessfully(response.ok);
      setShowSuccessErrorModal(true);
    } catch (error) {
      console.error("Error submitting form", error);
      setHasFormSubmittedSuccessfully(false);
      console.log("Error Submitting Form " + String(error));
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <section
      className="bg-white-50 min-h-screen dark:bg-gray-900 font-appFont enrol_your_school_container"
      style={container_styles}
    >
      <Nav />
      <form action="#" onSubmit={handleFormSubmit}>
        <EnrolmentForm
          heading_title="School enrolment"
          form_description="Fill in the form to register a school"
          formData={formData}
          setFormData={setFormData}
          isPreview={false}
          setPreviewDisplay={setPreviewDisplay}
          schoolLogo={schoolLogo}
          setSchoolLogo={setSchoolLogo}
          paymentReceipt={paymentReceipt}
          setPaymentReceipt={setPaymentReceipt}
          schoolLogoName={schoolLogoName}
          setSchoolLogoName={setSchoolLogoName}
          paymentReceiptName={paymentReceiptName}
          setPaymentReceiptName={setPaymentReceiptName}
          schoolOwnership={schoolOwnership}
          setSchoolOwnership={setSchoolOwnership}
        />
      </form>
      <form action="#">
        <div className={`is_preview${previewDisplay ? " displayed_flex" : ""}`}>
          <EnrolmentForm
            heading_title="School enrolment"
            form_description="Preview your information before completing registration"
            formData={formData}
            setFormData={setFormData}
            isPreview={true}
            setPreviewDisplay={setPreviewDisplay}
            schoolLogo={schoolLogo}
            setSchoolLogo={setSchoolLogo}
            paymentReceipt={paymentReceipt}
            setPaymentReceipt={setPaymentReceipt}
            schoolLogoName={schoolLogoName}
            setSchoolLogoName={setSchoolLogoName}
            paymentReceiptName={paymentReceiptName}
            setPaymentReceiptName={setPaymentReceiptName}
            schoolOwnership={schoolOwnership}
            setSchoolOwnership={setSchoolOwnership}
            hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully}
            setHasFormSubmittedSuccessfully={setHasFormSubmittedSuccessfully}
            showSuccessErrorModal={showSuccessErrorModal}
            setShowSuccessErrorModal={setShowSuccessErrorModal}
            showLoading={showLoading}
            setShowLoading={setShowLoading}
          />
        </div>
      </form> 
      {showLoading && <Loading message={"The form is being submitted. Please wait"} />} 
      {showSuccessErrorModal && (
        <SuccessErrorModal
          message={
            hasFormSubmittedSuccessfully
              ? "Your enrolment request has been submitted."
              : "There was an issue submitting your form. Please try again."
          }
          hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully}
          setShowSuccessErrorModal={setShowSuccessErrorModal}
        />
      )}
      <Footer />
    </section>
  );
};

export default EnrolYourSchool;
