

export const API_URL_LOGIN = 'http://3.9.10.188'; 
// export const API_URL = 'http://3.9.10.188';
export const API_URL = 'https://api-dev.brainstaketech.com';

//Endpoint for fetching school requests
export const fetchSchoolRequests = '/api/v1/schools/schools'; 
export const loginService = '/api/v1/auth/authenticate';
export const forgetPwd = '/forgotPassword/changePassword/';
export const verifyOTP = '/forgotPassword/verifyOtp/{otp}/';
export const changePwd = '/forgotPassword/changePassword/';
export const fetchteachers = '/api/v1/teachers/all';
export const bulkUploadTeachers_excel = "/api/v1/teachers/excel/upload";
export const bulkUploadStudents_excel = "/api/v1/students/excel/upload";
export const createIndividualTeacher = '/api/v1/teachers/create';
export const postEnquiry = '/api/v1/schools/enquiry';
export const createSchool = '/api/v1/schools/create';
export const fetchSchoolData = '/api/v1/schools/create';
export const editSchoolStatus = '/api/v1/schools/update/';
export const createSchoolTerm = '/api/v1/schools/{schoolId}/add/schoolTerm';
export const getAllStudents = '/api/v1/students/all';

export const API_TOKEN = "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6W3siYXV0aG9yaXR5IjoiV1JJVEVfUFJJVklMRUdFIn0seyJhdXRob3JpdHkiOiJERUxFVEVfUFJJVklMRUdFIn0seyJhdXRob3JpdHkiOiJSRUFEX1BSSVZJTEVHRSJ9LHsiYXV0aG9yaXR5IjoiVVBEQVRFX1BSSVZJTEVHRSJ9LHsiYXV0aG9yaXR5IjoiUk9MRV9BRE1JTiJ9XSwic3ViIjoiZWVyZXJlckBkLmd5dHl1IiwiaWF0IjoxNzMxMjIzNTY2LCJleHAiOjE3MzEyNDE1NjZ9.W5LR1pEPCl4V8AkacpvJl64npkQInFL-Z3UT35aTHP0"


//API Headers
export const apiHeaders = (API_TOKEN) => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${API_TOKEN}`,
  });

