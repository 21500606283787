import Footer from "../../../components/layout/Footer";
import SchoolCommonNav from "../../../components/layout/SchoolCommonNav";
import "../../../css/admin.css";
import { firstName, lastName } from "../../../globalConfigs/userLoginInfo";
import "../../../css/studentPortal.css"
import TeacherPortalSideBar from "../../../components/layout/teacherPortalSideBar";
import teacher_icon_eye from "../../../svg/eye.svg"
import { useState } from "react";
import TeacherPortalStudentList from "./teacherPortalStudentList";
import { alertTitleClasses } from "@mui/material";
import manage_parent_icon_left_arrow from "../../../svg/leftarrow.svg"
import manage_parent_icon_right_arrow from "../../../svg/rightarrow.svg"
import TeacherPortalViewProfile from "./teacherPortalViewProfile";

const TeacherPortalStudentManagement = () => {
  const school_logo_url = "https://picsum.photos/seed/picsum/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_profile_pic = "https://picsum.photos/id/28/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const [toggleDisplayClassStudents, setToggleDisplayClassStudents] = useState(false);
  const [toggleDisplayStudentProfile, setToggleDisplayStudentProfile] = useState(false);
  const [overlay, setOverlay] = useState("none");
  const [classID, setClassID] = useState(null);
  const [studentID, setStudentID] = useState(null);

  // -------------------SHOW STDENT PROFILE FUNCTONS HERE----------------------------------------------
  const showStudentsProfile = (student_id) => {
    setToggleDisplayStudentProfile(true);
    setStudentID(studentID);
    setOverlay("block")
  }

  const hideStudentsProfile = () => {
    setToggleDisplayStudentProfile(false);
    setOverlay("none");
  }
  // -----------------------------------------------------------------

  const showclassStudents = (class_id) => {
    setToggleDisplayClassStudents(true);
    setClassID(class_id);
    setOverlay("block")
  }

  const hideclassStudents = () => {
    setToggleDisplayClassStudents(false);
    setOverlay("none");
  }
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <TeacherPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Student Management</h1>

            <div>Here are your students</div>
            <div className="teacher-portal-tabs-container">
              <div style={{ display: `${overlay}` }} className="teacher-portal-student-list-container">
                {toggleDisplayClassStudents == true ? <TeacherPortalStudentList onclose={hideclassStudents} classs_id={classID} /> : ""}
                {toggleDisplayStudentProfile == true ? <TeacherPortalViewProfile onclose={hideStudentsProfile} classs_id={classID} /> : ""}
              </div>
              <div className="teacher-portal-filters-container">
                <div className="teacher-portal-filters">
                  <div>Filter students by:</div>
                  <div>
                    <select>
                      <option>None</option>
                      <option>None</option>
                      <option>None</option>
                    </select>
                  </div>
                </div>
                <div>Apply filter</div>
              </div>
              <div className="teacher-portal-tab">
     
                <div className="teacher-portal-table-container">
                  <table className="teacher-portal-table">
                    <tr>
                      <th>Student Name</th>
                      <th>Courses/ Subjects Taken in your class(es)</th>
                      <th>Level</th>
                      <th>Class</th>
                      <th>View Profile</th>
                    </tr>
                    <tr>
                      <td>Row 1</td>
                      <td>Data 1</td>
                      <td>Data 1</td>
                      <td>Data 2</td>
                      <td onClick={() => { showStudentsProfile(100) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                      <td>Row 2</td>
                      <td>Data 4</td>
                      <td>Data 4</td>
                      <td>Data 5</td>
                      <td onClick={() => { showStudentsProfile(10600) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                      <td>Row 3</td>
                      <td>Data 7</td>
                      <td>Data 7</td>
                      <td>Data 8</td>
                      <td onClick={() => { showStudentsProfile(10770) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                      <td>eee</td>
                      <td>gggg</td>
                      <td>gggg</td>
                      <td>gggg</td>
                      <td onClick={() => { showStudentsProfile(12200) }}><img src={teacher_icon_eye} /></td>
                    </tr>
                  </table>
               
                </div>

              </div>

            </div>

          </div>


          <div className='teacher_portal_pagination_container'>
            <div className='teacher_portal_pagination_items_container'>
              <div className=''><img src={manage_parent_icon_left_arrow} /></div>
              <div className='teacher_portal_page_items'>1</div>
              <div className='teacher_portal_page_items'>2</div>
              <div className='teacher_portal_page_items'>3</div>
              <div className='teacher_portal_page_items'>4</div>
              <div className=''><img src={manage_parent_icon_right_arrow} /></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TeacherPortalStudentManagement;