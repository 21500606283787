import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { handleForgetPwd } from '../../controllers/authenticationAPIs/forgetPwdAPI';
import '../../css/tempLoginCSS.css';
import Nav from '../../components/layout/Nav';
import Footer from '../../components/layout/Footer';
import passwordresetsucces from '../../svg/passwordresetsucces.svg';
import passwordresetfailure from '../../svg/passwordresetfailure.svg'

export default function ResetpasswordFailureOrSuccess({status}){
    const [successMessage, setSuccessMessage] = useState('');


    return (
        
        <section className="login-auth-main-container">
            <Nav />
                {successMessage == status ? 
                <div>
                    <h1>Reset password success</h1>
                    <div className="login-auth-container">
                        <h2>Password reset successfully! <Link to={"/login"}>Login here</Link></h2>
                        <div className='password-reset-success'><img src={passwordresetsucces} /></div>
                    </div>
                </div> :

                <div>
                    <h1>Reset password failure</h1>
                    <div className="login-auth-container">
                        <h2>An error occurred while reseting your password.<Link to={"/login"}>Retry here</Link></h2>
                        <div className='password-reset-success'><img src={passwordresetfailure} /></div>
                    </div>
                </div> 
                }
            <Footer />
        </section>
        
    );
};

  