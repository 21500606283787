import React, { useEffect, useState } from 'react';
import '../../css/hero_section.css';
import { Link } from "react-router-dom";
import { data as schoolData } from './schoolData'; // Import your local data

const HeroSection = () => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setQuery(value);

        if (value.length > 0) {
            // Filter the local school data based on the query
            const filteredSuggestions = schoolData.filter((school) =>
                school.schoolName.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.navbar');
            if (window.scrollY > 0) {
                navbar.classList.add('scrolled');
            } else {
                navbar.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <nav className='navbar'>
                <div className='logo'>
                    <img src='logo.png' alt='logo' />
                </div>
                <div className="InputContainer">
                    <input
                        placeholder="Search a School"
                        id="input"
                        className="input"
                        name="text"
                        type="text"
                        value={query}
                        onChange={handleSearch}
                    />
                    <label className="labelforsearch" htmlFor="input">
                        <svg className="searchIcon" viewBox="0 0 512 512">
                            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                        </svg>
                    </label>
                </div>

                <Link to="/enrol-your-school" className='nav-buttons'>
                    <a className='btn2'>Enroll your School</a>
                </Link>

                <div className="suggestion-list">
                    <ul>
                        {suggestions.map((school, index) => (
                            <li key={index}>
                                <strong>
                                    {school.schoolName}
                                    <li className='list-school-info'>
                                        {school.address}, {school.town}, {school.province}, {school.country}
                                    </li>
                                </strong>
                                <div className='sup_button'>
                                    <Link to="/enrol-your-school"><button className="enroll-btn">Enroll Now</button></Link>
                                    <Link to="/login"><button className='login_btn'>Login</button></Link>

                                </div><br></br>
                                <hr></hr>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>

            <section className='hero'>
                <video autoPlay muted loop className='background-video'>
                    <source src='eschools.mp4' type='video/mp4'></source>
                    <source src='abc1.webm' type='video/webm'></source>
                    Your browser does not support the video tag.
                </video>
                <div className='hero-content'>
                    <h1>Educate Humankind!</h1>
                    <p>Innovation for School Digital Transformation.</p>
                    <a href='#enquiry_form_container' className='btn'>Raise Enquiry</a>
                </div>
            </section>
        </div>
    );
};

export default HeroSection;
