import "../../css/studentPortalSideBar.css";
import AdminLink from "../widgets/AdminLink";
import teacherPortalHome from "../../svg/teacherPortalHome.png";

const TeacherPortalSideBar = () => {
    // alert(teacherPortalHome);
  return (
    <div className="left_side_nav">
      <AdminLink
        url_link="/teacher-portal"
        icon_url={"url('/images/home.png')"}
        description="Dashboard"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/teacher-portal/class-management"
        icon_url={"url('/images/classroom.png')"}
        description="Class Management"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/teacher-portal/student-management"
        icon_url={"url('/images/student_avatar.png')"}
        description="Student Management"
        is_sidebar_link={true}
      />

      <AdminLink
        url_link="/teacher-portal/exam-management"
        icon_url={"url('/images/exam-management.png')"}
        description="Exam Management"
        is_sidebar_link={true}
      />

      <AdminLink
        url_link="/teacher-portal/grading-results"
        icon_url={"url('/images/Grading-results.png')"}
        description="Grading Results"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/teacher-portal/uploading-results"
        icon_url={"url('/images/uploading-results.png')"}
        description="Uploading Results"
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/teacher-portal/settings"
        icon_url={"url('/images/settings.png')"}
        description="Settings"
        is_sidebar_link={true}
      />
    </div>
  );
};

export default TeacherPortalSideBar;
