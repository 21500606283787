import manage_teacher_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import TeacherManagementModalPopUp from "../../teacherManagement/teacherManagementComponents/teacherManagementModalPopUp"
import TeacherManagementAddTeacherLoader from "../../teacherManagement/teacherManagementComponents/teacherManagementAddTeacherLoader"
import { useState } from "react"

export default function AddStudent({ onClose }) {
    const [displaymodalpopup, setDisplayModalPopup] = useState(false)

    const handleModalPopUp = () => {
        setDisplayModalPopup(!displaymodalpopup)
    }
    return <div className="manage_teachers_addteacher_sub_container">
        <div>
            {displaymodalpopup && <TeacherManagementModalPopUp onClose={onClose} text={"Student being added please wait ..."} icon={<TeacherManagementAddTeacherLoader />} />}
        </div>
        <div className="manage_teachers_addteacher_hide_button" onClick={onClose}>
            <img src={manage_teacher_icon_cancelbutton} />
        </div>
        <div className="manage_teachers_addteacher_sub_container_title">Add Student</div>
        <div>
            <form>

                <div className="manage_teachers_addteacher_form_item">
                    <div>
                        <label>First Name</label>
                        <input type="text" name="firstName" placeholder="Enter the student name" />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input type="text" name="lastName" placeholder="Enter the student last name" />
                    </div>

                    <div>
                        <label>Date of Birth</label>
                        <input type="text" name="dob" placeholder="Enter the student D.O.B" />
                    </div>

                    <div>
                        <label>Gender</label>
                        <input type="text" name="gender" placeholder="Enter the student gender" />
                    </div>
                </div>

                <div className="manage_teachers_addteacher_form_item">
                    <div>
                        <label>Parent's phone number</label>
                        <input type="number" name="parentPhone" placeholder="Should start with 263" />
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="email" name="email" placeholder="Enter the student Email" />
                    </div>
                    <div>
                        <label>Home Address</label>
                        <input type="text" name="homeAddress" placeholder="" />
                    </div>
                </div>


                <div className="manage_teachers_addteacher_form_item">
                    <div>
                        <label>Country</label>
                        <input type="text" name="country" placeholder="" />
                    </div>
                    <div>
                        <label>City/Town</label>
                        <input type="town" name="city" placeholder="" />
                    </div>
                    <div>
                        <label>Province</label>
                        <input type="text" name="province" placeholder="" />
                    </div>

                    <div>
                        <label>District</label>
                        <input type="text" name="district" placeholder="" />
                    </div>

                </div>


                <div className="manage_teachers_addteacher_form_submit_button_container">
                    <div onClick={handleModalPopUp} className="manage_teachers_addteacher_form_submit_button">
                        Submit
                    </div>
                </div>

            </form>
        </div>
    </div>

}