import FormInput from "./FormInput";
import "../../css/enrolment.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { 
  API_URL, 
  createSchool,
} from "../../globalConfigs/apiConfigs";

const EnrolmentForm = ({
  heading_title,
  form_description,
  isPreview,
  formData,
  setFormData,
  setPreviewDisplay,
  schoolLogo,
  setSchoolLogo,
  paymentReceipt,
  setPaymentReceipt,
  schoolLogoName,
  setSchoolLogoName,
  paymentReceiptName,
  setPaymentReceiptName,
  schoolOwnership,
  setSchoolOwnership,
  hasFormSubmittedSuccessfully,
  setHasFormSubmittedSuccessfully,
  showSuccessErrorModal,
  setShowSuccessErrorModal,
  showLoading,
  setShowLoading,
}) => {
  const [logoFileTypeError, setLogoFileTypeError] = useState(false);
  const [receiptFileTypeError, setReceiptFileTypeError] = useState(false);

  const [logoFileSizeError, setLogoFileSizeError] = useState(false);
  const [receiptFileSizeError, setReceiptFileSizeError] = useState(false);

  const [isProvinceSelectionErrorHidden, setIsProvinceSelectionErrorHidden] =
    useState(true);
  const [isDistrictSelectionErrorHidden, setIsDistrictSelectionErrorHidden] =
    useState(true);
  const [districtError, setDistrictError] = useState("");

  const [areSomeInputsInvalidOrEmpty, setAreSomeInputsInvalidOrEmpty] =
    useState("");

  function areAllRequiredInputsValid() {
    const are_all_required_inputs_valid_and_not_empty =
      !(!formData["name"] || formData["name"] === 0) &&
      !(!formData["headFirstname"] || formData["headFirstname"] === 0) &&
      !(!formData["headLastname"] || formData["headLastname"] === 0) &&
      !(!formData["headPhonenumber"] || formData["headPhonenumber"] === 0) &&
      !(
        !formData["headEmail"] ||
        formData["headEmail"] === 0 ||
        !isEmailValid(formData["headEmail"])
      ) &&
      !(
        !formData["schoolEmail"] ||
        formData["schoolEmail"] === 0 ||
        !isEmailValid(formData["schoolEmail"])
      ) &&
      !(!formData["town"] || formData["town"] === 0) &&
      !(!formData["adminPhonenumber"] || formData["adminPhonenumber"] === 0) &&
      !(!formData["adminFirstname"] || formData["adminFirstname"] === 0) &&
      !(!formData["adminLastname"] || formData["adminLastname"] === 0) &&
      !(
        !formData["adminEmail"] ||
        formData["adminEmail"] === 0 ||
        !isEmailValid(formData["adminEmail"])
      ) &&
      !(!formData["adminPhonenumber"] || formData["adminPhonenumber"] === 0) &&
      !(!formData["streetName"] || formData["streetName"] === 0) &&
      !(!formData["schoolContact"] || formData["schoolContact"] === 0) &&
      !(!formData["district"] || formData["district"] === 0) &&
      !(!formData["province"] || formData["province"] === 0) &&
      !(!formData["country"] || formData["country"] === 0) &&
      !(
        !formData["responsibleAuthority"] ||
        formData["responsibleAuthority"] === 0
      ) &&
      !(!formData["ownershipType"] || formData["ownershipType"] === 0);

    console.log(are_all_required_inputs_valid_and_not_empty);
    if (are_all_required_inputs_valid_and_not_empty) {
      setPreviewDisplay(true);
    }
  }

  function isEmailValid(email) {
    const email_validity = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return email_validity;
  }

  function checkFileType(file_name, accepted_file_types_array) {
    if (!file_name) {
      return;
    }
    const file_extension = "." + file_name.split(".").pop().toLowerCase();
    return accepted_file_types_array.includes(file_extension);
  }

  function checkFileSize(file_size) {
    return parseInt(file_size) < 5250000;
  }

  function addInputDataToFormData(input, input_name) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [input_name]: input.trimStart(),
    }));
    console.log("form data", formData);
  }

  const handleSchoolLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    setSchoolLogo(selectedFile);
  };

  const handlePaymentReceiptChange = (e) => {
    const selectedFile = e.target.files[0];
    setPaymentReceipt(selectedFile);
  };

  const handleSubmit = () => {
    setShowLoading(true);
  
    if (!formData) {
      return;
    }
  
    const complete_form_data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        complete_form_data.append(key, formData[key]);
      }
    });
  
    if (schoolLogo) complete_form_data.append("logo", schoolLogo);
    if (paymentReceipt) complete_form_data.append("receipt", paymentReceipt);
   
    for (const [key, value] of complete_form_data.entries()) {
      console.log(`${key}:`, value);
    }
   
    fetch(`${API_URL}${createSchool}`, {
      method: "POST",
      body: complete_form_data
    })
      .then((response) => {  
        if (response.ok) {
          setPreviewDisplay(false);
          setHasFormSubmittedSuccessfully(true);
          setShowSuccessErrorModal(true);
          clearInputs();
          return
        } 
      }) 
      .catch((error) => { 
        setShowLoading(false);
        setHasFormSubmittedSuccessfully(false);
        setShowSuccessErrorModal(true);
      })
      .finally(() => {
        setShowLoading(false); 
      });
  };
  
  function clearInputs() {
    setFormData({
      name: "",
      streetName: "",
      town: "",
      district: "",
      province: "",
      country: "",
      schoolContact: "",
      schoolEmail: "",
      headFirstname: "",
      headLastname: "",
      headEmail: "",
      headPhonenumber: "",
      adminFirstname: "",
      adminLastname: "",
      adminPhone: "",
      adminEmail: "",
      adminPhonenumber: "",
      numberOfStudents: "",
      numberOfTeachers: "",
      adminNote: "",
      ownershipType: "",
      paymentReferenceNumber: "",
      receipt: "",
      paymentMethod: "",
      logo: "",
    });
    setSchoolLogoName("");
    setPaymentReceiptName("");
    setAreSomeInputsInvalidOrEmpty("");
    document
      .querySelector(".empty_field_error")
      .classList.remove("displayed_flex");
  }

  return (
    <div className="enrolment_form">
      {isPreview && (
        <button
          className="hide_parent"
          onClick={(e) => {
            e.preventDefault();
            setPreviewDisplay(false);
          }}
        >
          +
        </button>
      )}
      {!isPreview && heading_title && <h1>{heading_title}</h1>}
      {form_description && (
        <p className="form_description">{form_description}</p>
      )}
      <div className="form_inputs">
        {!isPreview ? (
          <>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`name`}
                placeholder="Enter name of school"
                title="Enter the full name of the school"
                name="name"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "name");
                }}
                value={formData["name"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`headFirstname`}
                placeholder="Enter name of school head"
                title="Enter the first name of the school Head/ Principal"
                name="headFirstname"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "headFirstname");
                }}
                value={formData["headFirstname"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`headLastname`}
                placeholder="Enter last name of school head"
                title="Enter the last name of the school Head/ Principal"
                name="headLastname"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "headLastname");
                }}
                value={formData["headLastname"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`headPhonenumber`}
                placeholder="Enter the school Head’s/ Principal’s phone number"
                title="Enter school head phone"
                name="headPhonenumber"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "headPhonenumber");
                }}
                value={formData["headPhonenumber"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`headEmail`}
                placeholder="Enter the school Head’s/ Principal’s email"
                title="Enter email of school head"
                name="headEmail"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "headEmail");
                }}
                value={formData["headEmail"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`adminFirstname`}
                placeholder="Enter the admin's first name"
                title="Enter the first name of the school Admin Officer"
                name="adminFirstname"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "adminFirstname");
                }}
                value={formData["adminFirstname"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`adminLastname`}
                placeholder="Enter the admin's last name"
                title="Enter the last name of the school Admin Officer"
                name="adminLastname"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "adminLastname");
                }}
                value={formData["adminLastname"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`adminPhonenumber`}
                placeholder="Enter school admin phone"
                title="Enter the phone number of the school Admin Officer"
                name="adminPhonenumber"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "adminPhonenumber");
                }}
                value={formData["adminPhonenumber"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`adminEmail`}
                placeholder="Enter admin email"
                title="Enter the school Admin Officer’s email"
                name="adminEmail"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "adminEmail");
                }}
                value={formData["adminEmail"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`schoolContact`}
                placeholder="Enter the official phone number of the school"
                title="Enter school phone number"
                name="schoolContact"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "schoolContact");
                }}
                value={formData["schoolContact"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`schoolEmail`}
                placeholder="Enter school email address"
                title="Enter the official email address of the school"
                name="schoolEmail"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "schoolEmail");
                }}
                value={formData["schoolEmail"]}
              />
              <button
                className="tooltip_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement
                    .querySelector(".tooltip")
                    .classList.toggle("displayed_flex");
                  e.target.classList.toggle("tooltip_btn_clicked");
                }}
              >
                ?
              </button>
              <div className="tooltip">
                Please enter the school's official email address, as provided by
                the administration. This should be a valid, active email used
                for official communication.
              </div>
            </div>
            <div className="address_legend">School address</div>
            <div className="address_container">
              <div className="form_input_container">
                <label htmlFor="streetName">
                  Enter the street name of the school
                </label>
                <textarea
                  required
                  id={`streetName`}
                  placeholder="Enter school street name"
                  title="Enter the streetName of the school"
                  name="streetName"
                  onChange={(e) => {
                    addInputDataToFormData(e.target.value, "streetName");
                  }}
                  value={formData["streetName"]}
                ></textarea>
              </div>
              <div className="form_input_container">
                <label htmlFor="country">
                  Select the name of the school country
                </label>
                <select
                  id={`country`}
                  name="country"
                  onChange={(e) => {
                    addInputDataToFormData(e.target.value, "country");
                  }}
                  value={formData["country"]}
                >
                  <option value="">Select the school country</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>
              <div className="province_container">
                <p
                  className={`province_error ${
                    !isProvinceSelectionErrorHidden ? " displayed_flex" : ""
                  }`}
                >
                  <span>Select the country first</span>
                  <button
                    className="close_parent"
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.parentElement.classList.remove("displayed_flex");
                      setIsProvinceSelectionErrorHidden(true);
                    }}
                  >
                    +
                  </button>
                </p>
                {formData?.country === "Zimbabwe" ? (
                  <div className="form_input_container">
                    <label htmlFor="province">Select the province</label>
                    <select
                      id={`province`}
                      name="province"
                      onChange={(e) => {
                        addInputDataToFormData(e.target.value, "province");
                      }}
                      required
                    >
                      <option value="">Select the province</option>
                      <option value="Bulawayo">Bulawayo</option>
                      <option value="Harare">Harare</option>
                      <option value="Manicaland">Manicaland</option>
                      <option value="Mashonaland Central">
                        Mashonaland Central
                      </option>
                      <option value="Mashonaland East">Mashonaland East</option>
                      <option value="Mashonaland West">Mashonaland West</option>
                      <option value="Masvingo">Masvingo</option>
                      <option value="Matabeleland North">
                        Matabeleland North
                      </option>
                      <option value="Matabeleland South">
                        Matabeleland South
                      </option>
                      <option value="Midlands">Midlands</option>
                    </select>
                  </div>
                ) : (
                  <FormInput
                    type="text"
                    id={`province`}
                    placeholder="Enter your school province"
                    title="Name of Province"
                    name="province"
                    onChange={(e) => {
                      if (!formData?.country || formData?.country === "") {
                        setIsProvinceSelectionErrorHidden(false);
                        return;
                      }
                      addInputDataToFormData(e.target.value, "province");
                    }}
                    value={formData?.province}
                  />
                )}
              </div>
              <div className="district_container">
                <p
                  className={`district_error ${
                    !isDistrictSelectionErrorHidden ? " displayed_flex" : ""
                  }`}
                >
                  <span>{districtError}</span>
                  <button
                    className="close_parent"
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.parentElement.classList.remove("displayed_flex");
                      setIsDistrictSelectionErrorHidden(true);
                    }}
                  >
                    +
                  </button>
                </p>
                {formData?.country === "Zimbabwe" ? (
                  <div className="form_input_container">
                    <label htmlFor="district">
                      Select the name of the school district
                    </label>
                    <select
                      name="district"
                      id="district"
                      onChange={(e) => {
                        const selected_option = e.target.selectedOptions[0];
                        if (
                          selected_option.getAttribute("data_province") !==
                          formData?.province
                        ) {
                          setDistrictError(
                            `${
                              !formData?.province
                                ? "Please select a province first"
                                : `${selected_option.value} district does not belong in ${formData?.province} province`
                            }`
                          );
                          addInputDataToFormData("", "district");
                          setIsDistrictSelectionErrorHidden(false);
                          return;
                        }
                        addInputDataToFormData(e.target.value, "district");
                      }}
                      className="zim_district selectd"
                    >
                      <option value="">Select the school district</option>
                      <optgroup label="Manicaland">
                        <option value="Buhera" data_province="Manicaland">
                          Buhera
                        </option>
                        <option value="Chimanimani" data_province="Manicaland">
                          Chimanimani
                        </option>
                        <option value="Chipinge" data_province="Manicaland">
                          Chipinge
                        </option>
                        <option value="Makoni" data_province="Manicaland">
                          Makoni
                        </option>
                        <option value="Mutare Rural" data_province="Manicaland">
                          Mutare Rural
                        </option>
                        <option value="Mutasa" data_province="Manicaland">
                          Mutasa
                        </option>
                        <option value="Nyanga" data_province="Manicaland">
                          Nyanga
                        </option>
                        <option value="Mutare Urban" data_province="Manicaland">
                          Mutare (Urban)
                        </option>
                        <option value="Rusape" data_province="Manicaland">
                          Rusape
                        </option>
                        <option
                          value="Chipinge Urban"
                          data_province="Manicaland"
                        >
                          Chipinge (Urban)
                        </option>
                      </optgroup>
                      <optgroup label="Mashonaland Central">
                        <option
                          value="Bindura"
                          data_province="Mashonaland Central"
                        >
                          Bindura
                        </option>
                        <option
                          value="Centenary"
                          data_province="Mashonaland Central"
                        >
                          Centenary
                        </option>
                        <option
                          value="Guruve"
                          data_province="Mashonaland Central"
                        >
                          Guruve
                        </option>
                        <option
                          value="Mazowe"
                          data_province="Mashonaland Central"
                        >
                          Mazowe
                        </option>
                        <option
                          value="Mount Darwin"
                          data_province="Mashonaland Central"
                        >
                          Mount Darwin
                        </option>
                        <option
                          value="Rushinga"
                          data_province="Mashonaland Central"
                        >
                          Rushinga
                        </option>
                        <option
                          value="Shamva"
                          data_province="Mashonaland Central"
                        >
                          Shamva
                        </option>
                        <option
                          value="Mbire"
                          data_province="Mashonaland Central"
                        >
                          Mbire
                        </option>
                        <option
                          value="Bindura Urban"
                          data_province="Mashonaland Central"
                        >
                          Bindura (Urban)
                        </option>
                        <option
                          value="Mvurwi"
                          data_province="Mashonaland Central"
                        >
                          Mvurwi
                        </option>
                      </optgroup>
                      <optgroup label="Mashonaland East">
                        <option
                          value="Chikomba"
                          data_province="Mashonaland East"
                        >
                          Chikomba
                        </option>
                        <option
                          value="Goromonzi"
                          data_province="Mashonaland East"
                        >
                          Goromonzi
                        </option>
                        <option value="Hwedza" data_province="Mashonaland East">
                          Hwedza
                        </option>
                        <option
                          value="Marondera"
                          data_province="Mashonaland East"
                        >
                          Marondera
                        </option>
                        <option value="Mudzi" data_province="Mashonaland East">
                          Mudzi
                        </option>
                        <option
                          value="Murehwa"
                          data_province="Mashonaland East"
                        >
                          Murehwa
                        </option>
                        <option value="Mutoko" data_province="Mashonaland East">
                          Mutoko
                        </option>
                        <option value="Seke" data_province="Mashonaland East">
                          Seke
                        </option>
                        <option
                          value="Uzumba-Maramba-Pfungwe"
                          data_province="Mashonaland East"
                        >
                          Uzumba-Maramba-Pfungwe
                        </option>
                        <option
                          value="Marondera Urban"
                          data_province="Mashonaland East"
                        >
                          Marondera (Urban)
                        </option>
                        <option
                          value="Chivhu Town Board"
                          data_province="Mashonaland East"
                        >
                          Chivhu Town Board
                        </option>
                        <option
                          value="Ruwa Local Board"
                          data_province="Mashonaland East"
                        >
                          Ruwa Local Board
                        </option>
                      </optgroup>
                      <optgroup label="Mashonaland West">
                        <option
                          value="Chegutu"
                          data_province="Mashonaland West"
                        >
                          Chegutu
                        </option>
                        <option
                          value="Hurungwe"
                          data_province="Mashonaland West"
                        >
                          Hurungwe
                        </option>
                        <option value="Kariba" data_province="Mashonaland West">
                          Kariba
                        </option>
                        <option
                          value="Makonde"
                          data_province="Mashonaland West"
                        >
                          Makonde
                        </option>
                        <option
                          value="Mhondoro-Ngezi"
                          data_province="Mashonaland West"
                        >
                          Mhondoro-Ngezi
                        </option>
                      </optgroup>
                      <optgroup label="Matabeleland North">
                        <option
                          value="Binga"
                          data_province="Matabeleland North"
                        >
                          Binga
                        </option>
                        <option value="Bubi" data_province="Matabeleland North">
                          Bubi
                        </option>
                        <option
                          value="Hwange"
                          data_province="Matabeleland North"
                        >
                          Hwange
                        </option>
                        <option
                          value="Lupane"
                          data_province="Matabeleland North"
                        >
                          Lupane
                        </option>
                        <option
                          value="Nkayi"
                          data_province="Matabeleland North"
                        >
                          Nkayi
                        </option>
                        <option
                          value="Tsholotsho"
                          data_province="Matabeleland North"
                        >
                          Tsholotsho
                        </option>
                        <option
                          value="Umguza"
                          data_province="Matabeleland North"
                        >
                          Umguza
                        </option>
                        <option
                          value="Victoria Falls"
                          data_province="Matabeleland North"
                        >
                          Victoria Falls
                        </option>
                      </optgroup>
                      <optgroup label="Matabeleland South">
                        <option
                          value="Beitbridge Rural"
                          data_province="Matabeleland South"
                        >
                          Beitbridge Rural
                        </option>
                        <option
                          value="Bulilima"
                          data_province="Matabeleland South"
                        >
                          Bulilima
                        </option>
                        <option
                          value="Mangwe"
                          data_province="Matabeleland South"
                        >
                          Mangwe
                        </option>
                        <option
                          value="Gwanda Rural"
                          data_province="Matabeleland South"
                        >
                          Gwanda Rural
                        </option>
                        <option
                          value="Insiza"
                          data_province="Matabeleland South"
                        >
                          Insiza
                        </option>
                        <option
                          value="Matobo"
                          data_province="Matabeleland South"
                        >
                          Matobo
                        </option>
                        <option
                          value="Umzingwane"
                          data_province="Matabeleland South"
                        >
                          Umzingwane
                        </option>
                        <option
                          value="Gwanda Urban"
                          data_province="Matabeleland South"
                        >
                          Gwanda (Urban)
                        </option>
                        <option
                          value="Beitbridge Urban"
                          data_province="Matabeleland South"
                        >
                          Beitbridge (Urban)
                        </option>
                        <option
                          value="Plumtree"
                          data_province="Matabeleland South"
                        >
                          Plumtree
                        </option>
                      </optgroup>
                      <optgroup label="Midlands">
                        <option value="Chirumhanzu" data_province="Midlands">
                          Chirumhanzu
                        </option>
                        <option value="Gokwe North" data_province="Midlands">
                          Gokwe North
                        </option>
                        <option value="Gokwe South" data_province="Midlands">
                          Gokwe South
                        </option>
                        <option value="Gweru Rural" data_province="Midlands">
                          Gweru Rural
                        </option>
                        <option value="Kwekwe Rural" data_province="Midlands">
                          Kwekwe Rural
                        </option>
                        <option value="Mberengwa" data_province="Midlands">
                          Mberengwa
                        </option>
                        <option value="Shurugwi" data_province="Midlands">
                          Shurugwi
                        </option>
                        <option value="Zvishavane" data_province="Midlands">
                          Zvishavane
                        </option>
                        <option value="Gweru Urban" data_province="Midlands">
                          Gweru (Urban)
                        </option>
                        <option value="Kwekwe Urban" data_province="Midlands">
                          Kwekwe (Urban)
                        </option>
                        <option value="Redcliff" data_province="Midlands">
                          Redcliff
                        </option>
                        <option value="Gokwe Centre" data_province="Midlands">
                          Gokwe Centre
                        </option>
                      </optgroup>
                      <optgroup label="Masvingo">
                        <option value="Bikita" data_province="Masvingo">
                          Bikita
                        </option>
                        <option value="Chiredzi" data_province="Masvingo">
                          Chiredzi
                        </option>
                        <option value="Chivi" data_province="Masvingo">
                          Chivi
                        </option>
                        <option value="Gutu" data_province="Masvingo">
                          Gutu
                        </option>
                        <option value="Masvingo Rural" data_province="Masvingo">
                          Masvingo Rural
                        </option>
                        <option value="Mwenezi" data_province="Masvingo">
                          Mwenezi
                        </option>
                        <option value="Zaka" data_province="Masvingo">
                          Zaka
                        </option>
                        <option value="Masvingo Urban" data_province="Masvingo">
                          Masvingo (Urban)
                        </option>
                        <option value="Chiredzi Town" data_province="Masvingo">
                          Chiredzi (Town)
                        </option>
                      </optgroup>
                      <optgroup label="Harare">
                        <option value="Harare Rural" data_province="Harare">
                          Harare Rural
                        </option>
                        <option value="Harare Urban" data_province="Harare">
                          Harare (Urban)
                        </option>
                        <option value="Chitungwiza" data_province="Harare">
                          Chitungwiza
                        </option>
                        <option value="Epworth" data_province="Harare">
                          Epworth
                        </option>
                      </optgroup>
                      <optgroup label="Bulawayo">
                        <option value="Bulawayo Urban" data_province="Bulawayo">
                          Bulawayo (Urban)
                        </option>
                      </optgroup>
                    </select>
                  </div>
                ) : (
                  <FormInput
                    type="text"
                    id={`district`}
                    placeholder="Enter the name of your school district"
                    title="Enter the name of your school district"
                    name="district"
                    onChange={(e) => {
                      if (!formData?.country || formData?.country === "") {
                        setDistrictError("Select the country first");
                        setIsDistrictSelectionErrorHidden(false);
                        return;
                      }
                      addInputDataToFormData(e.target.value, "district");
                    }}
                    value={formData?.district}
                  />
                )}
              </div>
              <div className="form_input_container">
                <FormInput
                  type="text"
                  required
                  id={`town`}
                  placeholder="Enter the name of the school city/town"
                  title="Enter the school city/town"
                  name="town"
                  onChange={(e) => {
                    addInputDataToFormData(e.target.value, "town");
                  }}
                  value={formData["town"]}
                />
              </div>
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                required
                id={`responsibleAuthority`}
                placeholder="Enter responsible authority"
                title="Enter the name of the school Responsible Authority"
                name="responsibleAuthority"
                onChange={(e) => {
                  addInputDataToFormData(
                    e.target.value,
                    "responsibleAuthority"
                  );
                }}
                value={formData["responsibleAuthority"]}
              />
            </div>
            <div className="form_input_container school_ownership">
              <h3>Select the school Ownership</h3>
              <FormInput
                type="radio"
                required
                name="ownershipType"
                value="Church"
                checked={schoolOwnership === "Church"}
                title="Church"
                onChange={(e) => {
                  setSchoolOwnership(e.target.value.toUpperCase());
                  addInputDataToFormData(e.target.value.toUpperCase(), "ownershipType");
                }}
              />
              <FormInput
                type="radio"
                required
                name="ownershipType"
                value="Council"
                checked={schoolOwnership === "Council"}
                title="Council"
                onChange={(e) => {
                  setSchoolOwnership(e.target.value.toUpperCase());
                  addInputDataToFormData(e.target.value, "ownershipType");
                }}
              />
              <FormInput
                type="radio"
                required
                name="ownershipType"
                value="Government"
                checked={schoolOwnership === "Government"}
                title="Government"
                onChange={(e) => {
                  setSchoolOwnership(e.target.value.toUpperCase());
                  addInputDataToFormData(e.target.value.toUpperCase(), "ownershipType");
                }}
              />
              <FormInput
                type="radio"
                required
                name="ownershipType"
                value="Private"
                checked={schoolOwnership === "Private"}
                title="Private"
                onChange={(e) => {
                  setSchoolOwnership(e.target.value.toUpperCase());
                  addInputDataToFormData(e.target.value.toUpperCase(), "ownershipType");
                }}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="file"
                id={`logo`}
                name="logo"
                title="Upload the school logo (only jpg, jpeg, png, svg and pdf file formats only. File size should be 5MB or less)"
                accept=".jpg, .jpeg, .png, .svg, .pdf"
                onChange={(e) => {
                  setLogoFileTypeError(false);
                  setLogoFileSizeError(false);
                  setSchoolLogoName("");
                  if (
                    !checkFileType(e.target?.files[0]?.name, [
                      ".jpg",
                      ".jpeg",
                      ".png",
                      ".svg",
                      ".pdf",
                    ])
                  ) {
                    setLogoFileTypeError(true);
                    setTimeout(() => {
                      setLogoFileTypeError(false);
                    }, 5000);
                  } else if (!checkFileSize(e.target?.files[0]?.size)) {
                    setLogoFileSizeError(true);
                    setTimeout(() => {
                      setLogoFileSizeError(false);
                    }, 5000);
                  } else {
                    setSchoolLogoName(e.target.value);
                    handleSchoolLogoChange(e);
                  }
                }}
                value={schoolLogoName}
              />
              <p
                className={`file_type_and_size_error ${
                  logoFileTypeError ? "displayed_flex" : ""
                }`}
              >
                Invalid file type. Only jpg, jpeg, png, svg and pdf accepted
              </p>
              <p
                className={`file_type_and_size_error ${
                  logoFileSizeError ? "displayed_flex" : ""
                }`}
              >
                File size should be 5MB or less
              </p>
              {schoolLogoName.length > 0 && (
                <button
                  className="clear_file_input"
                  onClick={(e) => {
                    e.preventDefault();
                    setSchoolLogoName("");
                    setSchoolLogo(null);
                  }}
                >
                  +
                </button>
              )}
            </div>
            <div className="form_input_container payment_receipt">
              <FormInput
                type="file"
                id={`receipt`}
                name="receipt"
                title="Upload the payment receipt (if the school has paid)"
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={(e) => {
                  setReceiptFileTypeError(false);
                  setReceiptFileSizeError(false);
                  setPaymentReceiptName("");
                  if (
                    !checkFileType(e.target?.files[0]?.name, [
                      ".jpg",
                      ".jpeg",
                      ".png",
                      ".svg",
                      ".pdf",
                    ])
                  ) {
                    setReceiptFileTypeError(true);
                    setTimeout(() => {
                      setReceiptFileTypeError(false);
                    }, 5000);
                  } else if (!checkFileSize(e.target.files[0].size)) {
                    setReceiptFileSizeError(true);
                    setTimeout(() => {
                      setReceiptFileSizeError(false);
                    }, 5000);
                  } else {
                    setPaymentReceiptName(e.target?.value);
                    handlePaymentReceiptChange(e);
                  }
                }}
                value={paymentReceiptName}
              />
              <p
                className={`file_type_and_size_error ${
                  receiptFileTypeError ? "displayed_flex" : ""
                }`}
              >
                Invalid file type. Only jpg, jpeg, png and pdf accepted
              </p>
              <p
                className={`file_type_and_size_error ${
                  receiptFileSizeError ? "displayed_flex" : ""
                }`}
              >
                File size should be 5MB or less
              </p>
              <button
                className="tooltip_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement
                    .querySelector(".tooltip")
                    .classList.toggle("displayed_flex");
                  e.target.classList.toggle("tooltip_btn_clicked");
                }}
              >
                ?
              </button>
              <div className="tooltip">
                If the school has paid, please upload the payment receipt. If
                the school hasn’t paid, leave this blank
              </div>
              {paymentReceiptName.length > 0 && (
                <button
                  className="clear_file_input"
                  onClick={(e) => {
                    e.preventDefault();
                    setPaymentReceiptName("");
                    setPaymentReceipt(null);
                  }}
                >
                  +
                </button>
              )}
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                id={`paymentReferenceNumber`}
                placeholder="Enter payment reference number"
                title="Enter the payment reference number (if the school has paid)"
                name="paymentReferenceNumber"
                onChange={(e) => {
                  addInputDataToFormData(
                    e.target.value,
                    "paymentReferenceNumber"
                  );
                }}
                value={formData["paymentReferenceNumber"]}
              />
              <button
                className="tooltip_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement
                    .querySelector(".tooltip")
                    .classList.toggle("displayed_flex");
                  e.target.classList.toggle("tooltip_btn_clicked");
                }}
              >
                ?
              </button>
              <div className="tooltip">
                If the school has paid, please enter the payment reference
                number. If the school hasn’t paid, leave this blank.
              </div>
            </div> 
            <div className="form_input_container">
              <label htmlFor="paymentMethod">Select the payment method</label>
              <select
                id={`paymentMethod`}
                name="paymentMethod"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "paymentMethod");
                }} 
              >
                <option value="">Select the payment method</option>
                <option value="CASH">CASH</option>
                <option value="BANK">BANK</option>
                <option value="ECOCASH">ECOCASH</option>
                <option value="MUKURU">MUKURU</option>
              </select>
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                id={`numberOfStudents`}
                placeholder="Enter the number of students"
                title="Enter the number of students"
                name="numberOfStudents"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "numberOfStudents");
                }}
                value={formData["numberOfStudents"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                id={`numberOfTeachers`}
                placeholder="Enter the number of teachers"
                title="Enter the number of teachers"
                name="numberOfTeachers"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "numberOfTeachers");
                }}
                value={formData["numberOfTeachers"]}
              />
            </div>
            <div className="form_input_container">
              <label htmlFor="adminNote">You may leave a note</label>
              <textarea
                required
                id={`preview__adminNote`}
                placeholder="Enter your message"
                title="You may leave a note"
                name="adminNote"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "adminNote");
                }}
                value={formData["adminNote"]}
              ></textarea>
            </div>
          </>
        ) : (
          <>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__name`}
                placeholder="Enter name of school"
                title="Enter the full name of the school"
                name="name" 
                value={formData["name"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__headFirstname`}
                placeholder="Enter first name of school head"
                title="Enter the first name of the school Head/ Principal"
                name="Enter name of school head" 
                value={formData["headFirstname"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__headLastname`}
                placeholder="Enter last name of school head"
                title="Enter the last name of the school Head/ Principal"
                name="headLastname" 
                value={formData["headLastname"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__headPhonenumber`}
                placeholder="Enter school head phone"
                title="Enter the school Head’s/ Principal’s phone number"
                name="headPhonenumber" 
                value={formData["headPhonenumber"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__headEmail`}
                placeholder="Enter email of school head"
                title="Enter the school Head’s/ Principal’s email"
                name="headEmail" 
                value={formData["headEmail"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__adminFirstname`}
                placeholder="Enter the admin's first name"
                title="Enter the first name of the school Admin Officer"
                name="adminFirstname" 
                value={formData["adminFirstname"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__adminLastname`}
                placeholder="Enter the admin's last name"
                title="Enter the last name of the school Admin Officer"
                name="adminLastname" 
                value={formData["adminLastname"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__adminPhonenumber`}
                placeholder="Enter school admin phone"
                title="Enter the phone number of the school Admin Officer"
                name="adminPhonenumber" 
                value={formData["adminPhonenumber"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__adminEmail`}
                placeholder="Enter admin email"
                title="Enter the school Admin Officer’s email"
                name="adminEmail" 
                value={formData["adminEmail"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__schoolContact`}
                placeholder="Enter school phone number"
                title="Enter the official phone number of the school"
                name="schoolContact" 
                value={formData["schoolContact"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__schoolEmail`}
                placeholder="Enter school email address"
                title="Enter the official email address of the school"
                name="schoolEmail" 
                value={formData["schoolEmail"]}
                disabled
              />
              <button
                className="tooltip_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement
                    .querySelector(".tooltip")
                    .classList.toggle("displayed_flex");
                  e.target.classList.toggle("tooltip_btn_clicked");
                }}
              >
                ?
              </button>
              <div className="tooltip">
                Please enter the school's official email address, as provided by
                the administration. This should be a valid, active email used
                for official communication.
              </div>
            </div>
            <div className="address_legend">School street name</div>
            <div className="address_container">
              <div className="form_input_container disabled_input">
                <label htmlFor="school_address">
                  Enter the address of the school
                </label>
                <textarea
                  required
                  id={`preview__streetName`}
                  placeholder="Enter school street name"
                  title="Enter the street name of the school"
                  name="previewStreetName"
                  value={formData["streetName"]}
                  disabled
                ></textarea>
              </div>
              <div className="form_input_container disabled_input">
                <FormInput
                  type="text"
                  required
                  id={`preview__district`}
                  placeholder="Enter the school district"
                  title="Enter the name of the school district"
                  name="district" 
                  value={formData["district"]}
                  disabled
                />
              </div>
              <div className="form_input_container disabled_input">
                <FormInput
                  type="text"
                  required
                  id={`preview__town`}
                  placeholder="Enter the school city/town"
                  title="Enter the name of the school city/town"
                  name="town" 
                  value={formData["town"]}
                  disabled
                />
              </div>
              <div className="form_input_container disabled_input">
                <FormInput
                  type="text"
                  required
                  id={`preview__province`}
                  placeholder="Enter the school province"
                  title="Enter the name of the school province"
                  name="province" 
                  value={formData["province"]}
                  disabled
                />
              </div>
              <div className="form_input_container disabled_input">
                <FormInput
                  type="text"
                  required
                  id={`preview__country`}
                  title="Select the school country"
                  name="country"
                  value={formData["country"]}
                  disabled
                />
              </div>
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__responsibleAuthority`}
                placeholder="Enter responsible authority"
                title="Enter the name of the school Responsible Authority"
                name="responsibleAuthority" 
                value={formData["responsibleAuthority"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                required
                id={`preview__ownershipType`}
                title="Select the school Ownership"
                name="ownershipType"
                value={formData["ownershipType"]}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input file_input">
              <FormInput
                type="text"
                required
                id={`preview__logo`}
                name="logo"
                title="Upload the school logo (only jpg, jpeg, png, svg and pdf file formats only. File size should be 5MB or less)"
                accept=".jpg, .jpeg, .png, .svg, .pdf"
                value={schoolLogoName}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input payment_receipt file_input">
              <FormInput
                type="text"
                id={`preview__receipt`}
                name="receipt"
                title="Upload the payment receipt (if the school has paid)"
                accept=".jpg, .jpeg, .png, .pdf"
                value={paymentReceiptName}
                disabled
              />
            </div>
            <div className="form_input_container disabled_input">
              <FormInput
                type="text"
                id={`preview__paymentReferenceNumber`}
                placeholder=""
                title="Enter the payment reference number (if the school has paid)"
                name="paymentReferenceNumber" 
                value={formData["paymentReferenceNumber"]}
                disabled
              />
              <button
                className="tooltip_btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement
                    .querySelector(".tooltip")
                    .classList.toggle("displayed_flex");
                  e.target.classList.toggle("tooltip_btn_clicked");
                }}
              >
                ?
              </button>
              <div className="tooltip">
                If the school has paid, please enter the payment reference
                number. If the school hasn’t paid, leave this blank.
              </div>
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                id={`preview__paymentMethod`}
                placeholder="Enter the payment method"
                title="Enter the payment method"
                name="paymentMethod"
                value={formData["paymentMethod"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                id={`preview__numberOfStudents`}
                placeholder="Enter the number of students"
                title="Enter the number of students"
                name="numberOfStudents"
                value={formData["numberOfStudents"]}
              />
            </div>
            <div className="form_input_container">
              <FormInput
                type="text"
                id={`preview__numberOfTeachers`}
                placeholder="Enter the number of teachers"
                title="Enter the number of teachers"
                name="numberOfTeachers"
                value={formData["numberOfTeachers"]}
              />
            </div>
            <div className="form_input_container">
              <label htmlFor="preview__adminNote">You may leave a note</label>
              <textarea
                required
                id={`adminNote`}
                placeholder="Enter your message"
                title="You may leave a note"
                name="preview__adminNote"
                value={formData["adminNote"]}
              ></textarea>
            </div>
          </>
        )}
        {!isPreview && (
          <>
            <div className="terms_and_privacy_notice">
              By submitting this form, you agree to Brainstake's&nbsp;
              <Link to="/terms" target="_blank" rel="noreferrer">
                Terms of Service
              </Link>
              &nbsp;and&nbsp;
              <Link to="/privacy" target="_blank" rel="noreferrer">
                Privacy Policy.
              </Link>{" "}
              Your data is encrypted and securely stored, following
              industry-standard practices to protect your personal information.
            </div>
            <p
              className={`empty_field_error${
                !areSomeInputsInvalidOrEmpty ||
                areSomeInputsInvalidOrEmpty === ""
                  ? ""
                  : " displayed_flex"
              }`}
            >
              {(!formData["name"] || formData["name"] === 0) && (
                <span>Enter name of school</span>
              )}
              {(!formData["headFirstname"] ||
                formData["headFirstname"] === 0) && (
                <span>Enter the first name of school Head/Principal</span>
              )}
              {(!formData["headLastname"] ||
                formData["headLastname"] === 0) && (
                <span>Enter the last name of school Head/Principal</span>
              )}
              {(!formData["headPhonenumber"] ||
                formData["headPhonenumber"] === 0) && (
                <span>Enter the school Head's/Principal's phone number</span>
              )}
              {(!formData["headEmail"] ||
                formData["headEmail"] === 0 ||
                !isEmailValid(formData["headEmail"])) && (
                <span>Enter a valid school Head/Principal's email</span>
              )}
              {(!formData["town"] || formData["town"] === 0) && (
                <span>Enter name of the school city/town</span>
              )}
              {(!formData["adminFirstname"] ||
                formData["adminFirstname"] === 0) && (
                <span>Enter the first name of the school Admin Officer</span>
              )}
              {(!formData["adminLastname"] ||
                formData["adminLastname"] === 0) && (
                <span>Enter the last name of the school Admin Officer</span>
              )}
              {(!formData["adminPhonenumber"] ||
                formData["adminPhonenumber"] === 0) && (
                <span>Enter the phone number of the school Admin Officer</span>
              )}
              {(!formData["adminEmail"] ||
                formData["adminEmail"] === 0 ||
                !isEmailValid(formData["adminEmail"])) && (
                <span>Enter a valid school Admin Officer's email</span>
              )}
              {(!formData["adminPhonenumber"] ||
                formData["adminPhonenumber"] === 0) && (
                <span>Enter school phone number</span>
              )}
              {(!formData["streetName"] || formData["streetName"] === 0) && (
                <span>Enter the street name of the school</span>
              )}
              {(!formData["schoolEmail"] ||
                formData["schoolEmail"] === 0 ||
                !isEmailValid(formData["schoolEmail"])) && (
                <span>Enter a valid email address of the school</span>
              )}
              {(!formData["district"] || formData["district"] === 0) && (
                <span>Enter the name of the school district</span>
              )}
              {(!formData["province"] || formData["province"] === 0) && (
                <span>Enter the name of the school province</span>
              )}
              {(!formData["country"] || formData["country"] === 0) && (
                <span>Select the school country</span>
              )}
              {(!formData["responsibleAuthority"] ||
                formData["responsibleAuthority"] === 0) && (
                <span>Enter the name of the school Responsible Authority</span>
              )}
              {(!formData["ownershipType"] ||
                formData["ownershipType"] === 0) && (
                <span>Select the school Ownership</span>
              )}
            </p>
            <button
              className="submit"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                if (!areAllRequiredInputsValid()) {
                  setAreSomeInputsInvalidOrEmpty(true);
                }
              }}
            >
              Submit
            </button>
          </>
        )}
        {isPreview && (
          <>
            <p className="check_if_correct_data_desc">
              Check if the information you provided is accurate. If not,
              go&nbsp;
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPreviewDisplay(false);
                }}
              >
                back and edit
              </button>
              &nbsp;before completing registration
            </p>
            <button
              className="complete_registration"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                console.log(formData);
                handleSubmit(); 
              }}
            >
              Complete Registration
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EnrolmentForm;
