import React, { useState, useEffect } from 'react';
import '../../../../css/manageResults.css';
import Pagination from './pagination.js';
import GradingPopup from './gradingPopUp.js';
import { CircularProgress } from '@mui/material';
import { API_URL } from '../../../../globalConfigs/apiConfigs.js';
import { token } from '../../../../globalConfigs/userLoginInfo.js';

const ExamClassList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [filterStatus, setFilterStatus] = useState(''); // State for filter status
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);

  useEffect(() => {
    // Fetch data from the API
     fetch(`${API_URL}/api/v1/teachers/65/exams`, {
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      }
  })
      .then(response => response.json())
      .then(apiData => {
        const transformedData = apiData.map(exam => ({
          title: exam.examName,
          subject: `Subject Placeholder`, // Adjust as needed if subject data is available
          date: new Date(exam.examDate).toLocaleDateString(),
          classes: [`Class Placeholder`], // Adjust if classes data is available
          year: exam.year.value,
          status: 'Pending', // Adjust based on API response if available
          totalMarks: exam.outOf,
        }));
        setData(transformedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleGradeClick = (exam) => {
    setSelectedExam(exam);
    setIsPopupOpen(true);
  };

  const closePopup = () => setIsPopupOpen(false);

  // Filter data based on selected status
  const filteredData = filterStatus ? data.filter(item => item.status === filterStatus) : data;

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleFilterChange = (event) => setFilterStatus(event.target.value);

  // Apply Filter Button resets to first page on filter change
  const applyFilter = () => setCurrentPage(1);

  return (
    <div className="table-container">
      {loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Filter Section */}
          <div className="filter-section">
            <label htmlFor="filter">Filter exams by:</label>
            <select id="filter" value={filterStatus} onChange={handleFilterChange}>
              <option value="">None</option>
              <option value="Pending">Pending</option>
              <option value="Graded">Graded</option>
            </select>
            <button onClick={applyFilter} className="apply-filter-btn">Apply filter</button>
          </div>

          {/* Exam Table */}
          <table>
            <thead>
              <tr>
                <th>Exam title</th>
                <th>Course/Subject</th>
                <th>Date of exam</th>
                <th>Class(es)</th>
                <th>Academic Year</th>
                <th>Exam Status</th>
                <th>Grade this exam</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td><span className="tag">{item.subject}</span></td>
                  <td>{item.date}</td>
                  <td>{item.classes.map(cls => <span key={cls} className="tag">{cls}</span>)}</td>
                  <td>{item.year}</td>
                  <td className={item.status === 'Graded' ? 'graded' : 'pending'}>{item.status}</td>
                  <td>
                    <button onClick={() => handleGradeClick(item)} className="edit-btn">✎</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Grading Popup */}
          {selectedExam && (
            <GradingPopup
              isOpen={isPopupOpen}
              onClose={closePopup}
              className={selectedExam.classes.join(', ')}
              examTitle={selectedExam.title}
              totalMarks={selectedExam.totalMarks}
            />
          )}

          {/* Pagination */}
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={filteredData.length}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default ExamClassList;
