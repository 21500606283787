import { Link, useLocation } from "react-router-dom";
import "../../css/admin_link.css";

const AdminLink = ({ icon_url, description, url_link, is_sidebar_link }) => {
  const location = useLocation();
  const isActive = location.pathname === url_link;
  const class_name = is_sidebar_link ? "left_sidebar_link" : "admin_link";

  const styles = {
    link_styles: {
      backgroundImage: icon_url,
      backgroundSize: "25px auto",
      backgroundPosition: is_sidebar_link ? "10px center" : "center 15px",
      backgroundRepeat: "no-repeat",
      backgroundColor: isActive ? "white" : "transparent", // Add white background when active
      color: isActive ? "black" : "inherit", // Adjust text color for visibility
    },
  };

  const { link_styles } = styles;

  return (
    <Link to={url_link} className={class_name} style={link_styles}>
      <span>{description}</span>
    </Link>
  );
};

export default AdminLink;
