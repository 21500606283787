function addInputDataToFormData(
  input,
  input_name,
  setterFunc, // state variable which stores form data in a particular component e.g const [formData, setFormData] = useState({...}), setterFunc would be setFormData
  formDataObjName,
  showConsoleLog // boolean
) {
  setterFunc((prevFormData) => ({
    ...prevFormData,
    [input_name]: input,
  }));
  if (showConsoleLog) console.log(formDataObjName); // for debugging purposes
}

function checkFileSize(file_size, maxFileSize = 5250000) {
  // check file size, default is 5MB
  return parseInt(file_size) < maxFileSize;
}

function checkFileType(file_name, accepted_file_types_array) {
  // check file type given a file name and an array of accepted file types e.g ['.pdf', '.png', '.jpg', '.svg']
  if (!file_name) {
    return;
  }
  const file_extension = "." + file_name.split(".").pop().toLowerCase();
  return accepted_file_types_array.includes(file_extension);
}

function zimbabweanPhoneNumberValidator(phoneNumber) {
  return /^(?:(?:\+|00)?263|0)?(7[1378])[0-9]{7}$/.test(`${phoneNumber}`);
  // 263773333333, +263773333333, 00263773333333, 0773333333, 773333333 are all valid
  // as well as XXX71, XX73, XX77 and XX78 numbers
}

export {
  addInputDataToFormData,
  checkFileSize,
  checkFileType,
  zimbabweanPhoneNumberValidator,
};
