import { useState } from "react";
import cancelbutton from "../../../svg/cancelbutton.svg";
import TeacherPortalAddPaper from "./teacherPortalAddPaper";

export default function TeacherPortalUploadResultsDisplay({ onclose }) {
   const [closeProfile, setCloseProfile] = useState("block");
   const [toggleDisplayAddPaper, setToggleDisplayAddPaper] = useState(false);
   // const [ overlay, setOverlay] = useState("none");
   const [examID, setExamID] = useState(null);

   const showAddPaper = (exam_id) => {
      setToggleDisplayAddPaper(true);
      setExamID(exam_id);
   }

   const closeStudentProfile = () => {
      setCloseProfile("none");
      onclose()
   }
   return <div style={{ display: `${closeProfile}` }}>
      <div className='teacher-portal-grading-results-main-container'>
         {toggleDisplayAddPaper == true ? <TeacherPortalAddPaper examID={examID} /> : ""}
         <div className='teacher-portal-student-profile'>
            <div>View final results for Statistics and enter teacher’s comment</div>
            <div><img onClick={closeStudentProfile} src={cancelbutton} /></div>
         </div>

         <div className='teacher-portal-grading-results'>
            <div>Class</div>
            <div>Form 3A</div>
            <div>Filter class</div>
         </div>


         <div className='teacher-portal-exam-grading-container'>
            <div>
               <form>
                  <table>
                     <thead>
                        <tr>
                           <th>Student Name</th>
                           <th>Final Mark (%)</th>
                           <th>Grade</th>
                           <th>Teacher’s comment</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>John Doe</td>
                           <td>40</td>
                           <td>A</td>
                           <td><input type="number" placeholder="Enter comment" /></td>
                        </tr>
                        <tr>
                           <td>Jane Smith</td>
                           <td>40</td>
                           <td>A</td>
                           <td><input type="number" placeholder="Enter comment" /></td>
                        </tr>
                        <tr>
                           <td>Emily Johnson</td>
                           <td>40</td>
                           <td>A</td>
                           <td><input type="number" placeholder="Enter comment" /></td>
                        </tr>
                        <tr>
                           <td>Michael Brown</td>
                           <td>40</td>
                           <td>A</td>
                           <td><input type="number" placeholder="Enter comment" /></td>
                        </tr>
                     </tbody>
                  </table>

                  <div className="teacher-portal-form-submit-container">
                     <button className="teacher-portal-form-submit">Submit</button>
                  </div>
               </form>
            </div>

         </div>
      </div>
   </div>
}