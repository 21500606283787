import { API_TOKEN, API_URL, apiHeaders } from "./apiConfigs";

// const API_TOKEN =
//   "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlcyI6W3siYXV0aG9yaXR5IjoiV1JJVEVfUFJJVklMRUdFIn0seyJhdXRob3JpdHkiOiJVUERBVEVfUFJJVklMRUdFIn0seyJhdXRob3JpdHkiOiJSRUFEX1BSSVZJTEVHRSJ9LHsiYXV0aG9yaXR5IjoiREVMRVRFX1BSSVZJTEVHRSJ9LHsiYXV0aG9yaXR5IjoiUk9MRV9BRE1JTiJ9XSwic3ViIjoiZWVyZXJlckBkLmd5dHl1IiwiaWF0IjoxNzMxNTAyMTA0LCJleHAiOjE3MzE1MjAxMDR9.cKx-z_B5fJHO97CD7i9RaGtZV0F_qggagVhriaZFehg";

const email = localStorage.getItem('email')

export const getDetailsAboutASchool = (schoolId) => { //WORKING
  return fetch(API_URL + `/api/v1/schools/id/${schoolId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${API_TOKEN}`, 
    },
  })
  .then((response) => { 
    if (!response.ok) {
      return []
    }
    return response.json();  
  })
  .then((data) => { 
    localStorage.setItem(`school${data.id}`, JSON.stringify(data));  
    return data;  
  })
  .catch((error) => {
    console.error("Error fetching school details:", error);
    return [];  
  });
};

export const getClasses = (schoolId) => {
  return fetch(API_URL + `/api/v1/schools/${schoolId}/classes`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${API_TOKEN}`, 
    },
  })
  .then((response) => { 
    console.log("Response status:", response.status);
    if (!response.ok) {
      return [];  
    }
    return response.json();  
  })
  .then((data) => { 
    console.log("Data:", data); 
    // localStorage.setItem(`school${schoolId}`, JSON.stringify(data));  
    return data;  
  })
  .catch((error) => {
    console.error(`Error fetching classes of school with id ${schoolId}`, error);
    return [];  
  });
};

export const getStudentDetails = (studentId) => { 
  return fetch(API_URL + `/api/v1/students/${studentId}/get`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${API_TOKEN}`, 
    },
  })
  .then((response) => { 
    if (!response.ok) {
      return []
    }
    return response.json();  
  })
  .then((data) => { 
    localStorage.setItem(`school${data.id}`, JSON.stringify(data));  
    return data;  
  })
  .catch((error) => {
    console.error("Error fetching school details:", error);
    return [];  
  });
};

// export const getStudentDetails = (studentId) => { 
//   return fetch(API_URL + `/api/v1/students/${studentId}/get`, {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${API_TOKEN}`, 
//     },
//   })
//   .then((response) => { 
//     if (!response.ok) {
//       return []
//     }
//     return response.json();  
//   })
//   .then((data) => { 
//     localStorage.setItem(`school${data.id}`, JSON.stringify(data));  
//     return data;  
//   })
//   .catch((error) => {
//     console.error("Error fetching school details:", error);
//     return [];  
//   });
// };

export const getParentStudents = (parentEmail) => { 
  return fetch(API_URL + `
/api/v1/parent/kids/${parentEmail}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${API_TOKEN}`, 
    },
  })
  .then((response) => { 
    if (!response.ok) {
      return []
    }
    return response.json();  
  })
  .then((data) => { 
    localStorage.setItem(`parent students: `, JSON.stringify(data));  
    return data;  
  })
  .catch((error) => {
    console.error("Error fetching school details:", error);
    return [];  
  });
};

export const getClassNameById = (schoolId, classId) => {
  return getClasses(schoolId)
    .then((classes) => {
      const classDetails = classes.find((classObj) => classObj.classId === classId);
      return classDetails ? classDetails.className : "";
    })
    .catch((error) => {
      console.error("Error fetching class name:", error);
      return "";
    });
};


export const getStudentReportCard = (
  studentId,
  schoolId,
  levelId,
  classId,
  termId,
  year
) => {
  return fetch(
    API_URL +
      `/api/v1/students/${studentId}/schools/${schoolId}/level/${levelId}/class/${classId}/term/${termId}/year/${year}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${API_TOKEN}`, 
      },
    }
  )
  .then((response) => { 
    if (!response.ok) { 
      return {}
    }
    return response.json(); 
  })
  .then((data) => { 
    localStorage.setItem(
      `student${studentId}SchoolReport`,
      JSON.stringify(data)
    ); 
    return data; 
  })
  .catch((error) => {
    console.error("Error fetching report card:", error);
    return {};  
  });
};


export const getSchoolTermDetails = (schoolId, termName) => {
  return fetch(
    API_URL + `/api/v1/schools/${schoolId}/term?name=${termName}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${API_TOKEN}`, 
      },
    }
  )
  .then((response) => { 
    if (!response.ok) {
      return {}
    }
    return response.json(); 
  }) 
  .catch((error) => {
    console.error("Error fetching school term details:", error);
    return {};  
  });
};

export const getTermId = (theSchoolId, theTermName) => {
  return getSchoolTermDetails(theSchoolId, theTermName)
    .then((termDetails) => { 
      console.log("Schoooool term details: ", termDetails)
      return termDetails ? termDetails.schoolTermId : "";
    })
    .catch((error) => {
      console.error("Error fetching subject name:", error);
      return "";
    });
};


export const getAllSchoolTermDetails = (schoolId) => {//WORKING
  return fetch(API_URL + `/api/v1/schools/${schoolId}/terms`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${API_TOKEN}`, 
    },
  })
  .then((response) => { ;
    if (!response.ok) {
      throw new Error("Failed to fetch terms");
    }
    return response.json();  
  })
  .then((data) => { 
    return data;  
  })
  .catch((error) => {
    console.error("Error fetching school term details:", error);
    return []; 
  });
};
 
export const getAllSubjects = async (schoolId) => { //WORKING
  return fetch(API_URL + `/api/v1/schools/${schoolId}/subjects`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${API_TOKEN}`, 
    },
  })
  .then((response) => {
    if (!response.ok) {
      return [];
    }
    return response.json();
  })
  .then((data) => {
    // console.log("Response when fetching all school subjects", data);
    return data;
  })
  .catch((error) => {
    // console.log("Failed to fetch subjects", error);
    return [];
  });
    // [
    //   {
    //     "subjectId": "8fa85f69-0717-3562-b3fc-2c963f76afa6",
    //     "subjectName": "Mathematics"
    //   },
    //   {
    //     "subjectId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "subjectName": "Physics"
    //   }
    // ]
};

export const getSubjectName = (theSchoolId, theSubjectId) => {//WORKING
  return getAllSubjects(theSchoolId)
    .then((subjects) => {
      const subject = subjects.find(({ subjectId }) => subjectId === theSubjectId);
      return subject ? subject.subjectName : "";
    })
    .catch((error) => {
      console.error("Error fetching subject name:", error);
      return "";
    });
};

