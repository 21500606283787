import React from 'react';
import '../../css/reportCard.css'

const ReportCard = () => {
  return (
    <div className="report-card-container">
      <div className="report-card-header">
        <h2>Tendai Maximoff's report card</h2>
        <p>Showing report card for: <span>Term 2, 2024</span></p>
      </div>

      <div className="report-card-body">
        <div className="school-header">
          <div className="school-logo">SCHOOL LOGO</div>
          <div className="school-details">
            <h1>JFH PRIMARY SCHOOL</h1>
            <div className="student-info">
              <p><strong>Name of student:</strong> Tendai Maximoff</p>
              <p><strong>Class/Grade:</strong> Grade 6A</p>
              <p><strong>Term:</strong> Term 2</p>
              <p><strong>School Year:</strong> 2024</p>
              <p><strong>Class Position:</strong> 3</p>
              <p><strong>Class Teacher:</strong> Shondo Moyo</p>
            </div>
          </div>
        </div>

        <div className="report-details">
          <table className="subject-table">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Score</th>
                <th>Grade</th>
                <th>Teacher's Comment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>English</td>
                <td>99%</td>
                <td>A</td>
                <td>Excellent</td>
              </tr>
              {/* Add other subjects similarly */}
            </tbody>
          </table>

          <div className="attendance">
            <h4>Attendance</h4>
            <p><strong>Total Days of School:</strong> 180</p>
            <p><strong>Days Attended:</strong> 179</p>
            <p><strong>Days Absent:</strong> 1</p>
          </div>

          <div className="grade-summary">
            <h4>Grade Percentage Average</h4>
            <p><strong>Score:</strong> 85.7%</p>
            <p><strong>Grade:</strong> A</p>
          </div>

          <div className="school-stamp">
            <p>SCHOOL STAMP</p>
            <p>20/08/2024</p>
          </div>
          
          <div className="principal-comment">
            <h4>Head/Principal's Comment</h4>
            <p>Lorem ipsum dolor sit amet. Incidun...</p>
          </div>

          <div className="grading-system">
            <h4>Grading System</h4>
            <ul>
              <li>A: 75% - 100%</li>
              <li>B: 65% - 74%</li>
              {/* Add other grades similarly */}
            </ul>
          </div>
        </div>
      </div>

      <div className="report-card-footer">
        <button className="print-button">Print</button>
        <button className="download-button">Download report card</button>
      </div>
    </div>
  );
};

export default ReportCard;
