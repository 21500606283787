// THIS IS A DISPLYA OF A NUMBER OF STUDENTS IN A CLASS
import '../../../css/teacherPortal.css';
import teacher_icon_eye from "../../../svg/eye.svg";
import cancelbutton from "../../../svg/cancelbutton.svg";
import { useEffect, useState } from "react";
import manage_parent_icon_left_arrow from "../../../svg/leftarrow.svg"
import manage_parent_icon_right_arrow from "../../../svg/rightarrow.svg"
import TeacherPortalViewProfile from './teacherPortalViewProfile';


export default function TeacherPortalStudentList({ classs_id, onclose }) {
    const [toggleDisplayStudentsList, setToggleDisplayStudentsList] = useState("block");
    const [toggleDisplayStudentProfile, setToggleDisplayStudentProfile] = useState(false);
    const [overlay, setOverlay] = useState("none");
    const [classID, setClassID] = useState(null);

    const showStudentsProfile = (class_id) => {
        setToggleDisplayStudentProfile(true);
        setClassID(class_id);
        setOverlay("block")
    }

    const hideStudentsProfile = () => {
        setToggleDisplayStudentProfile(false);
        setOverlay("none");
    }


    const closeStudentList = () => {
        setToggleDisplayStudentsList("none");
        onclose()
    }


    return <div style={{ display: `${toggleDisplayStudentsList}` }} className='teacher-portal-student-classlist-container'>

        <div style={{ display: `${overlay}` }} className="teacher-portal-student-list-container">
            {toggleDisplayStudentProfile == true ? <TeacherPortalViewProfile onclose={hideStudentsProfile} classs_id={classID} /> : ""}
        </div>

        <div className='teacher-portal-student-classlist'>
            <div className='teacher-portal-student-list'>
                <div>Students in Form 3A</div>
                <div><img onClick={closeStudentList} src={cancelbutton} /></div>
            </div>

            <div className="teacher-portal-table-container">
                <table className="teacher-portal-table">
                    <tr>
                        <th>Student Name</th>
                        <th>Courses/ Subjects Taken in your class</th>
                        <th>View profile</th>
                    </tr>
                    <tr>
                        <td>Data 1</td>
                        <td>Data 2</td>
                        <td onClick={showStudentsProfile}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                        <td>Data 4</td>
                        <td>Data 5</td>
                        <td onClick={showStudentsProfile}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                        <td>Data 7</td>
                        <td>Data 8</td>
                        <td onClick={showStudentsProfile}><img src={teacher_icon_eye} /></td>
                    </tr>
                    <tr>
                        <td>gggg</td>
                        <td>gggg</td>
                        <td onClick={showStudentsProfile}><img src={teacher_icon_eye} /></td>
                    </tr>
                </table>

            </div>

            <div className='teacher_portal_pagination_container'>
                <div className='teacher_portal_pagination_items_container'>
                    <div className=''><img src={manage_parent_icon_left_arrow} /></div>
                    <div className='teacher_portal_page_items'>1</div>
                    <div className='teacher_portal_page_items'>2</div>
                    <div className='teacher_portal_page_items'>3</div>
                    <div className='teacher_portal_page_items'>4</div>
                    <div className=''><img src={manage_parent_icon_right_arrow} /></div>
                </div>
            </div>

        </div>
    </div>
}