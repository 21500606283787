import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import "../../css/admin.css";
import { firstName, lastName } from "../../globalConfigs/userLoginInfo";
import '../../css/studentPortal.css';
import '../../css/teacherPortal.css';
import TeacherPortalSideBar from "../../components/layout/teacherPortalSideBar";
import teacher_icon_eye from "../../svg/eye.svg";
import { useState } from "react";
import TeacherPortalStudentList from "./teacherPortalComponents/teacherPortalStudentList";
import { alertTitleClasses } from "@mui/material";

const TeacherPortalTeacherManagement = () => {
  const school_logo_url = "https://picsum.photos/seed/picsum/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_profile_pic = "https://picsum.photos/id/28/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const [ toggleDisplayClassStudents, setToggleDisplayClassStudents] = useState(false);
  const [ overlay, setOverlay] = useState("inactive");
  const [classID, setClassID] = useState(null);
  
  const showclassStudents = (class_id) => {
    setToggleDisplayClassStudents(true);
    setClassID(class_id);
    setOverlay("active")
  }

  const hideclassStudents = () => {
    setToggleDisplayClassStudents(false);
    setOverlay("inactive");
  }
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <TeacherPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Classroom Management</h1>

            <div>Here are your classes</div>
            <div className="teacher-portal-tabs-container">
                <div className={`${overlay == 'active'? 'teacherportalblock' : 'teacherportalnone'} teacher-portal-student-list-container`}>
                    {toggleDisplayClassStudents == true ? <TeacherPortalStudentList onclose={hideclassStudents} classs_id={classID} /> : ""}
                </div>
                <div className="teacher-portal-tab">

                     <div className="teacher-portal-table-container">
                        <table className="teacher-portal-table">
                            <tr>
                                <th>Class</th>
                                <th>Courses/ Subjects Taught</th>
                                <th>Number of Students</th>
                                <th>View students</th>
                            </tr>
                            <tr>
                                <td>Row 1</td>
                                <td>Data 1</td>
                                <td>Data 2</td>
                                <td onClick={() => {showclassStudents(100)}}><img src={teacher_icon_eye} /></td>
                            </tr>
                            <tr>
                                <td>Row 2</td>
                                <td>Data 4</td>
                                <td>Data 5</td>
                                <td onClick={() => {showclassStudents(10600)}}><img src={teacher_icon_eye} /></td>
                            </tr>
                            <tr>
                                <td>Row 3</td>
                                <td>Data 7</td>
                                <td>Data 8</td>
                                <td onClick={() => {showclassStudents(10770)}}><img src={teacher_icon_eye} /></td>
                            </tr>
                            <tr>
                                <td>eee</td>
                                <td>gggg</td>
                                <td>gggg</td>
                                <td onClick={() => {showclassStudents(12200)}}><img src={teacher_icon_eye} /></td>
                            </tr>
                        </table>
      
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TeacherPortalTeacherManagement;