import { configureStore } from "@reduxjs/toolkit";
import enrolYourSchoolReducer from "../features/enrolYourSchool/enrolYourSchoolSlice";

const store = configureStore({
  reducer: {
    enrolYourSchool: enrolYourSchoolReducer,
  },
});

export default store;
