// src/utils/auth.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useAuthRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = !!localStorage.getItem("token"); // Modify token storage as needed
    if (!isLoggedIn) {
      navigate("/login"); // Redirect to login if not logged in
    }else{
        //navigate("/admin");
    }
  }, [navigate]);
};
