// controllers/approveSchoolRequest.js
import { API_URL, editSchoolStatus } from "../../globalConfigs/apiConfigs";
import { token } from "../../globalConfigs/userLoginInfo";
export const approveSchoolRequest = async (id, adminFirstname, adminLastName, adminEmail, senderID, feedback) => {
    const formData = new FormData();
    formData.append('schoolId', id);
    formData.append('status', 'ACTIVE');
    formData.append('adminFirstname',adminFirstname);
    formData.append('adminLastName', adminLastName);
    formData.append('adminEmail', adminEmail);
    formData.append('adminPassword', 'Password@123');
    formData.append('sender_id', senderID);
    formData.append('adminNote', feedback);

    const response = await fetch(API_URL + editSchoolStatus + id, {
        method: 'PUT',
        body: formData,
        headers: {
            'Authorization': 'Bearer ' + token,
        }
        
    });
    return response;
};
