import React, { useState } from 'react';
import "../../../../css/admin.css";
import  "../../../../css/teacherManagement.css";
import manage_parent_icon_cancelbutton from "../../../../svg/cancelbutton.svg";
import AdminParentManagementModalPopUp from './adminParentManagementModalPopUp';
import AdminParentManagementAddParentLoader from './adminParentManagementAddParentLoader';

export default function AdminParentManagementEditParentStatus({ onClose }){
    const [displaymodalpopup, setDisplayModalPopup] = useState(false)

    const handleModalPopUp = () => {
        setDisplayModalPopup(!displaymodalpopup)
       }
    return <div className="manage_teachers_addteacher_sub_container">
               <div>
                    {displaymodalpopup && <AdminParentManagementModalPopUp onClose={onClose} text={"Status being changed please wait ..."} icon={<AdminParentManagementAddParentLoader />} />}
                </div>
                <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_parent_icon_cancelbutton} />
                </div>
                <div className="manage_teachers_addteacher_editstatus_sub_container_title">
                    <h3>Edit Status For Ron Beasley</h3>
                </div>

                <div className="manage_teachers_addteacher_editstatus">
                   <div> Current Status: <b>Inactive</b></div>
                </div>
               
                <div>
                    <form>
                        <div className="manage_teachers_addteacher_editstatus_item_container">
                            <div>
                                <label>Active</label>
                                <input type="checkbox" name="phone-of-teacher" placeholder="" />
                            </div>

                            <div>
                                <label>Inactive</label>
                                <input type="checkbox" name="phone-of-teacher" placeholder="" />
                            </div>
                        </div>
                        <div className="manage_teachers_addteacher_form_submit_button_container">
                            <div  onClick={handleModalPopUp} className="manage_teachers_addteacher_form_submit_button">
                                Submit
                            </div>
                        </div>
                    </form>
                </div>

            </div>

}