import React, { useState } from 'react';
import "../../../../css/admin.css";
import  "../../../../css/parentManagement.css";
import default_user from "../../../../svg/user.svg"
import manage_parent_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import AdminParentManagementModalPopUp from "./adminParentManagementModalPopUp"
import AdminParentManagementAddParentLoader from "./adminParentManagementAddParentLoader"


export default function AdminParentManagementAddParentIndividual({ onClose }){
    const [displaymodalpopup, setDisplayModalPopup] = useState(false)

    const handleModalPopUp = () => {
        setDisplayModalPopup(!displaymodalpopup)
       }
    return <div className="manage_parent_view_edit_teacher_details_main_container">
               <div>
                    {displaymodalpopup && <AdminParentManagementModalPopUp onClose={onClose} text={"Parent being added please wait ..."} icon={<AdminParentManagementAddParentLoader />} />}
                </div>
                <div className="manage_parent_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_parent_icon_cancelbutton} />
                </div>
                <div className="manage_parent_addteacher_editstatus_sub_container_title">
                    <h3>Add a parent</h3>
                </div>


               
                <div className='manage_parent_view_edit_teacher_details_container'>
                    <form className='manage_parent_view_edit_teacher_details_form'>
                        <div className='manage_parent_view_edit_teacher_details_picture_section_container'>
                            <div className='manage_parent_view_edit_teacher_details_picture_section_sub_container'>
                                <div>
                                    <img src={default_user} />
                                </div>
                                <div>
                                    <label>Upload a profile picture</label>
                                    <input type='file' />
                                </div>
                            </div>
                            <div>
                                <label>First name of parent</label>
                                <input type='text' placeholder='Enter the parent’s first name' />
                            </div>
                        </div>

                        <div className='manage_parent_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Last name of parent</label>
                                <input type='text' placeholder='Enter the parent’s last name ' />
                            </div>
                            <div>
                                <label>Enter the parent’s phone number</label>
                                <input type='text' placeholder='Enter the phone number' />
                            </div>
                        </div>

                        <div className='manage_parent_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Enter the parent’s email</label>
                                <input type='text' placeholder='Enter the email ' />
                            </div>
                            <div>
                                <label>Address</label>
                                <textarea>
                                    123 Lowell Rd
                                    Greendale
                                    Harare
                                    Zimbabwe
                                </textarea>
                            </div>

                        </div>

                        <div className='manage_parent_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Enter the home city/town</label>
                                <input type='text' placeholder='Enter the home city/town' />
                            </div>
                            <div>
                                <label>Select the home country</label>
                                <select>
                                    <option>Select the home country</option>
                                    <option>option 1</option>
                                    <option>option 1</option>
                                </select>
                            </div>
                        </div>

                        <div className='manage_parent_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Enter email of parent’s associated student and add the student Enter student’s email</label>
                                <input type='text' placeholder='Enter student’s email' />
                            </div>
                        </div>
                       
                        <div className="manage_parent_deletez_teacher_form_submit_button_container">
                            <div  onClick={handleModalPopUp} className="manage_parent_view_edit_teacher_details_submit_button">
                                Submit
                            </div>
                        </div>
                    </form>
                </div>

            </div>

}