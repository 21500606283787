import React, { useState } from 'react';
import { Link } from "react-router-dom";
import AdminLeftSidebarNav from "../../../components/layout/AdminLeftSidebarNav";
import Footer from "../../../components/layout/Footer";
import SchoolCommonNav from "../../../components/layout/SchoolCommonNav";
import "../../../css/admin.css";
import  "../../../css/parentManagement.css";
import manage_parent_icon_question_mark from "../../../svg/Group 374.svg"
import manage_parent_icon_eye from "../../../svg/eye.svg"
import manage_parent_icon_edit from "../../../svg/Group 362.svg"
import manage_parent_icon_delete from "../../../svg/Group 368.svg"
import manage_parent_icon_left_arrow from "../../../svg/leftarrow.svg"
import manage_parent_icon_right_arrow from "../../../svg/rightarrow.svg"
import AdminParentmanegementTooltip from './adminParentManagementComponents/adminParentmanegementTooltip';
import AdminParentManagementAddParentIndividual from './adminParentManagementComponents/adminParentManagementAddParentIndividual';
import AdminParentManagementAddParentBulk from './adminParentManagementComponents/adminParentManagementAddParentBulk';
import AdminParentManagementViewParentDetails from './adminParentManagementComponents/adminParentManagementViewParentDetails';
import AdminParentManagementEditParentStatus from './adminParentManagementComponents/adminParentManagementEditParentStatus';
import AdminParentManagementDeleteParent from './adminParentManagementComponents/adminParentManagementDeleteParent';
import { firstName } from '../../../globalConfigs/userLoginInfo';
import { user_name, school_logo_url, school_name, school_address, are_there_new_notifications, user_profile_pic } from "../../../globalConfigs/userLoginInfo";








// THIS IS JUST FOR TEST DATA. IT WILL BE REMOVED AND REPLACED WITH ACTUAL API DATA
const initialParents = [
  {
    id: 1,
    name: 'John Doe',
    subject: 'Mathematics',
    email: 'john@example.com',
    status: 'Active',
    photo: '',
  },
  {
    id: 2,
    name: 'Jane Smith',
    subject: 'Science',
    email: 'jane@example.com',
    status: 'Inactive',
    photo: '',
  },
  {
    id: 3,
    name: 'Emily Johnson',
    subject: 'Literature',
    email: 'emily@example.com',
    status: 'Active',
    photo: '',
  },
];

const defaultPhoto = 'https://via.placeholder.com/50'; // Default photo icon URL

const AdminParentManagement = () => {
  const [parents, setParents] = useState(initialParents);
  const [searchTerm, setSearchTerm] = useState('');
  const [newParent, setNewParent] = useState({ name: '', subject: '', email: '', status: 'Active', photo: '' });
  const [displayAddParentIndividualForm, setDisplayAddParentIndividualForm] = useState(false);
  const [displayAddParentBulkUploadlForm, setDisplayAddParentBulkUploadlForm] = useState(false);
  const [displayEditParentStatusForm, setDisplayEditParentStatusForm] = useState(false);
  const [displayDeleteParentForm, setDisplayDeleteParentForm] = useState(false);
  const [displayViewParentDetailForm, setDisplayViewParentDetailForm] = useState(false);


  
//  displayViewTeacherDetailForm is too big, to meet the requirements of the design, this small piece 
// code will hide the able, when the form is active
let manage_parents_hide_show = {
  display: displayViewParentDetailForm ? 'none' : 'block',
}
   
  const displayParentIndividualFormToggle = () => {
    setDisplayAddParentIndividualForm(!displayAddParentIndividualForm)
  }
  const displayParentBulkFormToggle = () => {
    setDisplayAddParentBulkUploadlForm(!displayAddParentBulkUploadlForm)
  }

  const displayEditParentStatusFormToggle = () => {
    setDisplayEditParentStatusForm(!displayEditParentStatusForm)
  }

  const displayDeleteParentFormToggle = () => {
    setDisplayDeleteParentForm(!displayDeleteParentForm)
  }

  const displayViewParentDetailFormToggle = () => {
    setDisplayViewParentDetailForm(!displayViewParentDetailForm)
  }




  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddParent = () => {
    if (newParent.name && newParent.subject && newParent.email) {
      setParents([...parents, { id: parents.length + 1, ...newParent }]);
      setNewParent({ name: '', subject: '', email: '', status: 'Active', photo: '' });
    }
  };

  const toggleStatus = (id) => {
    setParents(parents.map(parent => 
      parent.id === id ? { ...parent, status: parent.status === 'Active' ? 'Inactive' : 'Active' } : parent
    ));
  };

  const handleDelete = (id) => {
    setParents(parents.filter(parent => parent.id !== id));
  };

  const filteredParents = parents.filter(parent =>
    parent.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


 
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        school_name={school_name}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <AdminLeftSidebarNav />
        <div className="content">

          <div className="about_school">
            <h1>Manage parents</h1>

            <div className="manage_teachers_add_teacher_heading_container">
              <div>Add Parents:</div>
              <Link onClick={displayParentIndividualFormToggle}>
                  <div className="manage_teachers_add_teacher_heading_item">Add Individually</div>
              </Link>
              <div  className="manage_teachers_add_teacher_heading_sub_container">
                <Link onClick={displayParentBulkFormToggle}>
                    <div className=" manage_teachers_add_teacher_heading_item">Bulk Add Via A csv File</div>
                </Link>
                <div>
                    <AdminParentmanegementTooltip text={"A CSV file is a simple text file used to store data in rows and columns. The columns required are First Name, Last Name, Phone Number, Date of birth, Nationality, Address, Email and Subject(s) (subjects separated by commas if a teacher teaches more than one subject)"} >
                      <Link to={"#"}>
                          <img src={manage_parent_icon_question_mark} alt="Question Mark" style={{ width: '20px', height: '22px' }}  />
                      </Link>
                    </AdminParentmanegementTooltip>
                </div>
              </div>
            </div>

            <div className="manage_teachers_addteacher_container">
                {displayAddParentIndividualForm == true ? <AdminParentManagementAddParentIndividual onClose={displayParentIndividualFormToggle} />: ""}
                {displayAddParentBulkUploadlForm == true ? <AdminParentManagementAddParentBulk onClose={displayParentBulkFormToggle} />: ""} 
                {displayViewParentDetailForm == true ? <AdminParentManagementViewParentDetails onClose={displayViewParentDetailFormToggle} />: ""}        
                {displayEditParentStatusForm == true ? <AdminParentManagementEditParentStatus onClose={displayEditParentStatusFormToggle} />: ""}
                {displayDeleteParentForm == true ? <AdminParentManagementDeleteParent onClose={displayDeleteParentFormToggle} />: ""} 
                
            </div>

            <div style={manage_parents_hide_show}  className="search_container">
              <form action="">
                <input
                  type="search"
                  name="search"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search for a parent by name or email"
                />
              </form>
            </div>

            <div className="manage_teachers_sort_by_filter_by_container"  >
                  <div className="manage_teachers_sort_by_filter_by_item">
                        <div className="manage_teachers_sort_by_filter_by_forms_title">Sort By</div>
                        <form className="manage_teachers_sort_by_filter_by_forms">
                              <select>
                                  <option>None</option>
                                  <option>Status-active</option>
                                  <option>Status-inactive</option>
                              </select>
                        </form>
                  </div>
              
            </div>

    
            <div style={manage_parents_hide_show} className="manage_teachers_table_container">
              <table>
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name of the parent</th>
                    <th>Parent's Email</th>
                    <th>Parent's Phone Number</th>
                    <th>Parent’s associated students</th>
                    <th>Status</th>
                    <th>View Details</th>
                    <th>Changer Status</th>
                    <th>Delete Parent</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredParents.map(parent => (
                    <tr key={parent.id}>
                      <td>
                        <img src={parent.photo || defaultPhoto} alt="Teacher" className="teacher-photo" />
                      </td>
                      <td>{parent.name}</td>
                      <td>{parent.email}</td>
                      <td>Parent's Phone</td>
                      <td className='manage_teachers_table_item_subjects'>
                        <span>{parent.subject}</span>
                      </td>
                      <td>{parent.status}</td>
                      <td onClick={displayViewParentDetailFormToggle}>
                          <img src={manage_parent_icon_eye} />
                      </td>
                      <td onClick={displayEditParentStatusFormToggle}>
                          <img src={manage_parent_icon_edit} />
                      </td>
                      <td onClick={displayDeleteParentFormToggle}>
                          <img src={manage_parent_icon_delete} />
                      </td>
                      {/* <td>
                        <button onClick={() => toggleStatus(teacher.id)}>
                          {teacher.status === 'Active' ? 'Deactivate' : 'Activate'}
                        </button>
                        <button onClick={() => handleDelete(teacher.id)}>Delete</button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <div>Showing: 6 per page</div> */}

            <div style={manage_parents_hide_show}  className='manage_teachers_pagination_container'>
                <div className='manage_teachers_pagination_items_container'>
                      <div className=''><img src={manage_parent_icon_left_arrow} /></div>
                      <div className='manage_teachers_page_items'>1</div>
                      <div className='manage_teachers_page_items'>2</div>
                      <div className='manage_teachers_page_items'>3</div>
                      <div className='manage_teachers_page_items'>4</div>
                      <div className=''><img src={manage_parent_icon_right_arrow} /></div>
                </div>
            </div>

            
          
          </div>
          
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default AdminParentManagement;
