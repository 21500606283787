import { useNavigate, Link } from "react-router-dom";
import React, { useState } from "react";
import { handleLogin } from "../../controllers/authenticationAPIs/loginAPI";
import CircularLoader from "../../globalConfigs/circularProgressIndicator";
import "../../css/login.css";
import Nav from "../../components/layout/Nav";
import Footer from "../../components/layout/Footer";
import { useAuthRedirect } from "./auth";
import { API_URL, getParentStudentsURL } from "../../globalConfigs/apiConfigs";

const LoginForm = () => {
  useAuthRedirect();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    const data = await handleLogin(email, password);
    setLoading(false); // Stop loading

    if (data.error) {
      setError(data.error);
    } else {
      setResponseData(data);
      setError(null); 

      localStorage.setItem("token", data.access_token);
      localStorage.setItem("firstName", data.firstname);
      localStorage.setItem("lastName", data.lastname);
      localStorage.setItem("userRole", data.roles);
      localStorage.setItem("userID", data.id);
      localStorage.setItem(
        "schoolID",
        data.schoolId ? data.schoolId.toString() : ""
      );
      localStorage.setItem("email", data.email);

      const students_info = {
        0: {
          studentData: {
            studentName: "Grace Langmore",
            school: "Kwenda Secondary School",
            studentClass: "Form 1 Green",
            studentLevel: "Form 1 Green",
            term: "Term 2",
            year: "2024",
            classPosition: "3",
            firstName: "Tendai",
            lastName: "Maximoff",
            results: {
              English: {
                score: "95%",
                grade: "A",
                teacherComment: "Excellent",
              },
              Mathematics: {
                score: "95%",
                grade: "A",
                teacherComment: "Superb",
              },
              "Physical Education and Arts": {
                score: "88%",
                grade: "A",
                teacherComment: "Excellent",
              },
              Shona: {
                score: "60%",
                grade: "C",
                teacherComment: "Good",
              },
              "Social Studies": {
                score: "72%",
                grade: "B",
                teacherComment: "Very Good",
              },
              Agriculture: {
                score: "99%",
                grade: "A",
                teacherComment: "Excellent",
              },
            },
            attendance: 179,
            totalDaysOfSchool: 180,
            headComment: "Student with a promising future",
          },
          schoolData: {
            schoolLogoUrl: "https://picsum.photos/seed/picsum/200/300",
            name: "Kwenda Secondary School",
            terms: ["Term 1", "Term 2", "Term 3"],
            gradingSystem: {
              A: {
                lowerLimit: "75%",
                upperLimit: "100%",
              },
              B: {
                lowerLimit: "74%",
                upperLimit: "65%",
              },
              C: {
                lowerLimit: "64%",
                upperLimit: "50%",
              },
              D: {
                lowerLimit: "49%",
                upperLimit: "40%",
              },
              E: {
                lowerLimit: "39%",
                upperLimit: "30%",
              },
              U: {
                lowerLimit: "Below 30%",
                upperLimit: "",
              },
            },
            dateResultsPublished: "20/08/2024",
          },
        },
        1: {
          studentData: {
            studentName: "Elon Maximoff",
            school: "Chekugona High Secondary School",
            studentClass: "Form 6Sciences",
            studentLevel: "Form 6Sciences",
            term: "Term 2",
            year: "2024",
            classPosition: "1",
            firstName: "Elon",
            lastName: "Maximoff",
            results: {
              "Pure Mathematics": {
                score: "95%",
                grade: "A",
                teacherComment: "Superb",
              },
              Chemistry: {
                score: "88%",
                grade: "A",
                teacherComment: "Excellent",
              },
              Physics: {
                score: "90%",
                grade: "A",
                teacherComment: "Excellent",
              },
            },
            attendance: 180,
            totalDaysOfSchool: 180,
            headComment: "An excellent student",
          },
          schoolData: {
            schoolLogoUrl: "https://picsum.photos/seed/picsum/200/300",
            name: "Chekugona High Secondary School",
            terms: ["Term 1", "Term 2", "Term 3"],
            gradingSystem: {
              A: {
                lowerLimit: "75%",
                upperLimit: "100%",
              },
              B: {
                lowerLimit: "74%",
                upperLimit: "65%",
              },
              C: {
                lowerLimit: "64%",
                upperLimit: "50%",
              },
              D: {
                lowerLimit: "49%",
                upperLimit: "40%",
              },
              E: {
                lowerLimit: "39%",
                upperLimit: "30%",
              },
              U: {
                lowerLimit: "Below 30%",
                upperLimit: "",
              },
            },
            dateResultsPublished: "12/08/2024",
          },
        },
      };
      localStorage.setItem('students_info', JSON.stringify(students_info)); 

      // Check roles and navigate accordingly
      if (data.roles.includes("ROLE_ADMIN")) {
        localStorage.setItem("role", "ADMIN");
        navigate("/admin");
      } else if (data.roles.includes("ROLE_SUPER_ADMIN")) {
        localStorage.setItem("role", "SUPERADMIN");
        navigate("/superadmin-dashboard");
      } else if (data.roles.includes("ROLE_TEACHER")) {
        localStorage.setItem("role", "TEACHER");
        navigate("/teacher-portal");
      } else if (data.roles.includes("ROLE_STUDENT")) {
        localStorage.setItem("role", "STUDENT");
        navigate("/student-portal");
      } else if (data.roles.includes("ROLE_USER")) {
        localStorage.setItem("role", "PARENT");


      const fetchAllParentStudents = async () => {
        console.log(API_URL + `/api/v1/parent/kids/${data?.email ? data?.email : ''}`)
        const response = await fetch(API_URL + `/api/v1/parent/kids/${data?.email ? data?.email : ''}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${data.access_token}`
          },
        });
          console.log("Reponse when fetching parent's students", response);
          if (response.ok) {
            localStorage.setItem("parentStudents", JSON.stringify(response));
          }
        }

        fetchAllParentStudents();

        navigate("/parent");
      }
    }
  };

  return (
    <section className="login-auth-main-container">
      <Nav />
      <h1>Brainstake Eschools Login</h1>
      <div className="login-auth-container">
        <h2>Please sign in to access your account</h2>
        <form onSubmit={onSubmit}>
          <div className="login-auth-form-group">
            <label>Email/Student Reg Number/Phone Number:</label>
            <input
              type="text"
              placeholder="Email/student Reg Number/Phone Number"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-auth-form-input"
            />
          </div>
          <div className="login-auth-form-group">
            <label>Password:</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-auth-form-input"
            />
          </div>
          <div className="login-auth-forgot-password">
            <div className="login-auth-checkbox">
              <input type="checkbox" />
              &nbsp;
              <div>Keep me signed in</div>
            </div>
            <div>
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="login-auth-submit-button"
              onClick={handleLogin}
            >
              Login
            </button>
          </div>

          <div className="login-auth-terms-and-conditions">
            By signing in, I agree to Brainstake <Link>Terms of service</Link>{" "}
            and <Link>Privacy policy</Link>
          </div>

          {/* {responseData && <div className="login-auth-success-message">Success: {JSON.stringify(responseData)}</div>}
                    {error && <div className="login-auth-error-message">Error: {error}</div>} */}
        </form>
      </div>

      {/* Show loading spinner with overlay */}
      {loading && <CircularLoader />}

      <Footer />
    </section>
  );
};

export default LoginForm;
