import AdminLeftSidebarNav from "../components/layout/AdminLeftSidebarNav";
import { useEffect, useState } from 'react'; 
import Footer from "../components/layout/Footer";
import SchoolCommonNav from "../components/layout/SchoolCommonNav";
import AdminLink from "../components/widgets/AdminLink";
import TotalStats from "../components/widgets/TotalStats";
import "../css/admin.css";
import { user_name, school_logo_url, school_name, school_address, are_there_new_notifications, user_profile_pic } from "../globalConfigs/userLoginInfo";
import { useAuthRedirect } from "./auth/auth";
import { schoolID, token } from "../globalConfigs/userLoginInfo";
import { API_URL } from "../globalConfigs/apiConfigs";
import { CircularProgress } from "@mui/material";

const Admin = () => {
  useAuthRedirect();

  const [schoolData, setSchoolData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch school data after authentication redirect
  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const response = await fetch(API_URL + '/api/v1/schools/id/' + schoolID, {
          
            headers: {
              'Authorization': 'Bearer ' + token,
            },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch school data");
        }
        const data = await response.json();
        setSchoolData(data);
        // Update local storage
        localStorage.setItem('logo', data.logo);
        localStorage.setItem('schoolName', data.name);
        localStorage.setItem('schoolAddress', data.streetName + " " + data.town + " " + data.district + " " + data.country);
        localStorage.setItem('schoolPK', data.id.toString());

        console.log(schoolID);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolData();
  }, []);



  return (
    <section className="admin admin_styles">

      <SchoolCommonNav
        school_logo_url={school_logo_url}
        school_name={school_name}
        user_name={user_name}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <AdminLeftSidebarNav />
        <div className="content">
          <div className="search_container">
            <form action="">
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search for functionality e.g add a teacher"
              />
            </form>
          </div>
          <div className="about_school">
            <h1>About School</h1>
            <h3>{school_name}</h3>
            <div className="info">
              <div className="school_address">{school_address || <CircularProgress/>}</div>
              <TotalStats
                total={schoolData?.numberOfStudents || <CircularProgress /> }
                description="Total students"
                icon_url={"url('/images/student_avatar_wt.png')"}
              />
              <TotalStats
                total={schoolData?.numberOfTeachers || <CircularProgress />}
                description="Total teachers"
                icon_url={"url('/images/teacher_avatar_wt.png')"}
                custom_bg_color="#84A9D1"
              />
              <TotalStats
                total="0%"
                description="School average"
                icon_url={"url('/images/line_graph_wt.png')"}
                custom_bg_color="#84A9D1"
              />
              <TotalStats custom_bg_color="#84A9D1" />
              <TotalStats custom_bg_color="#84A9D1" />
            </div>
          </div>
          <div className="manage_your_school">
            <h2>Manage your school</h2>
            <div className="info">
              <AdminLink
                url_link="/admin/statistics"
                icon_url={"url('/images/chart.png')"}
                description="Statistics"
              />
              <AdminLink
                url_link="/admin/student_management"
                icon_url={"url('/images/student_avatar.png')"}
                description="Student Management"
              />

              <AdminLink
                url_link="/admin/teacher-management"
                icon_url={"url('/images/teacher_avatar.png')"}
                description="Teacher Management"
              />

              <AdminLink
                url_link="/admin/class_management"
                icon_url={"url('/images/classroom.png')"}
                description="Class Management"
              />
              <AdminLink
                url_link="/admin/communications_centre"
                icon_url={"url('/images/comments_speech.png')"}
                description="Communications Centre"
              />
              <AdminLink
                url_link="/admin/school_events_and_announcements"
                icon_url={"url('/images/announcement.png')"}
                description="School events and Announcements"
              />
              <AdminLink
                url_link="/admin/profile_section"
                icon_url={"url('/images/profile.png')"}
                description="Profile Section"
              />
              <AdminLink
                url_link="/admin/documents_section"
                icon_url={"url('/images/document.png')"}
                description="Documents Section"
              />
              <AdminLink
                url_link="/admin/settings_section"
                icon_url={"url('/images/gear_solid.png')"}
                description="Settings Section"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Admin;
