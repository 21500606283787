import React from 'react';
import { useNavigate } from 'react-router-dom';
const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user session data from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('userRole');
        localStorage.removeItem('schoolID');
        localStorage.removeItem('logo');
        localStorage.removeItem('schoolName');
        localStorage.removeItem('schoolAddress');

        navigate('/login'); // Redirect to login
    };

    return (
        <button onClick={handleLogout}>
            Logout
        </button>
    );
};

export default LogoutButton;
