import "../../../css/cardStyle.css";
import { imageIcons } from "../../../components/imageComponents";
import { useEffect, useState } from "react";
import { getClassNameById } from "../../../globalConfigs/schoolService";

const CardWidget = ({
  keyVal,
  firstName,
  middleName,
  lastName,
  schoolId,
  classId,
  id,
  studentName,  
  setIsSchoolReportHidden,
  setSelectedStudent,
  selectedStudentId,
  setSelectedStudentId,
  selectedSchoolId,
  setSelectedSchoolId,
  selectedLevelId,
  setSelectedLevelId,
  selectedClassId,
  setSelectedClassId,
  selectedTermId,
  setSelectedTermId,
  selectedYear,
  setSelectedYear,
  setStudentData
}) => {
  
  const [className, setClassName] = useState("")

  // const student_data = {
  //   firstName: "string",
  //   lastName: "string",
  //   middleName: "string",
  //   email: "string",
  //   schoolId: 0,
  //   classId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   levelId: "4fa85f64-5717-4562-b3fc-2c963f66afa5",
  //   termId: "2fa85f64-5717-4562-b3fc-2c963f66afa9",
  //   year: {
  //     value: 0,
  //     leap: true,
  //   },
  //   subs: [
  //     {
  //       finalMark: 99,
  //       comment: "Excellent work",
  //       grade: "A",
  //       subjectId: "2fa85f69-0717-3562-b3fc-2c963f76afa4",
  //     },
  //     {
  //       finalMark: 72,
  //       comment: "Good work",
  //       grade: "B",
  //       subjectId: "8fa85f69-0717-3562-b3fc-2c963f76afa6",
  //     },
  //     {
  //       finalMark: 60,
  //       comment: "Good",
  //       grade: "C",
  //       subjectId: "4fa85f69-0717-3562-b3fc-2c963f76afa6",
  //     },
  //     {
  //       finalMark: 88,
  //       comment: "Excellent",
  //       grade: "string",
  //       subjectId: "9fa85f69-0717-3562-b3fc-2c963f76afa6",
  //     },
  //     {
  //       finalMark: 95,
  //       comment: "Superb",
  //       grade: "A",
  //       subjectId: "2fa85f69-0717-3562-b3fc-2c963f76afa6",
  //     },
  //     {
  //       finalMark: 95,
  //       comment: "Excellent work",
  //       grade: "A",
  //       subjectId: "8fa85f69-0717-3562-b3fc-2c963f76afa6",
  //     },
  //   ],
  // };

  useEffect(() => {
    getClassNameById(schoolId, classId).then((className) => {setClassName(className)})
    console.log("School id: ", schoolId)
    console.log("Class id: ", classId)
    console.log("Classsssssss", className)
  }, [])

  return (
    <div key={keyVal} data_val={keyVal} className="total_stats1">
      <div className="total_stats1__left">
        <span>
          <img src={imageIcons.graduate} alt="Graduate" className="widgetImg" />
          {studentName}
        </span>
        <span>
          <img
            src={imageIcons.school_premise}
            alt="Graduate"
            className="widgetImg"
          />
          {schoolId}
        </span>

        <span>
          <img
            src={imageIcons.classroom_icon}
            alt="Graduate"
            className="widgetImg"
          />
          {className}
        </span>

        <div className="subjectInfo">
          <div className="pHeaderIcon">
            <span>
              <img
                src={imageIcons.book_icon}
                alt="Graduate"
                className="widgetImg"
              />
              <strong className="pHeading">Current subjects</strong>
            </span>
          </div>
          <span>
            <div className="subjectList">Test Test test</div>
            <div className="subjectList">Test</div>
            <div className="subjectList">Test</div>
            <div className="subjectList">Test</div>
          </span>

          <div className="viewMoreSubjects">view more subjects</div>
        </div>

        <div className="reportCard">
          <span>
            <img
              src={imageIcons.report_card}
              alt="Graduate"
              className="widgetImg"
            />
            <button
              onClick={() => { 
                setSelectedStudent(keyVal);
                setIsSchoolReportHidden(false);
                // setSelectedStudentId(selectedStudentId);
                // setSelectedSchoolId(selectedSchoolId);
                // setSelectedLevelId(selectedLevelId);
                // setSelectedClassId(selectedClassId);
                // setSelectedTermId(selectedTermId);
                // setSelectedYear(selectedYear);
              }}
            >
              View Latest Report Card
            </button>
          </span>
        </div>
        <div className="reportCard1">
          <span>
            <img
              src={imageIcons.graduate_cap}
              alt="Graduate"
              className="widgetImg"
            />
            View all information
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardWidget;
