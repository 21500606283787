
import { API_URL, loginService } from '../../globalConfigs/apiConfigs';

export const handleLogin = async (email, password) => {
  const requestData = {
    email,
    password,
  };

  console.log(requestData);
  try {
    const response = await fetch(`${API_URL}${loginService}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data; // Return the data on success
  } catch (err) {
    return { error: err.message }; // Return the error on failure
  }
};
