import React, { useState, useEffect } from 'react';
import { Link, redirect } from "react-router-dom";
import AdminLeftSidebarNav from "../../../components/layout/AdminLeftSidebarNav";
import Footer from "../../../components/layout/Footer";
import SchoolCommonNav from "../../../components/layout/SchoolCommonNav";
import "../../../css/admin.css";
import "../../../css/teacherManagement.css";
import manage_teacher_icon_question_mark from "../../../svg/Group 374.svg"
import manage_teacher_icon_eye from "../../../svg/eye.svg"
import manage_teacher_icon_edit from "../../../svg/Group 362.svg"
import manage_teacher_icon_delete from "../../../svg/Group 368.svg"
import manage_teacher_icon_left_arrow from "../../../svg/leftarrow.svg"
import manage_teacher_icon_right_arrow from "../../../svg/rightarrow.svg"
import TeacherManagementTooltip from './teacherManagementComponents/teachermanegementTooltip';
import TeacherManagementAddTeacherIndividual from './teacherManagementComponents/teacherManagementAddTeacherIndividual';
import TeacherManagementAddTeacherBulk from './teacherManagementComponents/teacherManagementAddTeacherBulk';
import TeacherManagementEditTeacherStatus from './teacherManagementComponents/teacherManagementEditTeacherStatus';
import TeacherManagementDeleteTecher from './teacherManagementComponents/teacherManagementDeleteTecher';
import TeacherManagementViewTeacherDetails from './teacherManagementComponents/teacherManagementViewTeacherDetails';
import { fetchteachers } from '../../../globalConfigs/apiConfigs';
import { API_URL } from '../../../globalConfigs/apiConfigs';
import { firstName, token } from '../../../globalConfigs/userLoginInfo';
import TeacherManagementAddTeacherLoader from './teacherManagementComponents/teacherManagementAddTeacherLoader';
import { user_name, school_logo_url, school_name, school_address, are_there_new_notifications, user_profile_pic } from "../../../globalConfigs/userLoginInfo";
import { imageIcons } from '../../../components/imageComponents';
import AssignTeacher from './teacherManagementComponents/assignTeacher';



const defaultPhoto = '/images/avatar.png'; // Default photo icon URL

const TeacherManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const [newTeacher, setNewTeacher] = useState({ name: '', subject: '', email: '', status: 'Active', photo: '' });
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [displayAddTeacherIndividualForm, setDisplayAddTeacherIndividualForm] = useState(false);
  const [displayAddTeacherBulkUploadlForm, setDisplayAddTeacherBulkUploadlForm] = useState(false);
  const [displayEditTeacherStatusForm, setDisplayEditTeacherStatusForm] = useState(false);
  const [displayDeleteTeacherForm, setDisplayDeleteTeacherForm] = useState(false);
  const [displayViewTeacherDetailForm, setDisplayViewTeacherDetailForm] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const handleAssignClick = (teacher) => {
    setSelectedTeacher(teacher);
    setIsPopupOpen(true);
  };

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  // ---------------------------------------------------------------------------------------------
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState(teachers);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await fetch(`${API_URL}${fetchteachers}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const school_id = JSON.parse(localStorage.getItem("schoolID"));
        const filteredData = data.filter(item => item.schoolId === school_id);
        localStorage.setItem("all_teachers", JSON.stringify(filteredData));
        
        setTeachers(filteredData);
        setFilteredTeachers(filteredData);
      } catch (error) {
        setError('Failed to fetch teachers: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeachers();
  }, [API_URL, token]); // Add dependencies for effect

  if (loading) {
    return <div className='teacher-management-loading-teachers-list'><TeacherManagementAddTeacherLoader /></div>;
  }

  if (error) {
    return <div className='teacher-management-loading-teachers-list'>{error}</div>;
  }

      
  // --------------------------------------------------------------------------------------------------


  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = teachers.filter(teacher =>
      teacher.firstName.toLowerCase().includes(term) ||
      teacher.lastName.toLowerCase().includes(term) ||
      teacher.email.toLowerCase().includes(term)
    );

    setFilteredTeachers(filtered);
  };

  let looped_items = (arry) => {
    const commaSeperatedItems = arry.map(item =>
      `${item.name})`
    ).join(', ');
    return commaSeperatedItems
  }



  //  displayViewTeacherDetailForm is too big, to meet the requirements of the design, this small piece 
  // code will hide the able, when the form is active
  let manage_teachers_hide_show = {
    display: displayViewTeacherDetailForm ? 'none' : 'block',
  }

  const displayTeacherIndividualFormToggle = () => {
    setDisplayAddTeacherIndividualForm(!displayAddTeacherIndividualForm);
  }
  const displayTeacherBulkFormToggle = () => {
    setDisplayAddTeacherBulkUploadlForm(!displayAddTeacherBulkUploadlForm)
  }

  const displayEditTeacherStatusFormToggle = () => {
    setDisplayEditTeacherStatusForm(!displayEditTeacherStatusForm)
  }

  const displayDeleteTeacherFormToggle = () => {
    setDisplayDeleteTeacherForm(!displayDeleteTeacherForm)
  }

  const displayViewTeacherDetailFormToggle = (clicked_teacher_id) => {
    setDisplayViewTeacherDetailForm(!displayViewTeacherDetailForm);
    setSelectedTeacherId(clicked_teacher_id);
    // alert(clicked_teacher_id);
  }


  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        school_name={school_name}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <AdminLeftSidebarNav />
        <div className="content">

          <div className="about_school">
            <h1>Manage Your Teachers</h1>

            <div className="manage_teachers_add_teacher_heading_container">
              <div>Add teachers:</div>
              <Link onClick={displayTeacherIndividualFormToggle}>
                <div className="manage_teachers_add_teacher_heading_item">Add Individually</div>
              </Link>
              <div className="manage_teachers_add_teacher_heading_sub_container">
                <Link onClick={displayTeacherBulkFormToggle}>
                  <div className=" manage_teachers_add_teacher_heading_item">Bulk Add Via A csv File</div>
                </Link>
                <div>
                  <TeacherManagementTooltip text={"A CSV file is a simple text file used to store data in rows and columns. The columns required are First Name, Last Name, Phone Number, Date of birth, Nationality, Address, Email and Subject(s) (subjects separated by commas if a teacher teaches more than one subject)"} >
                    <Link to={"#"}>
                      <img src={manage_teacher_icon_question_mark} alt="Question Mark" style={{ width: '20px', height: '22px' }} />
                    </Link>
                  </TeacherManagementTooltip>
                </div>
              </div>
            </div>

            <div className="manage_teachers_addteacher_container">
              {displayAddTeacherIndividualForm == true ? <TeacherManagementAddTeacherIndividual onClose={displayTeacherIndividualFormToggle} /> : ""}
              {displayAddTeacherBulkUploadlForm == true ? <TeacherManagementAddTeacherBulk onClose={displayTeacherBulkFormToggle} /> : ""}
              {displayEditTeacherStatusForm == true ? <TeacherManagementEditTeacherStatus onClose={displayEditTeacherStatusFormToggle} /> : ""}
              {displayDeleteTeacherForm == true ? <TeacherManagementDeleteTecher onClose={displayDeleteTeacherFormToggle} /> : ""}
              {displayViewTeacherDetailForm == true ? <TeacherManagementViewTeacherDetails teacher_id={selectedTeacherId} onClose={displayViewTeacherDetailFormToggle} /> : ""}
            </div>

            <div style={manage_teachers_hide_show} className="search_container">
              <form action="">
                <input
                  type="search"
                  name="search"
                  id="search"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search for a teacher by name or email"
                />
              </form>
            </div>

            <div className="manage_teachers_sort_by_filter_by_container"  >
              <div className="manage_teachers_sort_by_filter_by_item">
                <div className="manage_teachers_sort_by_filter_by_forms_title">Sort By</div>
                <form className="manage_teachers_sort_by_filter_by_forms">
                  <select>
                    <option>None</option>
                    <option>Name</option>
                    <option>Email</option>
                    <option>Status</option>
                  </select>
                </form>
              </div>
              <div className="manage_teachers_sort_by_filter_by_item">
                <div className="manage_teachers_sort_by_filter_by_forms_title">Filter By</div>
                <form className="manage_teachers_sort_by_filter_by_forms">
                  <select>

                    <optgroup>
                      <option>Name</option>
                      <option>Status</option>
                      <option>Name</option>
                    </optgroup>
                  </select>
                </form>
              </div>
            </div>


            <div style={manage_teachers_hide_show} className="manage_teachers_table_container">
              <table>
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name of the teacher</th>
                    <th>Teacher's Email</th>
                    <th>Teacher's Phone Number</th>
                    <th>Subjects (Taught)</th>
                    <th>Status</th>
                    <th>Assign</th>
                    <th>View Details</th>
                    <th>Edit Status</th>
                    <th>Delete Teacher</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTeachers.map((teacher) => (
                    <tr key={teacher.id}>
                      <td>
                        <img src={teacher.photo || defaultPhoto} alt="Teacher" className="teacher-photo" />
                      </td>
                      <td>{teacher.firstName}</td>
                      <td>{teacher.email}</td>
                      <td>{teacher.phone}</td>
                      <td className='manage_teachers_table_item_subjects'>

                        {teacher.subjectsSpecialization.map((subject, index) => (
                          <span key={index}>
                            <strong>Name:</strong> {subject.name}<br />
                            <strong>Description:</strong> {subject.description}
                          </span>
                        ))}

                      </td>

                      <td>{teacher.status}</td>
                      <td onClick={() => handleAssignClick(teacher)}><img src={imageIcons.classroom_icon} /></td>
                      <td onClick={() => { displayViewTeacherDetailFormToggle(teacher.id) }}>
                        <img src={manage_teacher_icon_eye} />
                      </td>
                      <td onClick={displayEditTeacherStatusFormToggle}>
                        <img src={manage_teacher_icon_edit} />
                      </td>
                      <td onClick={displayDeleteTeacherFormToggle}>
                        <img src={manage_teacher_icon_delete} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <AssignTeacher
                isOpen={isPopupOpen}
                onClose={closePopup}
                selectedTeacher={selectedTeacher}  // Pass selected teacher info
              />
            </div>

            <div style={manage_teachers_hide_show} className='manage_teachers_pagination_container'>
              <div className='manage_teachers_pagination_items_container'>
                <div className=''><img src={manage_teacher_icon_left_arrow} /></div>
                <div className='manage_teachers_page_items'>1</div>
                <div className='manage_teachers_page_items'>2</div>
                <div className='manage_teachers_page_items'>3</div>
                <div className='manage_teachers_page_items'>4</div>
                <div className=''><img src={manage_teacher_icon_right_arrow} /></div>
              </div>
            </div>



          </div>

        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TeacherManagement;
