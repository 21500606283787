import React, { useState } from 'react';
import "../../../../css/admin.css";
import  "../../../../css/teacherManagement.css";
import manage_parent_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import AdminParentManagementModalPopUp from './adminParentManagementModalPopUp';
import AdminParentmanegementTooltip from './adminParentmanegementTooltip';
import AdminParentManagementAddParentLoader from './adminParentManagementAddParentLoader';
import default_user from "../../../../svg/user.svg"

export default function AdminParentManagementViewParentDetails({ onClose }){
    const [displaymodalpopup, setDisplayModalPopup] = useState(false)

    const handleModalPopUp = () => {
        setDisplayModalPopup(!displaymodalpopup)
       }
    return <div className="manage_teachers_view_edit_teacher_details_main_container">
               <div>
                    {displaymodalpopup && <AdminParentManagementModalPopUp onClose={onClose} text={"Parent being edited please wait ..."} icon={<AdminParentManagementAddParentLoader />} />}
                </div>
                <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_parent_icon_cancelbutton} />
                </div>
                <div className="manage_teachers_addteacher_editstatus_sub_container_title">
                    <h3>View/Edit details for Ron Beasley</h3>
                </div>


               
                <div className='manage_teachers_view_edit_teacher_details_container'>
                    <form className='manage_teachers_view_edit_teacher_details_form'>
                        <div className='manage_teachers_view_edit_teacher_details_picture_section_container'>
                            <div className='manage_teachers_view_edit_teacher_details_picture_section_sub_container'>
                                <div>
                                    <img src={default_user} />
                                </div>
                                <div>
                                    <label>Upload a profile picture</label>
                                    <input type='file' />
                                </div>
                            </div>
                            <div>
                                <label>First name of parent</label>
                                <input type='text' placeholder='Tonderai' value={"Tonderai"} />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Last name of parent</label>
                                <input type='text' placeholder='Last name of parent' value={"Mawanza"} />
                            </div>
                            <div>
                                <label>Enter the parent’s phone number</label>
                                <input type='text' placeholder='Enter the parent’s phone number' value={"+263  722993993"} />
                            </div>
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Enter the parent’s email</label>
                                <input type='text' placeholder='Enter the parent’s email' value={"tonde@gmail.com"} />
                            </div>
                            <div>
                                <label>Enter the parent’s home address</label>
                                <textarea>
                                    123 Lowell Rd
                                    Greendale
                                    Harare
                                    Zimbabwe
                                </textarea>
                            </div>
                          
                        </div>

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>Enter the home city/town</label>
                                <input type='text' placeholder='Enter the home city/town' value={"Bulawayo"} />
                            </div>

                            <div>
                                <label>Select the home country</label>
                                <input type='text' placeholder='Select the home country' value={"Zimbabwe"} />
                            </div>
                        </div>

                        

                        

                        <div className='manage_teachers_view_edit_teacher_details_form_item'>
                            <div>
                                <label>student and add the student</label>
                                <input type='text' placeholder='student and add the student' value={"lionelm@icloud.com"} />
                            </div>
                            
                        </div>

                        
                       
                        <div className="manage_teachers_deletez_teacher_form_submit_button_container">
                            <div  onClick={handleModalPopUp} className="manage_teachers_view_edit_teacher_details_submit_button">
                                Submit
                            </div>
                        </div>
                    </form>
                </div>

            </div>

}