import AdminLeftSidebarNav from "../../../components/layout/AdminLeftSidebarNav";
import Footer from "../../../components/layout/Footer";
import SchoolCommonNav from "../../../components/layout/SchoolCommonNav";
import "../../../css/admin.css";
import { firstName } from "../../../globalConfigs/userLoginInfo";
import SchoolInfo from "./infoComponents/infoCompnentsCards";
import { school_logo_url, school_name, user_profile_pic, are_there_new_notifications } from "../../../globalConfigs/userLoginInfo";

const ManageSchoolInfo = () => {
    

    return (
        <section className="admin admin_styles">


            <SchoolCommonNav
                school_logo_url={school_logo_url}
                school_name={school_name}
                user_name={firstName}
                user_profile_pic={user_profile_pic}
                are_there_new_notifications={are_there_new_notifications}
            />
            <div className="container">
                <AdminLeftSidebarNav />
                <div className="content">
                    <div className="about_school">
                        <h1>Manage School Information</h1>

                        <div className="info">
                            <SchoolInfo />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default ManageSchoolInfo;
