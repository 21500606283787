import { API_URL } from "../../globalConfigs/apiConfigs";
import { schoolID } from "../../globalConfigs/userLoginInfo";
import { token } from "../../globalConfigs/userLoginInfo";

export const submitTermData = async (data) => {
  try {
    const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/add/schoolTerm`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error('Failed to submit data');
    return await response.json();
  } catch (error) {
    throw error;
  }
};


export const submitLevelGrade = async (data) => {

  try {
    console.log(token)
    const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/add/levelGrade`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error('Failed to submit data');
    return await response.json();
  } catch (error) {
    throw error;
  }

}


export const submitClass = async (data) => {

  try {
    console.log(token)
    const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/add/schoolClasses`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error('Failed to submit data');
    return await response.json();
  } catch (error) {
    throw error;
  }

}



export const submitDept = async (data) => {

  try {
    const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/add/departments`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error('Failed to submit data');
    return await response.json();
  } catch (error) {
    throw error;
  }

}

export const submitSub = async (data) => {

  try {
    const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/add/subject`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error('Failed to submit data');
    return await response.json();
  } catch (error) {
    throw error;
  }

}
