import "../../css/admin.css";
import "../../css/comingSoon404.css"

const ComingSoon = ({ header, sidebar, body }) => {

  return (
    <>
      {/* This commonNav goes here */}
      {header}
      <div className="container">
        {/* LEFT BAR SIDE NAV GOES HERE */}
        {sidebar}
        <div className="content">
             {/* BODY GOES HERE */} 
             <div className="coming-soon-container">
                 <div className="coming-soon-sub-container">COOMING SOON</div>
                 <div>...this feature will be on the platform soon</div>
                 <div>In the meantime, let’s get you back</div>
             </div> 
        </div>
      </div>
    </>
  );
};


export default ComingSoon