import ParentPortalSideBar from "../../components/layout/parentPortalSideBar";
import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import "../../css/admin.css";
import "../../css/parent_portal.css";
import CardWidget from "./parentPortalUtils/cardWidget";
import StudentReport from "../../components/widgets/StudentReport.js";
import { useEffect, useState } from "react";
import { user_name } from "../../globalConfigs/userLoginInfo.js";
import { getDetailsAboutASchool } from "../../globalConfigs/schoolService.js";

const Admin = () => {
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const [isSchoolReportHidden, setIsSchoolReportHidden] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState("0");

  const current_year = new Date().getFullYear();
  const [selectedTerm, setSelectedTerm] = useState("Term 1");

  //IDs necessary for filtering school report
  const [selectedStudentId, setSelectedStudentId] = useState("0");
  const [selectedSchoolId, setSelectedSchoolId] = useState("1");
  const [selectedLevelId, setSelectedLevelId] = useState("0");
  const [selectedClassId, setSelectedClassId] = useState("0");
  const [selectedTermId, setSelectedTermId] = useState("0");
  const [selectedYear, setSelectedYear] = useState(current_year);
  const [studentData, setStudentData] = useState({})
 
  const students_info = {
    0: {
      studentData: {
        studentName: "Grace Langmore",
        school: "Kwenda Secondary School",
        studentClass: "Form 1 Green",
        studentLevel: "Form 1 Green",
        term: "Term 2",
        year: "2024",
        classPosition: "3",
        firstName: "Tendai",
        lastName: "Maximoff",
        results: {
          English: {
            score: "95%",
            grade: "A",
            teacherComment: "Excellent",
          },
          Mathematics: {
            score: "95%",
            grade: "A",
            teacherComment: "Superb",
          },
          "Physical Education and Arts": {
            score: "88%",
            grade: "A",
            teacherComment: "Excellent",
          },
          Shona: {
            score: "60%",
            grade: "C",
            teacherComment: "Good",
          },
          "Social Studies": {
            score: "72%",
            grade: "B",
            teacherComment: "Very Good",
          },
          Agriculture: {
            score: "99%",
            grade: "A",
            teacherComment: "Excellent",
          },
        },
        attendance: 179,
        totalDaysOfSchool: 180,
        headComment: "Student with a promising future",
      },
      schoolData: {
        schoolLogoUrl: "https://picsum.photos/seed/picsum/200/300",
        name: "Kwenda Secondary School",
        terms: ["Term 1", "Term 2", "Term 3"],
        gradingSystem: {
          A: {
            lowerLimit: "75%",
            upperLimit: "100%",
          },
          B: {
            lowerLimit: "74%",
            upperLimit: "65%",
          },
          C: {
            lowerLimit: "64%",
            upperLimit: "50%",
          },
          D: {
            lowerLimit: "49%",
            upperLimit: "40%",
          },
          E: {
            lowerLimit: "39%",
            upperLimit: "30%",
          },
          U: {
            lowerLimit: "Below 30%",
            upperLimit: "",
          },
        },
        dateResultsPublished: "20/08/2024",
      },
    },
    1: {
      studentData: {
        studentName: "Elon Maximoff",
        school: "Chekugona High Secondary School",
        studentClass: "Form 6Sciences",
        studentLevel: "Form 6Sciences",
        term: "Term 2",
        year: "2024",
        classPosition: "1",
        firstName: "Elon",
        lastName: "Maximoff",
        results: {
          "Pure Mathematics": {
            score: "95%",
            grade: "A",
            teacherComment: "Superb",
          },
          Chemistry: {
            score: "88%",
            grade: "A",
            teacherComment: "Excellent",
          },
          Physics: {
            score: "90%",
            grade: "A",
            teacherComment: "Excellent",
          },
        },
        attendance: 180,
        totalDaysOfSchool: 180,
        headComment: "An excellent student",
      },
      schoolData: {
        schoolLogoUrl: "https://picsum.photos/seed/picsum/200/300",
        name: "Chekugona High Secondary School",
        terms: ["Term 1", "Term 2", "Term 3"],
        gradingSystem: {
          A: {
            lowerLimit: "75%",
            upperLimit: "100%",
          },
          B: {
            lowerLimit: "74%",
            upperLimit: "65%",
          },
          C: {
            lowerLimit: "64%",
            upperLimit: "50%",
          },
          D: {
            lowerLimit: "49%",
            upperLimit: "40%",
          },
          E: {
            lowerLimit: "39%",
            upperLimit: "30%",
          },
          U: {
            lowerLimit: "Below 30%",
            upperLimit: "",
          },
        },
        dateResultsPublished: "12/08/2024",
      },
    },
  };

  // const masitudenti = [
  //   {
  //     id: 0,
  //     firstName: "Grace",
  //     lastName: "Langmore",
  //     middleName: "L",
  //     bsNumber: "string",
  //     dob: "2024-11-10T15:19:49.875Z",
  //     email: "string",
  //     phonenumber: "string",
  //     parentPhone: "string",
  //     address: "string",
  //     district: "string",
  //     province: "string",
  //     parentEmail: "string",
  //     country: "string",
  //     bloodGroup: "string",
  //     schoolId: 1,
  //     classId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     userId: 0,
  //     status: "PENDING",
  //     createdAt: "2024-11-10T15:19:49.875Z",
  //     createdBy: "string",
  //     updatedAt: "2024-11-10T15:19:49.875Z",
  //     updatedBy: "string",
  //     levelId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     termId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     sender_id: "string",
  //     regParent: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   },
  //   {
  //     id: 1,
  //     firstName: "Paul",
  //     lastName: "",
  //     middleName: "Apo",
  //     bsNumber: "string",
  //     dob: "2024-11-10T15:19:49.875Z",
  //     email: "string",
  //     phonenumber: "string",
  //     parentPhone: "string",
  //     address: "string",
  //     district: "string",
  //     province: "string",
  //     parentEmail: "string",
  //     country: "string",
  //     bloodGroup: "string",
  //     schoolId: 0,
  //     classId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     userId: 0,
  //     status: "PENDING",
  //     createdAt: "2024-11-10T15:19:49.875Z",
  //     createdBy: "string",
  //     updatedAt: "2024-11-10T15:19:49.875Z",
  //     updatedBy: "string",
  //     levelId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     termId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     sender_id: "string",
  //     regParent: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   },
  //   {
  //     id: 2,
  //     firstName: "Nyambi",
  //     lastName: "",
  //     middleName: "Nyambi",
  //     bsNumber: "string",
  //     dob: "2024-11-10T15:19:49.875Z",
  //     email: "string",
  //     phonenumber: "string",
  //     parentPhone: "string",
  //     address: "string",
  //     district: "string",
  //     province: "string",
  //     parentEmail: "string",
  //     country: "string",
  //     bloodGroup: "string",
  //     schoolId: 2,
  //     classId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     userId: 0,
  //     status: "PENDING",
  //     createdAt: "2024-11-10T15:19:49.875Z",
  //     createdBy: "string",
  //     updatedAt: "2024-11-10T15:19:49.875Z",
  //     updatedBy: "string",
  //     levelId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     termId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     sender_id: "string",
  //     regParent: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //   },
  // ];
 

  // const masitudenti = [
  //   {
  //     "id": 0,
  //     "firstName": "Grace",
  //     "lastName": "Langmore",
  //     "middleName": "L",
  //     "bsNumber": "string",
  //     "dob": "2024-11-10T15:19:49.875Z",
  //     "email": "string",
  //     "phonenumber": "string",
  //     "parentPhone": "string",
  //     "address": "string",
  //     "district": "string",
  //     "province": "string",
  //     "parentEmail": "string",
  //     "country": "string",
  //     "bloodGroup": "string",
  //     "schoolId": 1,
  //     "classId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "userId": 0,
  //     "status": "PENDING",
  //     "createdAt": "2024-11-10T15:19:49.875Z",
  //     "createdBy": "string",
  //     "updatedAt": "2024-11-10T15:19:49.875Z",
  //     "updatedBy": "string",
  //     "levelId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "termId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "sender_id": "string",
  //     "regParent": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  //   },
  //   {
  //     "id": 1,
  //     "firstName": "Paul",
  //     "lastName": "",
  //     "middleName": "Apo",
  //     "bsNumber": "string",
  //     "dob": "2024-11-10T15:19:49.875Z",
  //     "email": "string",
  //     "phonenumber": "string",
  //     "parentPhone": "string",
  //     "address": "string",
  //     "district": "string",
  //     "province": "string",
  //     "parentEmail": "string",
  //     "country": "string",
  //     "bloodGroup": "string",
  //     "schoolId": 0,
  //     "classId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "userId": 0,
  //     "status": "PENDING",
  //     "createdAt": "2024-11-10T15:19:49.875Z",
  //     "createdBy": "string",
  //     "updatedAt": "2024-11-10T15:19:49.875Z",
  //     "updatedBy": "string",
  //     "levelId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "termId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "sender_id": "string",
  //     "regParent": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  //   },
  //   {
  //     "id": 2,
  //     "firstName": "Nyambi",
  //     "lastName": "",
  //     "middleName": "Nyambi",
  //     "bsNumber": "string",
  //     "dob": "2024-11-10T15:19:49.875Z",
  //     "email": "string",
  //     "phonenumber": "string",
  //     "parentPhone": "string",
  //     "address": "string",
  //     "district": "string",
  //     "province": "string",
  //     "parentEmail": "string",
  //     "country": "string",
  //     "bloodGroup": "string",
  //     "schoolId": 2,
  //     "classId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "userId": 0,
  //     "status": "PENDING",
  //     "createdAt": "2024-11-10T15:19:49.875Z",
  //     "createdBy": "string",
  //     "updatedAt": "2024-11-10T15:19:49.875Z",
  //     "updatedBy": "string",
  //     "levelId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "termId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "sender_id": "string",
  //     "regParent": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  //   }
  // ]

  const student_data = [
    {
      id: 0,
      firstName: "Grace",
      lastName: "Langmore",
      middleName: "L",
      email: "grace@gmail.com",
      schoolId: 2,
      classId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      levelId: "4fa85f64-5717-4562-b3fc-2c963f66afa5",
      termId: "2fa85f64-5717-4562-b3fc-2c963f66afa9",
      year: {
        value: 0,
        leap: true,
      },
      subs: [
        {
          finalMark: 99,
          comment: "Excellent work",
          grade: "A",
          subjectId: "3fa85f69-0717-3562-b3fc-2c963f76afa4",
        },
        {
          finalMark: 72,
          comment: "Good work",
          grade: "B",
          subjectId: "4fa85f69-0717-3562-b3fc-2c963f76afa6",
        },
        {
          finalMark: 60,
          comment: "Good",
          grade: "C",
          subjectId: "5fa85f69-0717-3562-b3fc-2c963f76afa6",
        },
        {
          finalMark: 88,
          comment: "Excellent",
          grade: "A",
          subjectId: "6fa85f69-0717-3562-b3fc-2c963f76afa6",
        },
        {
          finalMark: 95,
          comment: "Superb",
          grade: "A",
          subjectId: "7fa85f69-0717-3562-b3fc-2c963f76afa6",
        },
        {
          finalMark: 95,
          comment: "Excellent work",
          grade: "A",
          subjectId: "lfa85f69-0717-3562-b3fc-2c963f76afa6",
        },
      ],
    },
    {
      id: 1,
      firstName: "Paul",
      middleName: "", 
      lastName: "Apo",
      email: "paul@icloud.com",
      schoolId: 3,
      classId: "pfa85f64-5717-4562-b3fc-2c963f66afa6",
      levelId: "lfa85f64-5717-4562-b3fc-2c963f66afa5",
      termId: "2fa85f64-5717-4562-b3fc-2c963f66afa9",
      year: {
        value: 0,
        leap: true,
      },
      subs: [
        {
          finalMark: 100,
          comment: "Excellent work",
          grade: "A",
          subjectId: "2fa85f69-0717-3562-b3fc-2c963f76afa4",
        },
        {
          finalMark: 74,
          comment: "Good work",
          grade: "B",
          subjectId: "8fa85f69-0717-3562-b3fc-2c963f76afa6",
        },
        {
          finalMark: 60,
          comment: "Good",
          grade: "C",
          subjectId: "4fa85f69s-0717-3562-b3fc-2c963f76afa6",
        },
        {
          finalMark: 90,
          comment: "Excellent",
          grade: "A",
          subjectId: "4sfa85f69-0717-3562-b3fc-2c963f76afa6",
        }
      ]
    },
    {
      id: 2,
      firstName: "Nyambi",
      lastName: "Nyambi",
      middleName: "",
      email: "goodfight@gmail.com",
      schoolId: 1,
      classId: "0e578360-572f-42b1-b05d-17d06c4514be",
      levelId: "4fa85f64-5717-4562-b3fc-2c963f66afa5",
      termId: "2fa85f64-5717-4562-b3fc-2c963f66afa9",
      year: {
        value: 0,
        leap: true,
      },
      subs: [ 
        {
          finalMark: 88,
          comment: "Excellent",
          grade: "A",
          subjectId: "ba804c31-6a55-46fd-b431-97ace5461977"
        },
        {
          finalMark: 95,
          comment: "Superb",
          grade: "A",
          subjectId: "1ea02478-9159-46d2-bd42-208850e581ce"
        },
        {
          finalMark: 95,
          comment: "Excellent work",
          grade: "A",
          subjectId: "326baad8-8d02-460f-9267-e52b216c97b2"
        },
      ],
    },
  ];

  // const student_data =  getStudentReportCard().then((response) => {
  //   setStudentData()
  // })

  // console.log(student_data[selectedStudent])
 
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={"/images/logo_container.svg"}
        school_name={""}
        user_name={
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("lastName")
        }
        user_profile_pic={""}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <ParentPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Parent Portal</h1> 
            <div className="info">
              {
                 student_data.map(({firstName, middleName, lastName, schoolId, classId, id, email}) => (
                  <CardWidget
                    keyVal={id}
                    setIsSchoolReportHidden={setIsSchoolReportHidden}
                    studentName={firstName + " " + middleName + " " + lastName}
                    // school={getDetailsAboutASchool(schoolId).name}
                    schoolId={schoolId}
                    classId={classId}
                    setSelectedStudent={setSelectedStudent}
                    studentData={student_data[selectedStudent]}
                  />
                )) 
              } 
            </div>
          </div>
        </div>
      </div>
      <div
        className={`school_report_container ${
          !isSchoolReportHidden && "displayed_flex"
        }`}
      >
        <StudentReport
          isSchoolReportAPopup={true}
          setIsSchoolReportHidden={setIsSchoolReportHidden}
          studentData={student_data[selectedStudent]}
          // schoolData={student_data[selectedStudent].schoolData}
          selectedTerm={selectedTerm}
          setSelectedTerm={setSelectedTerm}
          setSelectedStudent={setSelectedStudent}
          selectedStudentId={selectedSchoolId}
          setSelectedStudentId={setSelectedStudentId}
          selectedSchoolId={selectedSchoolId}
          setSelectedSchoolId={setSelectedSchoolId}
          selectedLevelId={selectedLevelId}
          setSelectedLevelId={setSelectedLevelId}
          selectedClassId={selectedClassId}
          setSelectedClassId={setSelectedClassId}
          selectedTermId={selectedTermId}
          setSelectedTermId={setSelectedTermId}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />
      </div>
      <Footer />
    </section>
  );
};

export default Admin;
