import React from 'react';
import '../../../css/mainDashboard.css'; 

const SchoolEnrolmentDetails = ({ school, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>×</button>
        <h2>Enrolment Details</h2>
        <div className="details-grid">
          <div>
            <strong>Name of School:</strong> <br/> {school.name}
          </div>
          <div>
            <strong>Name of Town:</strong><br/> {school.town}
          </div>
          <div>
            <strong>Name of Province:</strong><br/> {school.province}
          </div>
          <div>
            <strong>Name of Country:</strong><br/> {school.country}
          </div>
          <div>
            <strong>Name of School Head:</strong><br/> {school.headFirstname} {school.headLastname}
          </div>
          <div>
            <strong>Head’s Phone Number:</strong><br/> {school.headPhonenumber}
          </div>
          <div>
            <strong>Head's Email:</strong><br/> {school.headEmail}
          </div>
          <div>
            <strong>Name of Admin:</strong><br/> {school.admin}
          </div>
          <div>
            <strong>Admin’s Phone Number:</strong><br/> {school.adminPhonenumber}
          </div>
          <div>
            <strong>Admin’s Email:</strong><br/> {school.adminEmail}
          </div>
          <div>
            <strong>Number of Students:</strong><br/> {school.numberOfStudents}
          </div>
          <div>
            <strong>Number of Teachers:</strong><br/> {school.numberOfTeachers}
          </div>
          <div>
            <strong>School Phone Number:</strong><br/> {school.schoolContact}
          </div>
          <div>
            <strong>School Address:</strong><br/>
             {school.town} {school.district} {school.province}
          </div>
          <div>
            <strong>Superadmin’s comment:</strong> Lorem ipsum dolo sit amet. Regina incolam dat.
          </div>
          <div>
            <strong>Submission timestamp:</strong><br/>
            {school.createdAt}

          </div>
          <div>
            <strong>Last updated timestamp:</strong><br/>
            {school.updatedAt}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolEnrolmentDetails;
