import manage_teacher_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import TeacherManagementModalPopUp from "./teacherManagementModalPopUp"
import TeacherManagementAddTeacherLoader from "./teacherManagementAddTeacherLoader"
import { useEffect, useState } from "react"; 
import { API_URL, createIndividualTeacher } from "../../../../globalConfigs/apiConfigs";
import { token } from "../../../../globalConfigs/userLoginInfo";


export default function TeacherManagementAddTeacherIndividual({ onClose }){
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
    const [displayErrorModal, setDisplayErrorModal] = useState(false);
    const [displayloader, setDisplayLoader] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",

        phone: "",
        alternatePhoneNumber: "",
        birthday: "",
        address: "",
        nationality: "",
        yearsOfExperience: 0,
        hireDate: "",
        gender: "",
        employmentType: "",
        province: "",
        country: "",
        district: "",
        emergencyContactName: "",
        emergencyContactRelationship: "",
        emergencyContactPhoneNumber: "",
        photo: null, 
    });


    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    console.log(formData);

 

    const handleSubmit = async (e) => {
        handleModalPopUp();
        e.preventDefault();
        setDisplaySuccessModal(false);
        setDisplayLoader(true);


        const teacher_formData = new FormData();

            for (const key in formData) {
                teacher_formData.append(key, formData[key]);
            }

            for (let [key, value] of teacher_formData.entries()) {
                console.log(`${key}: ${value}`);
            }
        
            fetch(API_URL + createIndividualTeacher, {
            method: 'POST',
            headers: {
                        'Authorization': "Bearer " + token,
                    },
            body: teacher_formData,
            })
            .then((response) => {
                setResponseStatus(response.status)
            })
            .then((data) => {
            })
            .catch((error) => {
                setDisplaySuccessModal(false);
                setDisplayErrorModal(true);
                console.log(error);
            });
        };



        useEffect(
            () => {
                if (responseStatus == 201) {
                    console.log('Resource created successfully:');
                    setDisplaySuccessModal(true);
                    // alert(displaySuccessModal);
                    setDisplayLoader(false);
                    setDisplayErrorModal(false);
                }else if (responseStatus == 500) {
                    setDisplayLoader(false);
                    setDisplayErrorModal(true);
                }
            }, [responseStatus]
        )
        

 

        const handleModalPopUp = () => {
            setDisplayLoader(true);
        }

    return <div className="manage_teachers_addteacher_sub_container">
                <div>
                    {displayloader && <TeacherManagementModalPopUp onClose={onClose} text={"Teacher being added please wait ..."} icon={<TeacherManagementAddTeacherLoader />} />}
                </div>

                <div>
                    {displaySuccessModal && <TeacherManagementModalPopUp onClose={onClose} text={"Teacher added Success"} icon={<img src={"/images/success_tick.png"} />} />}
                </div>

                <div>
                    {displayErrorModal && <TeacherManagementModalPopUp onClose={onClose} text={"Failed To Add Teacher! Try Again."} icon={<img src={"/images/failure_exclamation.png"} />} />}
                </div>

                

                <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_teacher_icon_cancelbutton} />
                </div>
                <div className="manage_teachers_addteacher_sub_container_title">Add Teacher</div>
                <div>

                        {/* =================================================================================== */}
                        <form onSubmit={handleSubmit}>
                    
                                {/* ------------------------------------------------------------------------------------- */}
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>First Name</label>
                                    <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} required />
                                </div>
                                <div>
                                    <label>Last Name</label>
                                    <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Email</label>
                                    <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
                                </div>
                                <div>
                                    <label>Phone</label>
                                    <input type="text" name="phone" placeholder="Phone" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Alternate Phone Number</label>
                                    <input type="text" name="alternatePhoneNumber" placeholder="Alternate Phone Number" onChange={handleChange} />
                                </div>
                                <div>
                                    <label>Birthday</label>
                                    <input type="date" name="birthday" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Address</label>
                                    <input type="text" name="address" placeholder="Address" onChange={handleChange} />
                                </div>
                                <div>
                                    <label>Nationality</label>
                                    <input type="text" name="nationality" placeholder="Nationality" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Years of Experience</label>
                                    <input type="number" name="yearsOfExperience" placeholder="Years of Experience" onChange={handleChange} />
                                </div>
                                <div>
                                    <label>Hire Date</label>
                                    <input type="date" name="hireDate" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Gender</label>
                                    <select name="gender" onChange={handleChange}>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Employment Type</label>
                                    <select name="employmentType" onChange={handleChange}>
                                        <option value="FULL_TIME">Full Time</option>
                                        <option value="PART_TIME">Part Time</option>
                                    </select>
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Province</label>
                                    <input type="text" name="province" placeholder="Province" onChange={handleChange} />
                                </div>
                                <div>
                                    <label>District</label>
                                    <input type="text" name="district" placeholder="District" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Emergency Contact Name</label>
                                    <input type="text" name="emergencyContactName" placeholder="Emergency Contact Name" onChange={handleChange} />
                                </div>
                                <div>
                                    <label>Emergency Contact Relationship</label>
                                    <input type="text" name="emergencyContactRelationship" placeholder="Emergency Contact Relationship" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="manage_teachers_addteacher_form_item">
                                <div>
                                    <label>Emergency Contact Phone Number</label>
                                    <input type="text" name="emergencyContactPhoneNumber" placeholder="Emergency Contact Phone Number" onChange={handleChange} />
                                </div>
                            </div>
                            {/* ------------------------------------------------------------------------------------- */}
                            <div className="manage_teachers_addteacher_form_submit_button_container">
                                <button  type="submit"  className="manage_teachers_addteacher_form_submit_button">
                                    Submit
                                </button>
                            </div> 
                        </form>
                        {/* =================================================================================== */}

                        


                </div>
            </div>

}
