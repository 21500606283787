import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { handleLogin } from '../../controllers/authenticationAPIs/loginAPI';
// import '../../css/tempLoginCSS.css';
import '../../css/login.css';
import Nav from '../../components/layout/Nav';
import Footer from '../../components/layout/Footer';

const CreateNewPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = await handleLogin(email, password);

        if (data.error) {
            setError(data.error);
        } else {
            setResponseData(data);
            setError(null);

            // Check roles and navigate accordingly
            if (data.roles.includes("ROLE_ADMIN")) {
                navigate('/admin'); // Navigate to School Admin route
            } else if (data.roles.includes("ROLE_SUPERADMIN")) {
                navigate('/superadmin-dashboard'); // Navigate to SuperAdmin route
            } else if (data.roles.includes("ROLE_TEACHER")) {
                navigate('/teacher-portal'); // Fallback route if no specific role
            } else if (data.roles.includes("ROLE_STUDENT")) {
                navigate('/student-portal')
            } else if (data.roles.includes("ROLE_USER")) {
                navigate('/parent-portal')
            }


        }
    };

    return (
        <section className="login-auth-main-container">
            <Nav />
            <h1>Reset your password</h1>
            <div className="login-auth-container"> {/* Add a container for styling */}
        
                <h2>Create a new password</h2>
                <form onSubmit={onSubmit}>
                    <div className="login-auth-form-group">
                        <label>New password:</label>
                        <input
                            type="email"
                            placeholder='Enter new password'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="login-auth-form-input" // Apply styling to input
                        />
                    </div>
                    <div className="login-auth-form-group">
                        <label>Confirm new password:</label>
                        <input
                            type="password"
                            placeholder='Confirm new password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-auth-form-input" // Apply styling to input
                        />
                    </div>
                    
                    <div>
                        <button type="submit" className="login-auth-submit-button">Reset your password</button>
                    </div>

                    {responseData && <div className="login-auth-success-message">Success: {JSON.stringify(responseData)}</div>}
                    {error && <div className="login-auth-error-message">Error: {error}</div>}
                </form>
            </div>
            <Footer />
        </section>
    );
};

export default CreateNewPassword;
