import { useEffect, useState } from "react";
import "../../css/school_report.css";
import {
  getAllSchoolTermDetails,
  getAllSubjects,
  getClasses,
  getClassNameById,
  getDetailsAboutASchool,
  getSchoolTermDetails,
  getStudentReportCard,
  getSubjectName,
  getTermId,
} from "../../globalConfigs/schoolService";

const SchoolReport = ({
  isSchoolReportAPopup,
  setIsSchoolReportHidden,
  studentData,
  schoolData,
  selectedTerm,
  setSelectedTerm,
  setSelectedStudent,
  selectedStudentId,
  setSelectedStudentId,
  selectedSchoolId,
  setSelectedSchoolId,
  selectedLevelId,
  setSelectedLevelId,
  selectedClassId,
  setSelectedClassId,
  selectedTermId,
  setSelectedTermId,
  selectedYear,
  setSelectedYear,
}) => {
  const calculateGradePercentAverage = (
    gradeData,
    displayAverageGradeOnly
  ) => {};

  const [subjectName, setSubjectName] = useState("");
  const [results, setResults] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [schoolLogoUrl, setSchoolLogoUrl] = useState("");
  const [subjects, setSubjects] = useState("");
  const [className, setClassName] = useState("");

  const classes = [
    {
      classId: "0e578360-572f-42b1-b05d-17d06c4514be",
      className: "Form 3 Green",
    },
    {
      classId: "be124176-04a6-428d-9b5f-b96855479ebd",
      className: "7Y",
    },
    {
      classId: "14c3a16a-b9b1-484a-a2b7-73fd3a97ff06",
      className: "5B",
    },
  ];

  useEffect(() => { 
    setSelectedStudentId(studentData.userId)
    setSelectedSchoolId(studentData.schoolId)
    setSelectedClassId(studentData.classId)
    setSelectedLevelId(studentData.levelId)   

    console.log("Student data: ", studentData);
    getAllSchoolTermDetails(studentData.schoolId).then((response) => {
      setTermsData(response);
      console.log("All terms data: ", response);
    });

    getDetailsAboutASchool(studentData.schoolId).then((response) => {
      setSchoolName(response?.name || "");
      setSchoolLogoUrl(response?.logo)
      console.log("All school details: ", response);
    });

    getClasses(selectedSchoolId).then((res) => {
      console.log("These are all the classes: ", res);
    });

    // getClasses(studentData.schoolId).then((response) => {
    //   setSchoolName(response?.name || "")
    //   console.log("All school classes: ", response)
    // })

    // const getClassNameById = (schoolId, classId) => {
    //   return getClasses(schoolId)
    //     .then((classes) => {
    //       const classDetails = classes.find((classObj) => classObj.classId === classId);
    //       return classDetails ? classDetails.className : "";
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching class name:", error);
    //       return "";
    //     });
    // };

    // function getClassNameById(schoolId, classId) {
    //   const classDetails = classes.find((classObj) => classObj.classId === classId);
    //   return classDetails ? classDetails.className : "";
    // }

    getClassNameById(studentData.schoolId, studentData.classId).then((res) => {
      setClassName(res);
    });

    const subs = studentData.subs;
    // [
    //   {
    //     finalMark: 99,
    //     comment: "Excellent work",
    //     grade: "A",
    //     subjectId: "3fa85f69-0717-3562-b3fc-2c963f76afa4",
    //   },
    //   {
    //     finalMark: 72,
    //     comment: "Good work",
    //     grade: "B",
    //     subjectId: "4fa85f69-0717-3562-b3fc-2c963f76afa6",
    //   },
    //   {
    //     finalMark: 60,
    //     comment: "Good",
    //     grade: "C",
    //     subjectId: "5fa85f69-0717-3562-b3fc-2c963f76afa6",
    //   },
    //   {
    //     finalMark: 88,
    //     comment: "Excellent",
    //     grade: "A",
    //     subjectId: "6fa85f69-0717-3562-b3fc-2c963f76afa6",
    //   },
    //   {
    //     finalMark: 95,
    //     comment: "Superb",
    //     grade: "A",
    //     subjectId: "7fa85f69-0717-3562-b3fc-2c963f76afa6",
    //   },
    //   {
    //     finalMark: 95,
    //     comment: "Excellent work",
    //     grade: "A",
    //     subjectId: "lfa85f69-0717-3562-b3fc-2c963f76afa6",
    //   },
    // ]
    // getSubjectName(1, "ba804c31-6a55-46fd-b431-97ace5461977")
    //   .then((res) => {
    //     setSubjectName(res);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching subject name:", error);
    //     setSubjectName("");
    //   });

    const fetchSubjectsWithNames = async () => {
      try {
        const result = await Promise.all(
          subs.map(async ({ subjectId, ...rest }) => {
            try {
              const subjectName = await getSubjectName(selectedSchoolId, subjectId);
              return { subjectId, ...rest, subjectName };
            } catch (error) {
              console.error("Error fetching subject name:", error);
              return { subjectId, ...rest, subjectName: "" };
            }
          })
        );

        setSubjectsData(result);
      } catch (error) {
        setSubjectsData([]);
        console.error("Error processing subjects:", error);
      }
    };

    fetchSubjectsWithNames();

    // console.log("Student data", studentData);
    // console.log("Subs", studentData.subs);
  }, [studentData]);

  const current_year = parseInt(new Date().getFullYear());

  const years_data = () => {
    const years = [];
    for (let i = current_year; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  const allTermNames = termsData.map(({ name }) => name);

  console.log(
    getSubjectName(1, "ba804c31-6a55-46fd-b431-97ace5461977")
      .then((res) => {
        return res;
      })
      .then((r) => {
        return r;
      })
  );

  console.log(
    getAllSubjects(1).then((r) => {
      return r;
    })
  );

  return (
    <div className="school_report">
      {isSchoolReportAPopup && (
        <div className="close_report_container">
          <button
            className="close_parent"
            onClick={(e) => {
              e.preventDefault();
              setSubjectsData([]);
              setTermsData([]);
              if (isSchoolReportAPopup) {
                e.target.parentElement.parentElement.classList.remove(
                  "displayed_flex"
                );
              } else {
                e.target.parentElement.classList.remove("displayed_flex");
              }
              // if (setIsSchoolReportHidden) setIsSchoolReportHidden(true);
              setIsSchoolReportHidden(true);
            }}
          >
            +
          </button>
        </div>
      )}
      <h2 className="school_report_heading">
        {studentData?.firstName + " " + studentData?.lastName}'s report card
      </h2>
      <div className="school_report__filter_container">
        <form action="">
          <span>Showing report card for:</span>
          <select name="term" id="term">
            {allTermNames.map((term, i) => (
              <option
                key={i}
                onChange={(e) => {
                  setSelectedTerm(e.target.value); 
                  getTermId(selectedSchoolId, selectedTerm).then((response) => {
                    setSelectedTermId(response)
                    console.log("Term id: ", response)
                  }) 
                }}
              >
                {term}
              </option>
            ))}
          </select>
          <select name="year" id="year">
            {years_data().map((year, i) => (
              <option
                key={i}
                value={year}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
              >
                {year}
              </option>
            ))}
          </select>
          <button type="submit" onClick={(e) => {
            e.preventDefault() 
             getSchoolTermDetails(selectedSchoolId, selectedTerm).then((response) => {
              setSelectedTermId(response)
              console.log("School terms: ", response)
            })
            getStudentReportCard(
              selectedStudentId,
              selectedSchoolId,
              selectedLevelId,
              selectedClassId,
              selectedTermId,
              selectedYear
            ).then((response) => {
              console.log(response);
            });
          }}>Filter report</button>
        </form>
      </div>
      <div className="print_and_download_container">
        <button className="print">Print</button>
        <button className="download">Download report card</button>
      </div>
      <div className="school_report_main_data">
        <div className="school_logo_and_name_container">
          <img src={schoolLogoUrl} alt={`${schoolName} logo`} />
          <h3 className="school_logo_and_name_container">
            {schoolName !== "" ? schoolName : "SCHOOL NAME"}
          </h3>
        </div>
        <div className="school_report__details_container">
          <h3>REPORT CARD</h3>
          <div className="data">
            <span>
              <b>Name of student:&nbsp;</b>
              {studentData?.firstName +
                " " +
                studentData?.middleName +
                " " +
                studentData?.lastName}
            </span>
            <span>
              <b>Class/Grade:&nbsp;</b>
              {className}
            </span>
            {/* {studentData?.term && (
              <span>
                <b>Term:&nbsp;</b>
                {studentData.term}
              </span>
            )}
            {studentData?.year && (
              <span>
                <b>Year:&nbsp;</b>
                {studentData.year}
              </span>
            )}
            {studentData?.classPosition && (
              <span>
                <b>Class Position:&nbsp;</b>
                {studentData.classPosition}
              </span>
            )} */}
          </div>
        </div>
        <div className="school_report_details_and_grading_system_container">
          <div className="school_report_details_and_grading_system_container__left">
            <div className="school_report_details_and_grading_system_container__left_1">
              {subjectsData && (
                <h3>
                  <span>SUBJECT</span>
                  <span>SCORE</span>
                  <span>GRADE</span>
                  <span>TEACHER'S COMMENT</span>
                </h3>
              )}
              <div className="results">
                {subjectsData &&
                  subjectsData.map((student_result, i) => (
                    <p key={i}>
                      <span>{student_result.subjectName}</span>
                      <span>{student_result.finalMark}</span>
                      <span>{student_result.grade}</span>
                      <span>{student_result.comment}</span>
                    </p>
                  ))}
              </div>
            </div>
            {/* {results?.attendance && (
              <div className="school_report_details_and_grading_system_container__left_2">
                <h3>ATTENDANCE</h3>
                <div className="data">
                  <p>
                    <span>Total Days of School:&nbsp;</span>
                    <span>{results.totalDaysOfSchool}</span>
                  </p>
                  <p>
                    <span>Days Attendance:&nbsp;</span>
                    <span>{results.attendance}</span>
                  </p>
                  <p>
                    <span>Days Absent:&nbsp;</span>
                    <span>
                      {parseInt(studentData.totalDaysOfSchool) -
                        studentData.attendance}
                    </span>
                  </p>
                </div>
              </div>
            )} */}
            {/* <div className="school_report_details_and_grading_system_container__left_3">
              <h3>
                <span className="grade_percent_average">
                  GRADE PERCENTAGE AVERAGE
                </span>
                <span className="score">SCORE</span>
                <span className="grade">GRADE</span>
              </h3>
              <div className="data">
                <span className="data__grade_percent_average">
                  Average percentage of all subjects
                </span>
                <span className="data__score">
                  {
                    // calculateGradePercentAverage(studentData?.results)
                    "90%"
                  }
                </span>
                <span className="data__grade">
                  {
                    // calculateGradePercentAverage(
                    //   studentData?.results,
                    //   "displayAverageGradeOnly"
                    // )
                    "A"
                  }
                </span>
              </div>
            </div> */}
          </div>
          {/* <div className="school_report_details_and_grading_system_container__right">
            <h3>GRADING SYSTEM</h3>
            <div className="data">
              {Object.keys(schoolData?.gradingSystem).map((data, i) => (
                <p key={i}>
                  <span>{data}</span>
                  <span>{`${schoolData?.gradingSystem[data].lowerLimit} ${
                    schoolData?.gradingSystem[data].upperLimit && " - "
                  } ${schoolData?.gradingSystem[data].upperLimit}`}</span>
                </p>
              ))}
            </div>
          </div> */}
        </div>
        <div className="school_stamp_and_head_comment_container">
          <div className="school_stamp">
            <span>SCHOOL STAMP</span>
            <p className="date_results_published">
              {schoolData?.dateResultsPublished}
            </p>
          </div>
          <div className="head_comment">
            <h3>HEAD/PRINCIPAL'S COMMENT</h3>
            <p>{studentData?.headComment}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolReport;
