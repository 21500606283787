import { useState } from "react";
import cancelbutton from "../../../svg/cancelbutton.svg";

export default function TeacherPortalViewProfile({onclose}){
    const [closeProfile, setCloseProfile] = useState("block");

    const closeStudentProfile = () => {
        setCloseProfile("none");
        onclose()
    }
    return <div style={{display: `${closeProfile}`}}>
                <div className='teacher-portal-student-profile-main-container'>
                    <div className='teacher-portal-student-profile'>
                         <div>Student profile for Fungai Mutasa</div>
                         <div><img onClick={closeStudentProfile} src={cancelbutton} /></div>
                    </div>
                    <div className='teacher-portal-student-profile-content'>
                        <div>Name: Fungai Mutasa</div>
                        <div>Date of birth: 20/12/2008</div>
                        <div>Email: fungaim@gmail.com</div>
                        <div>Phone number: +263 772772773</div>
                        <div>Class: Form 3A</div>
                        <div>Blood group: A negative</div>
                        <div>Parent/Guardian: Sthembile Mutasa</div>
                        <div>Sthembile Mutasa phone number: +263 712228883</div>
                        <div>Parent/Guardian: Paul Refsnes</div>
                        <div>Paul Refsnes phone number: +263 712228883</div>
                        <div>Home address: 
                            214 Duri St
                            Greendale
                            Harare
                            Zimbabwe
                        </div>
                    </div>
                </div>
            </div>
}