import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/loader.css';


const CircularLoader = () => {
    return (
        <div className="loading-overlay">
            <CircularProgress className="loading-spinner" />
        </div>
    );
};

export default CircularLoader;
