import { useEffect, useState } from 'react';
import '../../../../css/schoolInfo.css';
import SchoolPopup from './addInfoPopUp';
import { schoolID, token } from '../../../../globalConfigs/userLoginInfo';
import { API_URL } from '../../../../globalConfigs/apiConfigs';
import { CircularProgress } from '@mui/material';

const SchoolInfo = () => {
  const [popupContent, setPopupContent] = useState(null);
  const [schoolData, setSchoolData] = useState(null);
  const [deptData, setDeptData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [getLevelData, setLevelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentYear = new Date().getFullYear();

  const openModal = (type) => {
    setPopupContent(type);
  };

  const closeModal = () => {
    setPopupContent(null);
  };

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/terms`, {
          headers: {
            'Authorization': 'Bearer ' + token,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch school data");
        }
        const data = await response.json();

        // Find the approved term
        const approvedTerm = data.find(term => term.status === "APPROVED");
        if (approvedTerm) {
          setSchoolData(approvedTerm);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolData();
  }, []);

  // Count
  useEffect(() => {
    const fetchDeptData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/departments`,{
          headers: {
            'Authorization': 'Bearer ' + token,
          },
        });
        const result = await response.json();
        setDeptData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDeptData();
  }, []);


  useEffect(() => {
    const fetchSubData = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/subjects`,{
          headers: {
            'Authorization': 'Bearer ' + token,
          },
        });
        const result = await response.json();
        setSubData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubData();
  }, []);

  useEffect(() => {
    const fetchLevel = async () => {
      try {
        const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/levels`,{
          headers: {
            'Authorization': 'Bearer ' + token,
          },
        });
        const result = await response.json();
        setLevelData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLevel();
  }, []);

  return (
    <div className="school-info-container">
      <div className="school-info-grid">
        <div className="info-item">
          <span>
            <strong className="showTerm">
              {currentYear}
            </strong>
          </span>
          <button onClick={() => openModal('academicYear')}>Add academic year</button>
        </div>
        <div className="info-item">
          <span>
            <strong className="showTerm">
              {schoolData?.name || (loading ? <CircularProgress /> : "No approved term")}
            </strong>
          </span>
          <button className="infoBtn" onClick={() => openModal('term')}>Add a term</button>
        </div>
        <div className="info-item">
          <span>
            <strong className="showTerm">
              {loading ? <CircularProgress /> : `${deptData.length} Departments`}
            </strong>
          </span>
          <button onClick={() => openModal('department')}>Add a department</button>
        </div>
        <div className="info-item">
          <span><strong className="showTerm">{loading ? <CircularProgress /> : `${getLevelData.length} Levels/Grades`}</strong></span>
          <button onClick={() => openModal('level')}>Add a level/grade</button>
        </div>
        <div className="info-item">
          <span><strong className="showTerm">{loading ? <CircularProgress /> : `${subData.length} Subjects`}</strong></span>
          <button onClick={() => openModal('subject')}>Add a subject</button>
        </div>
      </div>

      {popupContent && (
        <SchoolPopup contentType={popupContent} closeModal={closeModal} />
      )}
    </div>
  );
};

export default SchoolInfo;
