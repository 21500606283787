import { useState } from "react";
import Footer from "../../components/layout/Footer";
import Nav from "../../components/layout/Nav";
import FormInput from "../../components/widgets/FormInput";
import "../../css/forminput.css";
import "../../css/enrolment.css";
import "../../css/landing_page.css";
import { Link } from "react-router-dom";
import { API_URL, API_TOKEN, postEnquiry } from "../../globalConfigs/apiConfigs";
import SuccessErrorModalLandingPage from "../../components/widgets/SuccessErrorModalLandingPage";
import LoadingLandingPage from "../../components/widgets/LoadingLandingPage";
import HeroSection from "./hero_section";


const LandingPage = () => {
  const [enquiryFormData, setEnquiryFormData] = useState({
    name: "",
    headFirstname: "rrrrrrrrrrrrrrrr",
    headLastname: "",
    headPhonenumber: "",
    headEmail: "",
    schoolEmail: "",
    schoolContact: "",
    town: "",
    adminFirstname: "",
    adminLastname: "",
    adminEmail: "",
    adminPhonenumber: "",
    address: "",
    district: "",
    province: "",
    country: "",
    numberOfStudents: "",
    numberOfTeachers: "",
  });





  const [isProvinceSelectionErrorHidden, setIsProvinceSelectionErrorHidden] =
    useState(true);
  const [isDistrictSelectionErrorHidden, setIsDistrictSelectionErrorHidden] =
    useState(true);
  const [districtError, setDistrictError] = useState("");
  const [isEnquiryFormHidden, setIsEnquiryFormHidden] = useState(true);
  const [displaySuccessErrorModal, setDisplaySuccessErrorModal] = useState(false);
  const [displayloader, setDisplayLoader] = useState(false);
  const [hasFormSubmittedSuccessfully, setHasFormSubmittedSuccessfully] = useState(false);
  const [successErrorModalMessage, setSuccessErrorModalMessage] = useState("");






  const submit_enquiry_data_function = async (event) => {
    event.preventDefault();
    setDisplaySuccessErrorModal(false);
    setDisplayLoader(true);
    try {

      const response = await fetch(API_URL + postEnquiry, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + API_TOKEN,
        },
        body: JSON.stringify(enquiryFormData),
      });


      if (response.status === 201) {
        console.log('Resource created successfully:');
        setDisplaySuccessErrorModal(true);
        setDisplayLoader(false);
        setHasFormSubmittedSuccessfully(true);
        setSuccessErrorModalMessage("Enquiry Submitted")
      } else {
        console.log('Failed to create resource. Status:', response.status);

        setSuccessErrorModalMessage("Failed!. Try again ..");



      }

    } catch (error) {
      setHasFormSubmittedSuccessfully(false);
      setSuccessErrorModalMessage("Failed!. Try again ..")
      console.log(error);
      console.log("something went wrong");
    }

  }


  const clients_data = {
    langham: {
      name: "Langham Girls High School",
      desc: "Best school in Mazowe District",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      testifier: "Headmaster, Langham Girls High School",
      logo_src: "images/langham_logo.png",
    },
    st_johns: {
      name: "St Johns High School Chikwaka",
      desc: "Best school in Goromonzi District",
      message:
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      testifier: "Senior Mistress, St John's Chikwaka High School",
      logo_src: "images/st_johns_logo.png",
    },
    mt_mellary: {
      name: "Mt Melleray Primary School",
      desc: "School in Nyanga",
      message:
        "Dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut ",
      testifier: "Teacher in charge, Mt Melleray Primary School",
      logo_src: "images/mt_melleray_logo.png",
    },
  };

  const modules_data = {
    student_information_system: {
      heading: "Student Information System",
      content:
        "The student information system efficiently records all student information and presents it in accessible formats such as easily understandable outputs, printable reports, and statistics.",
      style_class: "modules_list__student_info",
    },
    institution_management: {
      heading: "Institution Administration",
      content:
        "Tools for decision making and rapid response are integrated into the institutions very heart.",
      style_class: "modules_list__institution_admin",
    },
    student_performance_management: {
      heading: "Student Performance Management",
      content:
        "Stay on top of your students academic progress at all times with real-time performance reporting and tracking.",
      style_class: "modules_list__student_performance",
    },
    communication: {
      heading: "Communication",
      content:
        "At all times, you maintain constant communication with all stakeholders and retain real-time control.",
      style_class: "modules_list__communication",
    },
  };

  const software_features = {
    admission: {
      heading: "Admission without the need for paperwork",
      desc: "Enhance efficiency and convenience by implementing a digital, paper-free admission process.",
    },
    activity_reporting: {
      heading: "Activity Reporting",
      desc: "Gain insights and make informed decisions by tracking and analyzing activities.",
    },
    smart_dashboards: {
      heading: "Smart Dashboards",
      desc: "Achieve smart insights by conveniently accessing intuitive dashboards with comprehensive, real-time data.",
    },
    exam_mgmnt: {
      heading: "Examination management",
      desc: "Effectively supervise assessments with efficient procedures and thorough supervision abilities.",
    },
    competitive_edge: {
      heading: "Competitive Edge",
      desc: "You hold within your grasp, without any delays, all the necessary data for making sound decisions on demand.",
    },
    collaboration: {
      heading: "Collaboration",
      desc: "Maintaining a constant flow of information allows for all stakeholders to stay connected and informed about the institute.",
    },
  };


  function addInputDataToFormData(input, input_name) {
    setEnquiryFormData((prevFormData) => ({
      ...prevFormData,
      [input_name]: input,
    }));
    console.log(enquiryFormData);
  }


  return (
    <section className="landing_page">
      {displayloader && (<LoadingLandingPage message={"Saving Enquiry. Please wait ..."} />)}
      {displaySuccessErrorModal == true ? <SuccessErrorModalLandingPage
        message={successErrorModalMessage}
        setShowSuccessErrorModal={setDisplaySuccessErrorModal}
        hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully} /> : ""}

      {/*<Nav />*/}
      <div>
      <HeroSection/>
      </div>
      
      
      {/*<div className="hero_section">
     

        <div className="hero_section__left">
          <h1>Welcome to Brainstake Eschools</h1>
          <span></span>
        </div>
        <div className="hero_section__right">
          <p>Your ultimate school management system</p>
          <Link to="#enrol_your_school_today">Enrol your school today</Link>
          <span></span>
        </div>
      </div>*
      <div className="who_we_are">
        <h2>Who we are</h2>
        <p>
          At BrainStake, we empower educational institutions with innovative,
          all-in-one management solutions, streamlining operations and enhancing
          communication to foster student success.
        </p>
      </div>
      <div className="why_choose">
        <h2>Why choose Brainstake eSchools</h2>
        <div className="why_choose__options">
          <span className="why_choose__options__option">Reliable</span>
          <span className="why_choose__options__option">Available</span>
          <span className="why_choose__options__option">Accessible</span>
          <span className="why_choose__options__option">Usable</span>
          <span className="why_choose__options__option">Secure</span>
        </div>
      </div>*/}
      <div className="features">
        <h2>Features of the software</h2>
        <div className="software_features">
          {Object.keys(software_features).map((key, i) => (
            <div key={i} className="software_features__feature">
              <h3 className="software_features__feature__heading">
                {software_features[key].heading}
              </h3>
              <p className="software_features__feature__desc">
                {software_features[key].desc}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="our_valued">
        <h2>
          Our valued customers experiencing effective, efficient and effortless
          school management
        </h2>
        <div className="our_valued__clients">
          <div className="some_of_our_clients">Some of our clients</div>
          <div className="placeholder__vertical"></div>
          <div className="our_valued__clients_cont">
            {Object.keys(clients_data)
              .filter((key, i) => i <= 1)
              .map((key, i) => (
                <div key={i} className="our_valued__clients__cont__div">
                  <div className="our_valued__clients__cont__div__left">
                    <p className="our_valued__clients__cont__div__name">
                      {clients_data[key].name}
                    </p>
                    <p className="our_valued__clients__cont__div__desc">
                      {clients_data[key].desc}
                    </p>
                  </div>
                  <img
                    alt={`${clients_data[key]?.name} Logo`}
                    src={clients_data[key]?.logo_src}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="empowering">
        Empowering students with seamless access to resources, personalized
        learning, and real-time academic insights for a brighter future
      </div>
      <div className="unlock_comprehensive">
        <div className="unlock_comprehensive__top">
          <span>
            Unlock the power of effective, efficient and effortless Educational
            Institution Management.
          </span>
        </div>
        <div className="unlock_comprehensive__bottom">
          <span>
            Comprehensive, secure, and user-friendly software solution that
            helps educational institutions of all sizes streamline their
            operations, improve efficiency, make informed decisions, and enhance
            effectiveness in achieve their goals.
          </span>
        </div>
      </div>
      <div className="modules">
        <h2>Brainstake eSchools Modules</h2>
        <p className="modules__desc">
          With a keen focus on every aspect of your institutions day-to-day
          running, these modules intricately cooperate to present accurate and
          timely informative reports.
        </p>
        <div className="modules_list__container">
          {Object.keys(modules_data)?.map((key, i) => (
            <div
              key={i}
              className={`modules_list ${modules_data[key]?.style_class}`}
            >
              <h3>Module</h3>
              <h4>{modules_data[key]?.heading}</h4>
              <p>{modules_data[key]?.content}</p>
            </div>
          ))}
        </div>
        <p className="many_more">...and many more to come</p>
      </div>
      <div className="testimonials">
        <h3>
          Here's what some of our customers have to say about Brainstake
          eSchools
        </h3>
        <div className="testimonials_data">
          {Object.keys(clients_data)?.map((key, i) => (
            <div key={i} className="testimonial_slider" data_slider={i}>
              <div className="testimonial_slider__top">
                <p>{clients_data[key]?.message}</p>
                <img
                  alt={`${clients_data[key]?.name} Logo`}
                  src={clients_data[key]?.logo_src}
                />
              </div>
              <div className="testimonial_slider__bottom">
                <span className="black_placeholder"></span>
                <span className="testifier">
                  {clients_data[key]?.testifier}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="sliders">
          {Object.keys(clients_data)?.map((key, i) => (
            <button
              key={i}
              className={`slider ${i === 0 ? "current_slider" : ""}`}
              onClick={(e) => {
                if (e.target.classList.contains("current_slider")) {
                  return;
                }

                document.querySelectorAll(".slider").forEach((slider) => {
                  slider.classList.remove("current_slider");
                });

                document
                  .querySelectorAll(".testimonial_slider")
                  .forEach((testimonial) => {
                    if (
                      parseInt(testimonial.getAttribute("data_slider")) !== i
                    ) {
                      testimonial.classList.add("displayed_none");
                    } else {
                      testimonial.classList.remove("displayed_none");
                    }
                  });
                e.target.classList.add("current_slider");
              }}
            ></button>
          ))}
        </div>
      </div>
      <div className="get_in_touch">
        <p className="get_in_touch__txt">
          Ready to take school management to the next level? Reach out today
        </p>
        <Link to="/contact">Contact us</Link>
      </div>
      <div
        className="toggle_enquiry_form_container"
        id="enrol_your_school_today"
      >
        <button
          className="toggle_enquiry_form"
          onClick={(e) => {
            e.preventDefault();
            if (isEnquiryFormHidden) {
              setIsEnquiryFormHidden(false);
              document.querySelector(
                ".toggle_enquiry_form_container__txt"
              ).innerHTML = "Hide enquiry form";
              document.querySelector(
                ".toggle_enquiry_form_container__hidden_or_shown"
              ).innerHTML = "-";
              return;
            }
            setIsEnquiryFormHidden(true);
            document.querySelector(
              ".toggle_enquiry_form_container__txt"
            ).innerHTML = "Show enquiry form";
            document.querySelector(
              ".toggle_enquiry_form_container__hidden_or_shown"
            ).innerHTML = "+";
          }}
        >
          <span className="toggle_enquiry_form_container__txt">
            Show enquiry form
          </span>
          <span className="toggle_enquiry_form_container__hidden_or_shown">
            +
          </span>
        </button>
      </div>
      <div className="enquiry_form_container">
        <form onSubmit={submit_enquiry_data_function}
          className={`enquiry_form ${!isEnquiryFormHidden ? " displayed_flex" : ""
            }`}
        >
          <h3>
            Have an enquiry? Fill in the form below and we'll get back to you
          </h3>
          <FormInput
            type="text"
            required
            id={`name`}
            placeholder="Enter the name of your school"
            title="Name of School"
            name="name"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "name");
            }}
            value={enquiryFormData?.name}
          />
          <FormInput
            type="text"
            required
            id={`town`}
            placeholder="Enter the name of your school towm/city"
            title="Name of Town"
            town="town"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "town");
            }}
            value={enquiryFormData?.town}
          />
          <div className="form_input_container">
            <label htmlFor="country">
              Select the name of the school country
            </label>
            <select
              id={`country`}
              name="country"
              onChange={(e) => {
                addInputDataToFormData(e.target.value, "country");
              }}
            >
              <option value="">Select the school country</option>
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
          <div className="province_container">
            <p
              className={`province_error ${!isProvinceSelectionErrorHidden ? " displayed_flex" : ""
                }`}
            >
              <span>Select the country first</span>
              <button
                className="close_parent"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement.classList.remove("displayed_flex");
                  setIsProvinceSelectionErrorHidden(true);
                }}
              >
                +
              </button>
            </p>
            {enquiryFormData?.country === "Zimbabwe" ? (
              <div className="form_input_container">
                <label htmlFor="province">Select the province</label>
                <select
                  id={`province`}
                  name="province"
                  onChange={(e) => {
                    addInputDataToFormData(e.target.value, "province");
                  }}
                  required
                >
                  <option value="">Select the province</option>
                  <option value="Bulawayo">Bulawayo</option>
                  <option value="Harare">Harare</option>
                  <option value="Manicaland">Manicaland</option>
                  <option value="Mashonaland Central">
                    Mashonaland Central
                  </option>
                  <option value="Mashonaland East">Mashonaland East</option>
                  <option value="Mashonaland West">Mashonaland West</option>
                  <option value="Masvingo">Masvingo</option>
                  <option value="Matabeleland North">Matabeleland North</option>
                  <option value="Matabeleland South">Matabeleland South</option>
                  <option value="Midlands">Midlands</option>
                </select>
              </div>
            ) : (
              <FormInput
                type="text"
                id={`province`}
                placeholder="Enter your school province"
                title="Name of Province"
                name="province"
                onChange={(e) => {
                  if (
                    !enquiryFormData?.country ||
                    enquiryFormData?.country === ""
                  ) {
                    setIsProvinceSelectionErrorHidden(false);
                    return;
                  }
                  addInputDataToFormData(e.target.value, "province");
                }}
                value={enquiryFormData?.province}
              />
            )}
          </div>
          <div className="district_container">
            <p
              className={`district_error ${!isDistrictSelectionErrorHidden ? " displayed_flex" : ""
                }`}
            >
              <span>{districtError}</span>
              <button
                className="close_parent"
                onClick={(e) => {
                  e.preventDefault();
                  e.target.parentElement.classList.remove("displayed_flex");
                  setIsDistrictSelectionErrorHidden(true);
                }}
              >
                +
              </button>
            </p>
            {enquiryFormData?.country === "Zimbabwe" ? (
              <div className="form_input_container">
                <label htmlFor="district">
                  Select the name of the school district
                </label>
                <select
                  name="district"
                  id="district"
                  onChange={(e) => {
                    const selected_option = e.target.selectedOptions[0]
                    if (selected_option.getAttribute("data_province") !== enquiryFormData?.province) {
                      setDistrictError(`${selected_option.value} district does not belong in ${enquiryFormData?.province} province`);
                      setIsDistrictSelectionErrorHidden(false);
                      return;
                    }
                    addInputDataToFormData(e.target.value, "district");
                  }}
                  className="zim_district selectd"
                >
                  <option value="">Select the school district</option>
                  <optgroup label="Manicaland" >
                    <option
                      value="Buhera" data_province="Manicaland" >Buhera</option>
                    <option
                      value="Chimanimani" data_province="Manicaland" >Chimanimani</option>
                    <option
                      value="Chipinge" data_province="Manicaland" >Chipinge</option>
                    <option
                      value="Makoni" data_province="Manicaland" >Makoni</option>
                    <option
                      value="Mutare Rural" data_province="Manicaland" >Mutare Rural</option>
                    <option
                      value="Mutasa" data_province="Manicaland" >Mutasa</option>
                    <option
                      value="Nyanga" data_province="Manicaland" >Nyanga</option>
                    <option
                      value="Mutare Urban" data_province="Manicaland" >Mutare (Urban)</option>
                    <option
                      value="Rusape" data_province="Manicaland" >Rusape</option>
                    <option
                      value="Chipinge Urban" data_province="Manicaland" >Chipinge (Urban)</option>
                  </optgroup>
                  <optgroup label="Mashonaland Central" >
                    <option
                      value="Bindura" data_province="Mashonaland Central" >Bindura</option>
                    <option
                      value="Centenary" data_province="Mashonaland Central" >Centenary</option>
                    <option
                      value="Guruve" data_province="Mashonaland Central" >Guruve</option>
                    <option
                      value="Mazowe" data_province="Mashonaland Central" >Mazowe</option>
                    <option
                      value="Mount Darwin" data_province="Mashonaland Central" >Mount Darwin</option>
                    <option
                      value="Rushinga" data_province="Mashonaland Central" >Rushinga</option>
                    <option
                      value="Shamva" data_province="Mashonaland Central" >Shamva</option>
                    <option
                      value="Mbire" data_province="Mashonaland Central" >Mbire</option>
                    <option
                      value="Bindura Urban" data_province="Mashonaland Central" >Bindura (Urban)</option>
                    <option
                      value="Mvurwi" data_province="Mashonaland Central" >Mvurwi</option>
                  </optgroup>
                  <optgroup label="Mashonaland East">
                    <option
                      value="Chikomba" data_province="Mashonaland East" >Chikomba</option>
                    <option
                      value="Goromonzi" data_province="Mashonaland East" >Goromonzi</option>
                    <option
                      value="Hwedza" data_province="Mashonaland East" >Hwedza</option>
                    <option
                      value="Marondera" data_province="Mashonaland East" >Marondera</option>
                    <option
                      value="Mudzi" data_province="Mashonaland East" >Mudzi</option>
                    <option
                      value="Murehwa" data_province="Mashonaland East" >Murehwa</option>
                    <option
                      value="Mutoko" data_province="Mashonaland East" >Mutoko</option>
                    <option
                      value="Seke" data_province="Mashonaland East" >Seke</option>
                    <option
                      value="Uzumba-Maramba-Pfungwe" data_province="Mashonaland East" >
                      Uzumba-Maramba-Pfungwe
                    </option>
                    <option
                      value="Marondera Urban" data_province="Mashonaland East" >Marondera (Urban)</option>
                    <option
                      value="Chivhu Town Board" data_province="Mashonaland East" >Chivhu Town Board</option>
                    <option
                      value="Ruwa Local Board" data_province="Mashonaland East" >Ruwa Local Board</option>
                  </optgroup>
                  <optgroup label="Mashonaland West" >
                    <option
                      value="Chegutu" data_province="Mashonaland West" >Chegutu</option>
                    <option
                      value="Hurungwe" data_province="Mashonaland West" >Hurungwe</option>
                    <option
                      value="Kariba" data_province="Mashonaland West" >Kariba</option>
                    <option
                      value="Makonde" data_province="Mashonaland West" >Makonde</option>
                    <option
                      value="Mhondoro-Ngezi" data_province="Mashonaland West" >Mhondoro-Ngezi</option>
                  </optgroup>
                  <optgroup label="Matabeleland North" >
                    <option
                      value="Binga" data_province="Matabeleland North" >Binga</option>
                    <option
                      value="Bubi" data_province="Matabeleland North" >Bubi</option>
                    <option
                      value="Hwange" data_province="Matabeleland North" >Hwange</option>
                    <option
                      value="Lupane" data_province="Matabeleland North" >Lupane</option>
                    <option
                      value="Nkayi" data_province="Matabeleland North" >Nkayi</option>
                    <option
                      value="Tsholotsho" data_province="Matabeleland North" >Tsholotsho</option>
                    <option
                      value="Umguza" data_province="Matabeleland North" >Umguza</option>
                    <option
                      value="Victoria Falls" data_province="Matabeleland North" >Victoria Falls</option>
                  </optgroup>
                  <optgroup label="Matabeleland South" >
                    <option
                      value="Beitbridge Rural" data_province="Matabeleland South" >Beitbridge Rural</option>
                    <option
                      value="Bulilima" data_province="Matabeleland South" >Bulilima</option>
                    <option
                      value="Mangwe" data_province="Matabeleland South" >Mangwe</option>
                    <option
                      value="Gwanda Rural" data_province="Matabeleland South" >Gwanda Rural</option>
                    <option
                      value="Insiza" data_province="Matabeleland South" >Insiza</option>
                    <option
                      value="Matobo" data_province="Matabeleland South" >Matobo</option>
                    <option
                      value="Umzingwane" data_province="Matabeleland South" >Umzingwane</option>
                    <option
                      value="Gwanda Urban" data_province="Matabeleland South" >Gwanda (Urban)</option>
                    <option
                      value="Beitbridge Urban" data_province="Matabeleland South" >Beitbridge (Urban)</option>
                    <option
                      value="Plumtree" data_province="Matabeleland South" >Plumtree</option>
                  </optgroup>
                  <optgroup label="Midlands" >
                    <option
                      value="Chirumhanzu" data_province="Midlands" >Chirumhanzu</option>
                    <option
                      value="Gokwe North" data_province="Midlands" >Gokwe North</option>
                    <option
                      value="Gokwe South" data_province="Midlands" >Gokwe South</option>
                    <option
                      value="Gweru Rural" data_province="Midlands" >Gweru Rural</option>
                    <option
                      value="Kwekwe Rural" data_province="Midlands" >Kwekwe Rural</option>
                    <option
                      value="Mberengwa" data_province="Midlands" >Mberengwa</option>
                    <option
                      value="Shurugwi" data_province="Midlands" >Shurugwi</option>
                    <option
                      value="Zvishavane" data_province="Midlands" >Zvishavane</option>
                    <option
                      value="Gweru Urban" data_province="Midlands" >Gweru (Urban)</option>
                    <option
                      value="Kwekwe Urban" data_province="Midlands" >Kwekwe (Urban)</option>
                    <option
                      value="Redcliff" data_province="Midlands" >Redcliff</option>
                    <option
                      value="Gokwe Centre" data_province="Midlands" >Gokwe Centre</option>
                  </optgroup>
                  <optgroup label="Masvingo">
                    <option
                      value="Bikita" data_province="Masvingo" >Bikita</option>
                    <option
                      value="Chiredzi" data_province="Masvingo" >Chiredzi</option>
                    <option
                      value="Chivi" data_province="Masvingo" >Chivi</option>
                    <option
                      value="Gutu" data_province="Masvingo" >Gutu</option>
                    <option
                      value="Masvingo Rural" data_province="Masvingo" >Masvingo Rural</option>
                    <option
                      value="Mwenezi" data_province="Masvingo" >Mwenezi</option>
                    <option
                      value="Zaka" data_province="Masvingo" >Zaka</option>
                    <option
                      value="Masvingo Urban" data_province="Masvingo" >Masvingo (Urban)</option>
                    <option
                      value="Chiredzi Town" data_province="Masvingo" >Chiredzi (Town)</option>
                  </optgroup>
                  <optgroup label="Harare" >
                    <option
                      value="Harare Rural" data_province="Harare" >Harare Rural</option>
                    <option
                      value="Harare Urban" data_province="Harare" >Harare (Urban)</option>
                    <option
                      value="Chitungwiza" data_province="Harare" >Chitungwiza</option>
                    <option
                      value="Epworth" data_province="Harare" >Epworth</option>
                  </optgroup>
                  <optgroup label="Bulawayo" >
                    <option
                      value="Bulawayo Urban" data_province="Bulawayo" >Bulawayo (Urban)</option>
                  </optgroup>
                </select>
              </div>
            ) : (
              <FormInput
                type="text"
                id={`district`}
                placeholder="Enter the name of your school district"
                title="Name of District"
                name="district"
                onChange={(e) => {
                  if (
                    !enquiryFormData?.country ||
                    enquiryFormData?.country === ""
                  ) {
                    setDistrictError("Select the country first");
                    setIsDistrictSelectionErrorHidden(false);
                    return;
                  }
                  addInputDataToFormData(e.target.value, "district");
                }}
                value={enquiryFormData?.district}
              />
            )}
          </div>
          <FormInput
            type="text"
            required
            id={`headFirstName`}
            placeholder="Enter the head's first name"
            title="First name of Head"
            name="headFirstName"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "headFirstName");
            }}
            value={enquiryFormData?.headFirstName}
          />
          <FormInput
            type="text"
            required
            id={`headLastname`}
            placeholder="Enter the head's last name"
            title="Last name of Head"
            name="headLastname"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "headLastname");
            }}
            value={enquiryFormData?.headLastname}
          />
          <FormInput
            type="text"
            required
            id={`headPhonenumber`}
            placeholder="Enter the head's phone"
            title="Head's phone number"
            name="headPhonenumber"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "headPhonenumber");
            }}
            value={enquiryFormData?.headPhonenumber}
          />
          <FormInput
            type="email"
            required
            id={`headEmail`}
            placeholder="Enter the head's email"
            title="Head's email"
            name="headEmail"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "headEmail");
            }}
            value={enquiryFormData?.headEmail}
          />
          <FormInput
            type="text"
            required
            id={`adminFirstName`}
            placeholder="Enter the admin's first name"
            title="First name of admin"
            name="adminFirstName"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "adminFirstName");
            }}
            value={enquiryFormData?.adminFirstName}
          />
          <FormInput
            type="text"
            required
            id={`adminLastName`}
            placeholder="Enter the admin's last name"
            title="Last name of admin"
            name="adminLastName"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "adminLastName");
            }}
            value={enquiryFormData?.adminLastName}
          />
          <FormInput
            type="text"
            required
            id={`adminPhonenumber`}
            placeholder="Enter the admin's phone number"
            title="Admin's phone number"
            name="adminPhonenumber"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "adminPhonenumber");
            }}
            value={enquiryFormData?.adminPhonenumber}
          />
          <FormInput
            type="email"
            required
            id={`adminEmail`}
            placeholder="Enter the admin's email"
            title="Admin's email"
            name="adminEmail"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "adminEmail");
            }}
            value={enquiryFormData?.adminEmail}
          />
          <FormInput
            type="text"
            required
            id={`numberOfStudents`}
            placeholder="Enter the number of students"
            title="Number of Students"
            name="numberOfStudents"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "numberOfStudents");
            }}
            value={enquiryFormData?.numberOfStudents}
          />
          <FormInput
            type="text"
            required
            id={`numberOfTeachers`}
            placeholder="Enter the number of teachers"
            title="Number of Teachers"
            name="numberOfTeachers"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "numberOfTeachers");
            }}
            value={enquiryFormData?.numberOfTeachers}
          />
          <FormInput
            type="text"
            required
            id={`schoolContact`}
            placeholder="Enter the school phone number"
            title="School phone number"
            name="schoolContact"
            onChange={(e) => {
              addInputDataToFormData(e.target.value, "schoolContact");
            }}
            value={enquiryFormData?.schoolContact}
          />
          <div className="form_input_container">
            <label htmlFor="school_address">
              Enter the address of the school
            </label>
            <textarea
              required
              id={`address`}
              placeholder="School address"
              title="Enter school address"
              name="address"
              onChange={(e) => {
                addInputDataToFormData(e.target.value, "address");
              }}
              value={enquiryFormData?.address}
            ></textarea>
          </div>
          <button className="submit submit_enquiry_form">Submit</button>
        </form>
      </div>
      <Footer />
    </section>
  );
};

export default LandingPage;
