export const data = [
    {
        "schoolName": "Chemhanza High School",
        "address": "Chemhanza Mission Farm, P.O. Box 58",
        "town": "Hwedza",
        "province": "Mashonaland East",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 1
    },
    {
        "schoolName": "Chindunduma 1 High School",
        "address": "P Bag 2072",
        "town": "Shamva",
        "province": "Mashonaland Central",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 2
    },
    {
        "schoolName": "Chindunduma 2 High School",
        "address": "Kemp Heaven Farm, P Bag 2074 ",
        "town": "Shamva",
        "province": "Mashonaland Central",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 3
    },
    {
        "schoolName": "Kwenda Mission",
        "address": "Kwenda Mission Farm, Ward 22, Sadza Growth Point, P. Bag 2001, Chikomba",
        "town": "Sadza",
        "province": "Mashonaland East",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 4
    },
    {
        "schoolName": "Mavhudzi High School",
        "address": "Chariton Farm, PO Box 93, Nyazura",
        "town": "Headlands",
        "province": "Manicaland ",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 5
    },
    {
        "schoolName": "Methodist High School",
        "address": null,
        "town": "Bulawayo",
        "province": "Bulawayo Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 6
    },
    {
        "schoolName": "Moleli High School",
        "address": "PBag 972, Norton",
        "town": "Norton",
        "province": "Mashonaland West",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 7
    },
    {
        "schoolName": "Nkululeko High School",
        "address": null,
        "town": "Gweru",
        "province": "Midlands ",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 8
    },
    {
        "schoolName": "Pakame High School",
        "address": "PO Box 240\/ PO Box 121 ",
        "town": "Shurugwi",
        "province": "Midlands ",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 9
    },
    {
        "schoolName": "Rusununguko High School",
        "address": " Blue Water Farm, Bromley-Melfort Loop Rd, P. Bag 61, P.O. Box 2021, Bromley",
        "town": "Goromonzi",
        "province": "Mashonaland East",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 10
    },
    {
        "schoolName": "Sandringham Primary School",
        "address": "Sandringham Farms Private bag 915 Sandringham",
        "town": "Norton",
        "province": "Mashonaland West",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 11
    },
    {
        "schoolName": "Sandringham High School",
        "address": "Sandringham Farms Private bag 915 Sandringham",
        "town": "Norton",
        "province": "Mashonaland West",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 12
    },
    {
        "schoolName": "Waddilove Primary School",
        "address": "Waddilove Farm, 25Km along Watershed Rd, P.O Box 3709",
        "town": "Marondera",
        "province": "Mashonaland East",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 13
    },
    {
        "schoolName": "Waddilove High School",
        "address": "Waddilove Farm, 25Km along Watershed Rd, P.O Box 3709",
        "town": "Marondera",
        "province": "Mashonaland East",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 14
    },
    {
        "schoolName": "Langham Girls High School",
        "address": "Langham Hasfa Farm, Mvurwi",
        "town": "Mazowe",
        "province": "Mashonaland Central",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 15
    },
    {
        "schoolName": "St Johns Chikwaka High School",
        "address": "Mapfumo Village, Ward 12, PO Box 40, Juru",
        "town": "Goromonzi",
        "province": "Mashonaland East",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 16
    },
    {
        "schoolName": "Hatcliff High School",
        "address": "PO Box BW1090",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 17
    },
    {
        "schoolName": "Friendship High School",
        "address": "3331 Alpes Road, Hatcliffe Borrowdale",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 18
    },
    {
        "schoolName": "Reekworth Schools",
        "address": "22, Rubidge Close, Hoggerty Hill, Borrowdale",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 19
    },
    {
        "schoolName": "Borrowdale Academy",
        "address": "700 Whisper Road cnr, Borrowdale Road",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 20
    },
    {
        "schoolName": "Ritchworth Primary School",
        "address": "4 Toledo Close Hogerty Hill ",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 21
    },
    {
        "schoolName": "TMS Academy",
        "address": "196 Crowhill Road, Borrowdale Brooke",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 22
    },
    {
        "schoolName": "Malborough High School",
        "address": "Cnr Chinyika and Admiral Tait Streets, PO Box MR199, Marlborough",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 23
    },
    {
        "schoolName": "Crowhill Primary School",
        "address": "2162 Crowhill Views",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0,
        "id": 24
    },
    {
        "schoolName": "Elephant College",
        "address": "40 Gilchrist Dr",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Corner Stone College",
        "address": "Westgate",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "King Solomon High School",
        "address": "108 Harare Dr",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Hilson Elementary School",
        "address": "61 Aples Rd, Vainona",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Malbereign Girls High School",
        "address": "corner takely road &, Sherwood Drrive, Malbereign",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Alfred Beit Primary School",
        "address": "1 First Avenue",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Elis Robin High School",
        "address": "144 Sherwood Drive",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Oakdale High School",
        "address": "216 Rolf Avenue",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Pinewood High School",
        "address": "10376 Kuwadzana Phase 3, Fontainbleu, Kuwadzana",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Vinefields Junior School",
        "address": "5628 Stotford Road, Sunridgepark, Tynwald Rd",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Dominion Heights College",
        "address": "1511 Tynawld South",
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Hatfielf Junior School",
        "address": null,
        "town": "Harare",
        "province": "Harare Province",
        "country": "Zimbabwe",
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": 0.0
    },
    {
        "schoolName": "Hatfield Girls High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Haig Park Primary School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Marting Luther King School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Queensdale Primary School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Courteney Selous School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Prescott Junior School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Melbourne Private Primary School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Gunhill Junior School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Living Waters Theological Seminary",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "David Livingstone Primary School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Vital Group of Schools",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "St Paul Musami High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Manunure High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Mbizo High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Batanai High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "St Angela High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Dzivarasekwa 1 High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    },
    {
        "schoolName": "Dzivarasekwa 2 High School",
        "address": null,
        "town": null,
        "province": null,
        "country": null,
        "numberOfStudents": null,
        "logo": null,
        "theme": null,
        "onBoard": null
    }
]