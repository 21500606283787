import React, { useState, useEffect } from 'react';
import '../../../css/mainDashboard.css';

const EditSchoolEnrolmentDetails = ({ school, isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        country: '',
        admin: '',
        adminEmail: '',
        schoolPhone: '',
        schoolOwnershipType: '',
        status: '',
    });

    useEffect(() => {
        if (school) {
            setFormData(school);
        }
    }, [school]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    if (!isOpen || !school) return null;

    return (
        <div className='divEditOverlay'>
            <div className='divEditModal'>
                <div className='modalHeader'>
                    <h2 className='divHeading'>Edit Enrollment Details</h2>
                    {/* Close icon */}
                    <span className='closeIcon' onClick={onClose}>
                        &times;
                    </span>
                </div>
                <form className='divEditForm'>
                    <div className='divEditColumn'>
                        <label>
                            School name:<br/>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Country:<br/>
                            <input
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className='divEditColumn'>
                        <label>
                            Admin name:
                            <input
                                type="text"
                                name="admin"
                                value={formData.admin}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Admin email:
                            <input
                                type="email"
                                name="adminEmail"
                                value={formData.adminEmail}
                                onChange={handleChange}
                            />
                        </label>
                    </div>

                    <div className='divEditColumn'>
                        <label>
                            School Address:
                            <input
                                type="text"
                                name="schoolAddress"
                                value={formData.admin}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            School email:
                            <input
                                type="email"
                                name="schoolEmail"
                                value={formData.adminEmail}
                                onChange={handleChange}
                            />
                        </label>
                    </div>

                    <div className='divEditColumn'>
                        <label>
                            Admin name:
                            <input
                                type="text"
                                name="admin"
                                value={formData.admin}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Admin email:
                            <input
                                type="email"
                                name="adminEmail"
                                value={formData.adminEmail}
                                onChange={handleChange}
                            />
                        </label>
                    </div>


                    <div className='divEditColumn'>
                        <label>
                            School Phone:
                            <input
                                type="tel"
                                name="schoolPhone"
                                value={formData.schoolPhone}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Ownership Type:
                            <input
                                type="text"
                                name="schoolOwnershipType"
                                value={formData.ownershipType}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className='divEditColumn'>
                        <label>
                            Status:
                            <input
                                type="text"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className='divButtonGroup'>
                        <button type="submit" className='divUpdateButton'>UPDATE SCHOOL INFORMATION</button>
                        <button type="button" className='editCancelButton' onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};


export default EditSchoolEnrolmentDetails;
