import { useState, useEffect } from 'react';
import { fetchSchoolRequests, API_URL } from '../../globalConfigs/apiConfigs';
import { token } from '../../globalConfigs/userLoginInfo';


const FetchSchoolRequests = () => {
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Initializing schools requests counting stats
    const [approvedCount, setApprovedCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);

    useEffect(() => {
        const fetchSchools = async () => {
            try {
                const response = await fetch(API_URL + fetchSchoolRequests, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setSchools(data);

                // Counting number of REJECTED, APPROVED or PENDING schools and display statistics
                const approved = data.filter(school => school.status === 'APPROVED').length;
                const pending = data.filter(school => school.status === 'PENDING').length;
                const rejected = data.filter(school => school.status === 'REJECTED').length;

                // Assign counted values to variable
                setApprovedCount(approved);
                setPendingCount(pending);
                setRejectedCount(rejected);

                setLoading(false);

            } catch (error) {
                console.error('Error fetching schools:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchSchools();
    }, []);

    return { schools, loading, error, approvedCount, pendingCount, rejectedCount };
};

export default FetchSchoolRequests;