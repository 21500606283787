import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    name: "",
    headFirstName: "",
    headLastname: "",
    headPhonenumber: "",
    headEmail: "",
    schoolEmail: "",
    town: "",
    adminFirstName: "",
    adminLastName: "",
    adminEmail: "",
    adminPhonenumber: "",
    address: "",
    schoolContact: "",
    district: "",
    province: "",
    country: "",
    responsibleAuthority: "",
    ownershipType: "",
    paymentReferenceNumber: "",
    paymentMethod: "",
    numberOfStudents: "",
    numberOfTeachers: "",
    note: "",
  },
  schoolLogo: null,
  paymentReceipt: null,
  schoolLogoName: "",
  paymentReceiptName: "",
  isFormLoading: false,
  showLoading: false,
  showSuccessErrorModal: false,
  hasFormSubmittedSuccessfully: false,
};

const enrolYourSchoolSlice = createSlice({
  name: "enrolYourSchool",
  initialState,
  reducers: {
    setFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    setSchoolLogo(state, action) {
      state.schoolLogo = action.payload;
    },
    setPaymentReceipt(state, action) {
      state.paymentReceipt = action.payload;
    },
    setFormLoading(state, action) {
      state.isFormLoading = action.payload;
    },
    setShowLoading(state, action) {
      state.showLoading = action.payload;
    },
    setShowSuccessErrorModal(state, action) {
      state.showSuccessErrorModal = action.payload;
    },
    setHasFormSubmittedSuccessfully(state, action) {
      state.hasFormSubmittedSuccessfully = action.payload;
    },
  },
});

export const {
  setFormData,
  setSchoolLogo,
  setPaymentReceipt,
  setFormLoading,
  setShowLoading,
  setShowSuccessErrorModal,
  setHasFormSubmittedSuccessfully,
} = enrolYourSchoolSlice.actions;

export default enrolYourSchoolSlice.reducer;
