import { useState } from "react";
import cancelbutton from "../../../svg/cancelbutton.svg";


export default function TeacherPortalAddPaper({ examID }) {
    const [closeAddPaperForm, setCloseAddPaperForm] = useState(null);
    // alert(examID);


    const closeAddPaperFormFunction = () => {
        setCloseAddPaperForm("none");
        window.location.reload()
    }
    return <div style={{ display: `${closeAddPaperForm}` }}>
        <div className='teacher-portal-student-profile-main-container'>

            <div className='teacher-portal-student-profile'>
                <div>Add a paper for Physics - A’Level</div>
                <div><img onClick={closeAddPaperFormFunction} src={cancelbutton} /></div>
            </div>
            <div className='teacher-portal-exam-management-container'>
                <div>
                    <form>
                        <div>
                            <div>
                                <label>Exam paper title</label>
                                <input placeholder="Exam paper title" type="text" />
                            </div>
                            <div>
                                <label>Date of exam</label>
                                <input placeholder="DD/MM/YY" type="date" />
                            </div>
                            <div>
                                <label>Exam Status</label>
                                <select>
                                    <option>Select exam status</option>
                                    <option>pending</option>
                                    <option>pending</option>
                                </select>
                            </div>
                            <div>
                                <label>Total Mark</label>
                                <input placeholder="Enter the total mark" type="text" />
                            </div>
                            <div>
                                <label>Paper weight (%)</label>
                                <input placeholder="Enter the paper weight" type="text" />
                            </div>

                        </div>
                        <div className="teacher-portal-form-submit-container">
                            <button className="teacher-portal-form-submit">Add Paper</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
}