import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "../../../../css/admin.css";
import  "../../../../css/teacherManagement.css";
import manage_teacher_icon_question_mark from "../../../../svg/Group 374.svg"
import TeacherManagementTooltip from '../../../schoolAdmin/teacherManagement/teacherManagementComponents/teachermanegementTooltip';
import manage_teacher_icon_cancelbutton from "../../../../svg/cancelbutton.svg"
import TeacherManagementModalPopUp from '../../teacherManagement/teacherManagementComponents/teacherManagementModalPopUp';
import TeacherManagementAddTeacherLoader from '../../teacherManagement/teacherManagementComponents/teacherManagementAddTeacherLoader';
import {bulkUploadStudents_excel, API_URL} from "../../../../globalConfigs/apiConfigs";
import {token} from "../../../../globalConfigs/userLoginInfo";

export default function StudentBulkUpload({ onClose }){
    const [displaymodalpopup, setDisplayModalPopup] = useState(false)


    // =======================================================================
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
    const [displayErrorModal, setDisplayErrorModal] = useState(false);
    const [displayloader, setDisplayLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);


    // ----------------------------------------------------------------
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
      };
    
      const handleUpload = (event) => {
        handleModalPopUp();
        event.preventDefault();

        setDisplaySuccessModal(false);
        setDisplayLoader(true);

        const formData = new FormData();
        formData.append('file', selectedFile);
    
        fetch(API_URL + bulkUploadStudents_excel, {
          method: 'POST',
          headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
    
        })
          .then((data) => {
            console.log(data.status);
            if (data.status == 201) {
                setDisplaySuccessModal(true);
                setDisplayLoader(false);
                setDisplayErrorModal(false);
            }
            
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage("Failed!. Try again ..");
            setDisplaySuccessModal(false);
            setDisplayErrorModal(true);
          });
      };

    // ----------------------------------------------------------------    



       const handleModalPopUp = () => {
        setDisplayLoader(true);
    }
    // =======================================================================

    return <div className="manage_teachers_addteacher_sub_container">
                <div>
                    {displayloader && <TeacherManagementModalPopUp onClose={onClose} text={"Students being added please wait ..."} icon={<TeacherManagementAddTeacherLoader />} />}
                </div>

                <div>
                    {displaySuccessModal && <TeacherManagementModalPopUp onClose={onClose} text={"Students added Success"} icon={<img src={"/images/success_tick.png"} />} />}
                </div>

                <div>
                    {displayErrorModal && <TeacherManagementModalPopUp onClose={onClose} text={"Students To Add Teachers! Try Again."} icon={<img src={"/images/failure_exclamation.png"} />} />}
                </div>

                <div className="manage_teachers_addteacher_hide_button" onClick={ onClose }>
                    <img src={manage_teacher_icon_cancelbutton} />
                </div>
                <div className="manage_teachers_addteacher_bulk_sub_container_title">
                    <h3>Bulk add students via a Excel file</h3>
                    <div>
                        <TeacherManagementTooltip text={"A CSV file is a simple text file used to store data in rows and columns. The columns required are First Name, Last Name, Phone Number, Date of birth, Nationality, Address, Email and Subject(s) (subjects separated by commas if a teacher teaches more than one subject)"} >
                        <Link to={"#"}>
                            <img src={manage_teacher_icon_question_mark} alt="Question Mark" style={{ width: '20px', height: '22px' }}  />
                        </Link>
                        </TeacherManagementTooltip>
                    </div>
                </div>
               
                <div>
                    <form onSubmit={handleUpload}>
                        <div className="manage_teachers_addteacher_bulk_form_item">
                            <div>
                                <label>Upload the Excel file</label>
                                <input
                                    type="file"
                                    accept=".csv, .xlsx"
                                    onChange={handleFileChange}
                                 />

                            </div>
                        </div>
                        <div className="manage_teachers_addteacher_form_submit_button_container">
                            <button type='submit' className="manage_teachers_addteacher_form_submit_button">
                                Upload
                            </button>
                        </div>
                    </form>
                </div>

            </div>

}