import { useLocation } from 'react-router-dom';
import AdminLink from "../widgets/AdminLink";

const ParentPortalSideBar = () => {
  const location = useLocation(); // get the current URL path

  return (
    <div className="left_side_nav">
      <AdminLink
        url_link="/parent"
        icon_url={"url('/images/chart.png')"}
        description="Dashboard"
        is_active={location.pathname === "/parent"} // set active state
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/parent/students"
        icon_url={"url('/images/student_avatar.png')"}
        description="Student"
        is_active={location.pathname === "/parent/students"}
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/parent/school-communication"
        icon_url={"url('/images/comments_speech.png')"}
        description="School Communication"
        is_active={location.pathname === "/parent/communications_centre"}
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/parent/fees_management"
        icon_url={"url('/images/profile.png')"}
        description="Fees Management"
        is_active={location.pathname === "/parent/fees_management"}
        is_sidebar_link={true}
      />
      <AdminLink
        url_link="/parent/settings_section"
        icon_url={"url('/images/document.png')"}
        description="Settings"
        is_active={location.pathname === "/parent/settings_section"}
        is_sidebar_link={true}
      />
    </div>
  );
};

export default ParentPortalSideBar;
