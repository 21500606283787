import { useState } from "react";
import cancelbutton from "../../../svg/cancelbutton.svg";
import TeacherPortalAddPaper from "./teacherPortalAddPaper";

export default function TeacherPortalCreateExamForm({ onclose }) {
    const [closeProfile, setCloseProfile] = useState("block");
    const [toggleDisplayAddPaper, setToggleDisplayAddPaper] = useState(false);
    const [examID, setExamID] = useState(null);

    const showAddPaper = (exam_id) => {
        setToggleDisplayAddPaper(true);
        setExamID(exam_id);
    }

    const closeStudentProfile = () => {
        setCloseProfile("none");
        onclose()
    }
    return <div style={{ display: `${closeProfile}` }}>
        <div className='teacher-portal-student-profile-main-container'>
            {toggleDisplayAddPaper == true ? <TeacherPortalAddPaper examID={examID} /> : ""}
            <div className='teacher-portal-student-profile'>
                <div>Create an exam</div>
                <div><img onClick={closeStudentProfile} src={cancelbutton} /></div>
            </div>
            <div className='teacher-portal-exam-management-container'>
                <div>
                    <form>
                        <div>
                            <div>
                                <label>Course/Subject</label>
                                <input placeholder="Select Course/Subject" type="text" />
                            </div>
                            <div>
                                <label>Class(es) taking the exam</label>
                                <input placeholder="Select class(es) taking the exam" type="text" />
                            </div>
                            <div>
                                <label>Number of papers</label>
                                <input placeholder="Enter the number of papers" type="text" />
                            </div>
                            <div>
                                <label>Academic Year</label>
                                <input placeholder="Enter the academic year" type="text" />
                            </div>
                            <div>
                                <label>Term</label>
                                <input placeholder="Enter the term" type="text" />
                            </div>
                            <div>
                                <label>Add a paper</label>
                                <div onClick={() => { showAddPaper(8888) }} className="teacher-portal-plus-icon">+</div>
                            </div>
                            <div>
                                <label>List of papers</label>
                                <select>
                                    <option>Physics Paper 1</option>
                                    <option>Physics Paper 1</option>
                                    <option>Physics Paper 1</option>
                                </select>
                            </div>
                        </div>
                        <div className="teacher-portal-form-submit-container">
                            <button className="teacher-portal-form-submit">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
}