import React from "react";
// import Login from "./pages/auth/Login";
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from "react-router-dom";
import EnrolYourSchool from "./pages/EnrolYourSchool";
import SchoolEnquiries from "./pages/superadmin/enquiries/schoolsEnquiries/schoolEnquiry";
import MainDashboard from "./pages/superadmin/mainDashboard/mainDashboard";
import LandingPage from "./pages/HomePage/LandingPage";
import Admin from "./pages/Admin";
import TeacherManagement from "./pages/schoolAdmin/teacherManagement/teacherManagement"
import SchoolEnrolmentRequests from "./pages/superadmin/schoolEnrolment/schoolEnrolmentRequests";
import LoginForm from "./pages/auth/loginService";
import ForgotPassword from "./pages/auth/forgotPassword";

import StudentManagement from "./pages/schoolAdmin/studentManagement/admin_student_dashboard";
import AdminParentManagement from "./pages/schoolAdmin/adminParentManagement/adminParentManagement";
import ParentPortal from "./pages/parentPortal/parent_portal";
import ParentStudents from "./pages/parentPortal/parentStudentMgt";
import ParentCommunicationPortal from "./pages/parentPortal/schoolCommunication/communicationPortal";
import ConfirmOTP from "./pages/auth/confirmOTP";
import CreateNewPassword from "./pages/auth/createnewpassword";
import ResetpasswordFailureOrSuccess from "./pages/auth/resetpasswordFailureOrSuccess";
import LogoutButton from "./pages/auth/logout";
import StudentPortal from "./pages/studentPortal/studentDashboard";
import StudentPortalSubject from "./pages/studentPortal/subjectList";
import ExamTimeTable from "./pages/studentPortal/examTimeTable";
import StudentPortalReportCard from "./pages/studentPortal/studentReportCard";
import ComingSoon from "./pages/comingSoon404/comingSoon";

import Error404 from "./pages/comingSoon404/error404";
import TeacherPortal from "./pages/teacherPortal/TeacherPortal";
import TeacherPortalTeacherManagement from "./pages/teacherPortal/TeacherPortalClassManagement";
import TeacherPortalStudentManagement from "./pages/teacherPortal/teacherPortalComponents/teacherPortalStudentManagement";
import TeacherPortalExamManagement from "./pages/teacherPortal/teacherPortalExamManagement";
import TeacherPortalGradingResults from "./pages/teacherPortal/TeacherPortalGradingResults";
import TeacherPortalUploadResults from "./pages/teacherPortal/TeacherPortalUploadResults";
import TeacherPortalSettings from "./pages/teacherPortal/TeacherPortalSettings";

import ExamManagement from "./pages/schoolAdmin/examManagement/examManagement";    
import ManageSchoolInfo from "./pages/schoolAdmin/schoolInformationManagement/manageSchoolInfo";  
import DynamicProfile from "./pages/dynamicSharedPages/DynamicProfile";  
import DynamicSettings from "./pages/dynamicSharedPages/DynamicSettings"; 
import RegisterParent from "./pages/parentRegistration/RegisterParent"; 
import ResultsManagement from "./pages/schoolAdmin/resultsManagement/manageResults";
import ClassManagement from "./pages/schoolAdmin/classManagement/classManagement"; 
import HeroSection from "./pages/HomePage/hero_section";  




const App = () => {
  

  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} /> To be added */}
        <Route path="/" element={<LandingPage />} /> {/* To be added */}
        {/* <Route path="/raise_an_enquiry" element={<RaiseAnEnquiry />} /> */}{" "}
        {/* To be added */}

        <Route path="/enrol-your-school" element={<EnrolYourSchool />} />
        <Route
          path="/superadmin-dashboard/enquiries-from-schools"
          element={<SchoolEnquiries />}
        />
        <Route path="/superadmin-dashboard" element={<MainDashboard />} />
        
        <Route path="/admin/teacher-management" element={<TeacherManagement />} />
        <Route path="/admin/student-management" element={<StudentManagement />} />
        <Route path="/admin/parent-management" element={<AdminParentManagement />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirmotp" element={<ConfirmOTP />} />
        <Route path="/createnewpassword" element={<CreateNewPassword />} />
        <Route path="/resetpasswordresult" element={<ResetpasswordFailureOrSuccess />} /> 

        <Route path="/admin" element={<Admin />} /> 
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/error-404" element={<Error404  />} />
        <Route path="/superadmin-dashboard/school-enrolment-requests" element={<SchoolEnrolmentRequests/>} />
        <Route path="login" element={<LoginForm/>} />
        <Route path="logout" element={<LogoutButton/>} />
        <Route path="/school-admin/student-management" element={<StudentManagement/>} />
        <Route path="/parent" element={<ParentPortal/>} />
        <Route path="/parent/students" element={<ParentStudents/>} />
        <Route path="/parent/school-communication" element={<ParentCommunicationPortal/>} />
        <Route path="/enrol_your_school" element={<EnrolYourSchool/>} />

        
        <Route path="/teacher-portal" element={<Admin />} />
        {/* <Route path="/teacher-portal" element={<TeacherPortal/>} /> */}
        <Route path="/teacher-portal/class-management" element={<TeacherPortalTeacherManagement/>} />
        <Route path="/teacher-portal/student-management" element={<TeacherPortalStudentManagement/>} />
        <Route path="/teacher-portal/exam-management" element={<TeacherPortalExamManagement/>} />
        <Route path="/teacher-portal/grading-results" element={<TeacherPortalGradingResults/>} />
        <Route path="/teacher-portal/uploading-results" element={<TeacherPortalUploadResults/>} />
        <Route path="/teacher-portal/settings" element={<TeacherPortalSettings/>} />

        <Route path="/student-portal" element={<StudentPortal/>} />
        <Route path="/sudent-portal/subjects" element={<StudentPortalSubject/>} />
        <Route path="/sudent-portal/exam-time-table" element={<ExamTimeTable/>} />
        <Route path="/sudent-portal/report-card" element={<StudentPortalReportCard/>} />
        
        

        <Route path="/admin" element={<Admin />} />  
        <Route path="/superadmin/profile_section" element={<DynamicProfile />} />
        <Route path="/admin/profile_section" element={<DynamicProfile />} />
        <Route path="/teacher/profile_section" element={<DynamicProfile />} />
        <Route path="/student/profile_section" element={<DynamicProfile />} />
        <Route path="/parent/profile_section" element={<DynamicProfile />} /> 
        {/* <Route path="/student/profile" element={<DynamicProfile />} />
        <Route path="/teacher/profile" element={<DynamicProfile />} />
        <Route path="/parent/profile" element={<DynamicProfile />} /> */}  

        <Route path="/superadmin/settings_section" element={<DynamicSettings />} />
        <Route path="/admin/settings_section" element={<DynamicSettings />} />
        <Route path="/teacher/settings_section" element={<DynamicSettings />} />
        <Route path="/student/settings_section" element={<DynamicSettings />} />
        <Route path="/parent/settings_section" element={<DynamicSettings />} />
        {/* <Route path="/student/profile" element={<DynamicProfile />} />
        <Route path="/teacher/profile" element={<DynamicProfile />} />
        <Route path="/parent/profile" element={<DynamicProfile />} /> */}  
        <Route path="/coming-soon" element={<ComingSoon />} />   
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/superadmin-dashboard/school-enrolment-requests" element={<SchoolEnrolmentRequests />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="logout" element={<LogoutButton />} />
        <Route path="/school-admin/student-management" element={<StudentManagement />} />
        <Route path="/parent" element={<ParentPortal />} />
        <Route path="/parent/students" element={<ParentStudents />} />
        <Route path="/parent/school-communication" element={<ParentCommunicationPortal />} />
        <Route path="/enrol_your_school" element={<EnrolYourSchool />} />
        <Route path="/student-portal" element={<StudentPortal />} />
        <Route path="/sudent-portal/subjects" element={<StudentPortalSubject />} />
        <Route path="/sudent-portal/exam-time-table" element={<ExamTimeTable />} />
        <Route path="/sudent-portal/report-card" element={<StudentPortalReportCard />} />
        <Route path="/admin/menage-school-info" element={<ManageSchoolInfo />} />
        <Route path="/results-management" element={<ResultsManagement />} /> 
        <Route path="/admin/class-management" element={<ClassManagement />} /> 
        <Route path="/admin/exam-management" element={<ExamManagement />} />   
        {/* <Route path="/parent-registration" element={<RegisterParent />} />   */}
        <Route path="/:dynamicPath" element={<RegisterParent />} />  

        {/* <Route path="/raise_an_enquiry" element={<RaiseAnEnquiry />} /> */}{" "}

        {/* <Route path="/about" element={<About />} /> */} {/* To be added */}
        {/* To be added */}

        {/* <Route path="/contact" element={<Contact />} /> */}{" "}
        {/* To be added */}
        {/* <Route path="/terms" element={<Terms />} /> */} {/* To be added */}
        {/* <Route path="/privacy" element={<Privacy />} /> */}{" "}
        {/* To be added */}
        {/* <Route path="*" element={<NotFound />} /> */} {/* To be added */}
      
      </Routes>
    </Router>
  );
};

export default App;

