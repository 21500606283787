import "../../css/dynamic_profile.css";
import AdminLeftSidebarNav from "../../components/layout/AdminLeftSidebarNav";  
import Footer from "../../components/layout/Footer";
import Nav from "../../components/layout/Nav"; 
import ParentPortalSideBar from "../../components/layout/parentPortalSideBar";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import Sidebar from "../../components/layout/sideBar";
import StudentPortalSideBar from "../../components/layout/studentPortalSideBar";

const DynamicProfile = () => {
  const role = "ADMIN";
  const profileData = {
    profile_pic_url: "https://picsum.photos/seed/picsum/200/300",
    fname: "Jane",
    lname: "Doe",
    phone: "+263782773709",
    email: "janed@gmail.com",
    dob: "07/11/2009",
    school_logo_url: "https://picsum.photos/id/14/200/300"
  }; // API integration: this would be fetched and stored in state using Redux toolkit

  const full_name = `${profileData["fname"]} ${profileData["lname"]}`;

  return (
    <div className="dynamic_profile">
      {role === "PARENT" || role === "SUPER_ADMIN" ? ( 
        <SchoolCommonNav 
          school_logo_url={"/images/logo_container.svg"}
          school_name={"aassaas"}
          user_name={full_name}
          user_profile_pic={profileData["profile_pic_url"]}
          are_there_new_notifications={false}
        />
        ) : (
          <SchoolCommonNav
            school_logo_url={profileData["school_logo_url"]}
            school_name={"aassaas"}
            user_name={full_name}
            user_profile_pic={profileData["profile_pic_url"]}
            are_there_new_notifications={false}
          />
        )
      }
      <div className="left_nav_and_main_content_container"> 
        {role === "SUPER_ADMIN" ?
          <Sidebar />
          : role === "ADMIN" ? 
          <AdminLeftSidebarNav /> :
          // role === "TEACHER" ? not yet developed
          // <TeacherLeftSidebarNav /> not yet developed
          role === "STUDENT" ?
          <StudentPortalSideBar /> :
          <ParentPortalSideBar />
        }
        <div className="dynamic_profile_content">
          <h1>Profile Section</h1>
          <div className="dynamic_profile_content__container">
            <div className="profile_pic_container">
              <img src={profileData.profile_pic_url} alt="Profile Pic" />
            </div>
            {Object.keys(profileData)
              .filter(
                (data) => !(data === "profile_pic_url" || data === "school_logo_url")
              )
              .map((data) =>
                !(data === "fname" || data === "lname") ? (
                  <div
                    key={data}
                    className="dynamic_profile_content__data_container"
                  >
                    {data === "email"
                      ? "Email:"
                      : data === "phone"
                      ? "Phone number:"
                      : data === "dob"
                      ? "Date of birth:"
                      : data}
                    &nbsp;{profileData[data]}
                  </div>
                ) : data === "lname" ? (
                  <div
                    key={"full_name"}
                    className="dynamic_profile_content__data_container"
                  >
                    {"Name:"}
                    &nbsp;{`${profileData["fname"]} ${profileData["lname"]}`}
                  </div>
                ) : (
                  ""
                )
              )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DynamicProfile;
