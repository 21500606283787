import approve from '../components/icons/approve.png';
import avatar from '../components/icons/avatar.png';
import edit from '../components/icons/edit.png';
import enquiry from '../components/icons/enquiry.png';
import stats from '../components/icons/stats.png';
import enrollment from '../components/icons/enrollment.png';
import get_start from '../components/icons/get-start.png';
import eye from '../components/icons/eye.png';
import reject from '../components/icons/reject.png';
import enrollment_black from '../components/icons/enrollment-black.png';
import users from '../components/icons/users.png';
import schools from '../components/icons/schools.png';
import students from '../components/icons/stedents.png';
import notif from '../components/icons/notif.png';
import settings_icon from '../components/icons/settings-icon.png';
import profile from '../components/icons/profile.png';
import menu from '../components/icons/menu.png';
import logout from '../components/icons/logout.png';
import graduate from '../components/icons/graduate.png';
import school_premise from '../components/icons/school-premise.png';
import classroom_icon from '../components/icons/classroom-icon.png';
import book_icon from '../components/icons/book-icon.png';
import report_card from '../components/icons/report-card.png';
import graduate_cap from '../components/icons/graduate-cap.png';
import exam_ico from '../components/icons/exam-icon.png';
import subject_icon from '../components/icons/subject-image.png';
import class_icon from '../components/icons/classroom-icon.png';
import teacher_icon from '../components/icons/class-teacher.png';





export const imageIcons = {
    approve,
    avatar,
    edit,
    enquiry,
    stats,
    enrollment,
    get_start,
    eye,
    reject,
    enrollment_black,
    users,
    schools,
    students,
    notif,
    settings_icon,
    profile,
    menu,
    logout,
    graduate,
    school_premise,
    classroom_icon,
    book_icon,
    report_card,
    graduate_cap,
    exam_ico,
    subject_icon,
    class_icon,
    teacher_icon
  };