// ApproveEnrolmentModal component
import React, { useState } from 'react';
import '../../../css/mainDashboard.css';
import { CircularProgress } from '@mui/material';

const ApproveEnrolmentModal = ({ isOpen, onClose, onApprove, loading }) => {
    const [feedback, setFeedback] = useState('');
    const [senderID, setSenderID] = useState('');

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleSenderID = (e) => {
        setSenderID(e.target.value);
    };

    const handleApproveClick = () => {
        onApprove(feedback);
    };

    if (!isOpen) return null;

    return (
        <div className='divOverlay'>
            <div className='divModal'>
                <div className='modalHeader'>
                    <h2>Approve Enrolment Request</h2>
                    <span className='closeIcon' onClick={onClose}>
                        &times;
                    </span>
                </div>
                <textarea
                    className='divTextArea'
                    value={feedback}
                    onChange={handleFeedbackChange}
                    placeholder="Feedback after approval"
                />
                <input
                    className=''
                    value={senderID}
                    onChange={handleSenderID}
                    placeholder="SMS Sender ID"
                /><br/><br/>

                <button
                    className='approveButton'
                    onClick={handleApproveClick}
                    disabled={loading}
                >
                    {loading ? "Please wait..." : "Approve Request"}
                    {loading && (
                        <CircularProgress size={20} className="buttonProgressIndicator" />
                    )}
                </button>
            </div>
        </div>
    );
};

export default ApproveEnrolmentModal;
