import { Link } from "react-router-dom"
import React, { useState } from 'react';
import LogoSvg from "../../svg/LogoSvg"
import './../../css/navBar.css';
import { imageIcons } from "../imageComponents";
import { token, firstName, lastName } from "../../globalConfigs/userLoginInfo";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log("User logged out");
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/superadmin-dashboard"><LogoSvg /></Link>
      </div>

      <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="navbar-item">
          <img src={imageIcons.notif} alt="Notifications" className="navBarIcons"/>
        </div>
        <div className="navbar-item">
          <img src={imageIcons.settings_icon} alt="Settings" className="navBarIcons" />
        </div>
        <div className="navbar-item avatar-dropdown" onClick={toggleDropdown}>
          <img src={imageIcons.avatar} alt="Avatar" className="navBarIcons" />
          <p>{ firstName }</p>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <Link to="/profile" className="dropdown-item">
                <img src={imageIcons.profile} alt="Profile" className="navBarNotif" />
                Profile
              </Link>
              <hr />
              <div className="dropdown-item" onClick={handleLogout}>
                <img src={imageIcons.logout} alt="Logout" className="navBarNotif" />
                Logout
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="menu-toggle" onClick={toggleMenu}>
        <img src={imageIcons.menu} alt="Menu" className="navBarIcons" />
      </div>
    </nav>
  );
};

export default NavBar;
