import ParentPortalSideBar from "../../../components/layout/parentPortalSideBar";
import Footer from "../../../components/layout/Footer";
import SchoolCommonNav from "../../../components/layout/SchoolCommonNav";
import "../../../css/admin.css";
import ParentCommunicationWidget from "./communicationWidget";

const ParentCommunicationPortal = () => {
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED 

  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={"/images/logo_container.svg"}
        school_name={""}
        user_name={
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("lastName")
        }
        user_profile_pic={""}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <ParentPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>School Communication</h1>
            <div className="info">
              <ParentCommunicationWidget
                heading="Parent teacher day announcement"
                date="Monday 25 Nov 2024 8:30AM"
                school="Kenda Mission"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default ParentCommunicationPortal;
