// src/components/GradingPopup.js
import React, { useEffect, useState } from 'react';
import '../../../../css/manageResults.css';

const GradingPopup = ({ isOpen, onClose, className, examTitle, totalMarks }) => {
  const [students, setStudents] = useState([]);
  const [marks, setMarks] = useState({});

  // Fetch students list for the given class when the popup opens
  useEffect(() => {
    if (isOpen) {
      // Replace with your API call to fetch students for the selected class
      fetch(`/api/classes/${className}/students`)
        .then((response) => response.json())
        .then((data) => {
          setStudents(data);
          // Initialize marks for each student with an empty string
          const initialMarks = {};
          data.forEach((student) => {
            initialMarks[student.id] = '';
          });
          setMarks(initialMarks);
        })
        .catch((error) => console.error('Error fetching students:', error));
    }
  }, [isOpen, className]);

  const handleMarkChange = (studentId, value) => {
    setMarks((prevMarks) => ({ ...prevMarks, [studentId]: value }));
  };

  const handleSubmit = () => {
    // Here you would send the marks to your backend or perform any necessary logic
    console.log('Submitted marks:', marks);
    onClose(); // Close the popup after submission
  };

  if (!isOpen) return null;

  return (
    <div className="grading-popup-overlay">
      <div className="grading-popup">
        <button className="close-btn" onClick={onClose}>✕</button>
        <h2>Grading results for <span>{examTitle}</span></h2>
        <p><strong>Class:</strong> <span>{className}</span></p>
        <p><strong>Total Mark:</strong> <span>{totalMarks}</span></p>
        <div className="student-marks-table">
          <table>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Mark</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>{student.name}</td>
                  <td>
                    <input
                      type="number"
                      placeholder="Enter mark"
                      value={marks[student.id]}
                      onChange={(e) => handleMarkChange(student.id, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="submit-btn" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default GradingPopup;
