import React, { useState, useRef } from 'react';
import  "../../../../css/teacherManagement.css";

const TeachermanegementTooltip = ({ children, text }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <div
      className="manage_teachers_tooltip_container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div className="manage_teachers_tooltip" ref={tooltipRef}>
          {text}
        </div>
      )}
    </div>
  );
};

export default TeachermanegementTooltip;