import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import '../../../../css/classManagement.css';
import { API_URL } from '../../../../globalConfigs/apiConfigs';
import { schoolID, token } from '../../../../globalConfigs/userLoginInfo';

const CreateClassPopup = ({ isOpen, onClose }) => {
    const [selectedGrade, setSelectedGrade] = useState('');
    const [className, setClassName] = useState('');
    const [error, setError] = useState('');
    const [grades, setGrades] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchGrades = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/levels`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setGrades(data);
            } catch (err) {
                console.error('Error fetching grades:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchGrades();
    }, []);

    if (!isOpen) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedGrade || !className) {
            setError('Please fill in all fields');
            return;
        }
        setError('');
        setSubmitting(true);

        try {
            const response = await fetch(`${API_URL}/api/v1/schools/${schoolID}/add/schoolClasses`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify([{
                    name: className,
                    levelId: selectedGrade,
                }]),
            });

            console.log(
                JSON.stringify({
                    name: className,
                    levelId: selectedGrade,
                })
            )

            console.log(response.status);



            if (response.ok) {
                console.log('Class created successfully');
                onClose(); // Close the popup on success
            } else {
                const errorData = await response.json();
                console.log(errorData);
                console.error('Error creating class:', errorData);
                setError('Failed to create class');
            }
        } catch (err) {
            console.error('Error creating class:', err);
            setError('Failed to create class');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <button className="popup-close-btn" onClick={onClose}>✕</button>
                <h2>Create a class</h2>
                <form className="popup-form" onSubmit={handleSubmit}>
                    <div className="popup-input">
                        <label>Select level/grade</label>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <select
                                value={selectedGrade}
                                onChange={(e) => setSelectedGrade(e.target.value)}
                            >
                                <option value="">Select the level/grade</option>
                                {grades.map((grade) => (
                                    <option key={grade.levelGradeId} value={grade.levelGradeId}>
                                        {grade.name}
                                    </option>
                                ))}
                            </select>
                        )}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                    <div className="popup-input">
                        <label>Class name</label>
                        <input 
                            type="text" 
                            placeholder="Enter the name of the class" 
                            value={className}
                            onChange={(e) => setClassName(e.target.value)} 
                        />
                    </div>
                    <button type="submit" className="popup-submit-btn" disabled={!selectedGrade || submitting}>
                        {submitting ? <CircularProgress size={24} color='white'/> : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateClassPopup;
