import React, { useState } from 'react';
import '../../../../css/schoolInfo.css';
import { submitLevelGrade, submitTermData, submitDept, submitSub } from '../../../../controllers/schoolAdmin/schoolInfoAPI';
import { CircularProgress } from '@mui/material';
import SuccessErrorModal from '../../../../components/widgets/SuccessErrorModal';

const SchoolPopup = ({ contentType, closeModal, loading }) => {

  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState(false);
  const [hasFormSubmittedSuccessfully, setHasFormSubmittedSuccessfully] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [selectedTerm, setSelectedTerm] = useState('');
  const [setLevel, setGradeLevel] = useState('');
  const [setGrade, setGradeScale] = useState('');
  const [setMinScore, setMinimumScore] = useState('');
  const [setMaxScore, setMaximumScore] = useState('');

  const [setGradeB, setGradeScaleB] = useState('');
  const [setMinScoreB, setMinimumScoreB] = useState('');
  const [setMaxScoreB, setMaximumScoreB] = useState('');

  const [setGradeC, setGradeScaleC] = useState('');
  const [setMinScoreC, setMinimumScoreC] = useState('');
  const [setMaxScoreC, setMaximumScoreC] = useState('');

  const [setGradeD, setGradeScaleD] = useState('');
  const [setMinScoreD, setMinimumScoreD] = useState('');
  const [setMaxScoreD, setMaximumScoreD] = useState('');

  const [setGradeE, setGradeScaleE] = useState('');
  const [setMinScoreE, setMinimumScoreE] = useState('');
  const [setMaxScoreE, setMaximumScoreE] = useState('');

  const [setGradeF, setGradeScaleF] = useState('');
  const [setMinScoreF, setMinimumScoreF] = useState('');
  const [setMaxScoreF, setMaximumScoreF] = useState('');

  const currentYear = new Date().getFullYear();
  const [dept, setDept] = useState('');
  const [sub, setSubject] = useState('');



  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (contentType === 'term' && selectedTerm) {
      setIsLoading(true);  // Show the progress indicator
      const termData = [
        {
          name: selectedTerm,
          startDate: "2024-11-01T14:59:01.183Z", // Adjust as needed
          endDate: "2024-11-01T14:59:01.183Z", // Adjust as needed
          status: "APPROVED"
        }
      ];
      try {
        const response = await submitTermData(termData);
        console.log(response.length);
        closeModal();
        setHasFormSubmittedSuccessfully(response.length >= 1);
        setModalMessage(response.length >= 1 ? 'Term submitted successfully!' : 'Failed to submit term');
        setShowSuccessErrorModal(true);

      } catch (error) {
        console.error('Failed to submit term:', error);
      } finally {
        setIsLoading(false);  // Hide the progress indicator
      }
    } else if (contentType === 'level' && setLevel) {
      setIsLoading(true);
      const levelData = {
        "name": setLevel,
        "description": "zdzfsdzbsgxcV nag",
        "gradingScales": [
          {
            "grade": setGrade,
            "minScore": setMinScore,
            "maxScore": setMaxScore
          },
          {
            "grade": setGradeB,
            "minScore": setMinScoreB,
            "maxScore": setMaxScoreB
          },
          {
            "grade": setGradeC,
            "minScore": setMinScoreC,
            "maxScore": setMaxScoreC
          },
          {
            "grade": setGradeD,
            "minScore": setMinScoreD,
            "maxScore": setMaxScoreD
          },
          {
            "grade": setGradeE,
            "minScore": setMinScoreE,
            "maxScore": setMaxScoreE
          },
          {
            "grade": setGradeF,
            "minScore": setMinScoreF,
            "maxScore": setMaxScoreF
          }
        ]
      }

      console.log(levelData)

      try {
        const response = await submitLevelGrade(levelData);
        console.log(response.length);
        setHasFormSubmittedSuccessfully(response.length >= 1);
        setModalMessage(response.length >= 1 ? 'Term submitted successfully!' : 'Failed to submit term');
        if (response.length >= 1) {
          closeModal();
          setShowSuccessErrorModal(true);

        } else {
          closeModal();
          setShowSuccessErrorModal(true);

        }

      } catch (err) {
        console.log(`Error: ${err}`)
      } finally {
        setIsLoading(false);
      }
    } else if (contentType === 'department' && setDept) {
      // Load a department
      setIsLoading(true);  // Show the progress indicator
      const deptData = [
        {
          name: dept,
        }
      ];
      try {
        const response = await submitDept(deptData);
        closeModal();
        setHasFormSubmittedSuccessfully(response.length >= 1);
        setModalMessage(response.length >= 1 ? 'Term submitted successfully!' : 'Failed to submit term');
        setShowSuccessErrorModal(true);

      } catch (error) {
        console.error('Failed to submit term:', error);
      } finally {
        setIsLoading(false);  // Hide the progress indicator
      }
    }else if (contentType === 'subject' && setSubject) {
      // Load a department
      setIsLoading(true);  // Show the progress indicator
      const subData = [
        {
          subjectName: sub,
        }
      ];
      try {
        const response = await submitSub(subData);
        closeModal();
        setHasFormSubmittedSuccessfully(response.length >= 1);
        setModalMessage(response.length >= 1 ? 'Subject submitted successfully!' : 'Failed to submit term');
        setShowSuccessErrorModal(true);

      } catch (error) {
        console.error('Failed to submit subject:', error);
      } finally {
        setIsLoading(false);  // Hide the progress indicator
      }
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>
            {contentType === 'academicYear' && 'Create academic year'}
            {contentType === 'term' && 'Create term'}
            {contentType === 'department' && 'Create department'}
            {contentType === 'level' && 'Create level/grade'}
          </h2>
          <button className="close-btn" onClick={closeModal}>✖</button>
        </div>

        <div className="popup-body">
          {contentType === 'academicYear' && (
            <>
              <div className="popup-section">
                <label>Current academic year:</label>
                <div className="current-year-box">
                  <span>{currentYear}</span>
                </div>
              </div>
              <div className="popup-section">
                <label>Select academic year:</label>
                <input className='fieldStyle' type="text" placeholder="Select academic year" />
              </div>
            </>
          )}

          {contentType === 'term' && (
            <>
              <div className="popup-section">
                <label>Current term:</label>
                <div className="current-term-box">
                  <span>Term 3</span>
                </div>
              </div>
              <div className="popup-section">
                <label>Select term:</label>
                <select className='fieldStyle' value={selectedTerm} onChange={e => setSelectedTerm(e.target.value)}>
                  <option value="">Select term</option>
                  <option value="Term 1">Term 1</option>
                  <option value="Term 2">Term 2</option>
                  <option value="Term 3">Term 3</option>
                </select>
              </div>
            </>
          )}

          {contentType === 'department' && (
            <>
              <div className="popup-section">
                <label>Department name:</label>
                <input className='fieldStyle' type="text" placeholder="Enter department name" onChange={(e) => setDept(e.target.value)} />
              </div>
            </>
          )}

          {contentType === 'subject' && (
            <>
              <div className="popup-section">
                <label>Subject name:</label>
                <input className='fieldStyle' type="text" placeholder="Enter department name" onChange={(e) => setSubject(e.target.value)} />
              </div>
            </>
          )}

          {contentType === 'level' && (
            <>

              <div className="popup-section">
                <label>Enter Level/Grade name:</label>
                <input className='fieldStyle' type="text" onChange={(e) => setGradeLevel(e.target.value)} />

                <br></br><br></br>
                <hr />
                <div className="three-column-row">
                  <div className="column">
                    <label>Grade Scale:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='e.g A'
                      onChange={(e) => setGradeScale(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Min Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMinimumScore(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Max Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMaximumScore(e.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <div className="three-column-row">
                  <div className="column">
                    <label>Grade Scale:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='e.g B'
                      onChange={(e) => setGradeScaleB(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Min Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMinimumScoreB(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Max Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMaximumScoreB(e.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <div className="three-column-row">
                  <div className="column">
                    <label>Grade Scale:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='e.g C'
                      onChange={(e) => setGradeScaleC(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Min Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMinimumScoreC(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Max Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMaximumScoreC(e.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <div className="three-column-row">
                  <div className="column">
                    <label>Grade Scale:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='e.g D'
                      onChange={(e) => setGradeScaleD(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Min Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMinimumScoreD(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Max Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMaximumScoreD(e.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <div className="three-column-row">
                  <div className="column">
                    <label>Grade Scale:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='e.g E'
                      onChange={(e) => setGradeScaleE(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Min Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMinimumScoreE(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Max Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMaximumScoreE(e.target.value)}
                    />
                  </div>
                </div>
                <hr />
                <div className="three-column-row">
                  <div className="column">
                    <label>Grade Scale:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='e.g F'
                      onChange={(e) => setGradeScaleF(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Min Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMinimumScoreF(e.target.value)}

                    />
                  </div>
                  <div className="column">
                    <label>Max Score:</label>
                    <input
                      type="text"
                      className="fieldStyle"
                      placeholder='enter %'
                      onChange={(e) => setMaximumScoreF(e.target.value)}
                    />
                  </div>
                </div>


              </div>
            </>
          )}
        </div>

        <div className="popup-footer">
          <button className="submit-btn" onClick={handleSubmit}>

            {isLoading ? "Please wait..." : "Submit"}
            {isLoading && (
              <CircularProgress size={20} color='white' className="buttonProgressIndicator" />
            )}

          </button>
        </div>

      </div>
      {showSuccessErrorModal && (
        <SuccessErrorModal
          isSuccess={hasFormSubmittedSuccessfully}
          message={modalMessage}
          setShowSuccessErrorModal={setShowSuccessErrorModal}
          hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully}
        />
      )}
    </div>
  );
};

export default SchoolPopup;
