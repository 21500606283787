import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/nav.css";
import AdminLink from "../widgets/AdminLink";

const SchoolCommonNav = ({
  school_logo_url,
  school_name,
  user_name,
  user_profile_pic,
  are_there_new_notifications,
}) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false)
  const styles = {
    hamburger_styles: {
      backgroundImage: `url('/images/${
        isMobileMenuVisible ? "close_" : ""
      }hamburger_wt.png')`,
      backgroundSize: "70%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  };

  const { hamburger_styles } = styles;

  const navigate = useNavigate()

  return (
    <div className="school_common_nav nav">
      <div className="left">
        <Link to="/">
          <img src={school_logo_url} alt={`${school_name} logo`} />
        </Link>
        <button
          className="hamburger"
          style={hamburger_styles}
          onClick={(e) => {
            e.target.parentElement.parentElement
              .querySelector(".right")
              .classList.toggle("displayed_flex");
            setIsMobileMenuVisible(!isMobileMenuVisible);
            setIsDropDownVisible(false)
          }}
        ></button>
      </div>
      <div className="right">
        <button className="right_btn notifications">
          <span className="icon">
            <span
              className={`notifications__indicator${
                are_there_new_notifications ? " displayed_flex" : ""
              }`}
            ></span>
          </span>
          <span>Notifications</span>
        </button>
        <button className="right_btn settings"
          onClick={() => {
            navigate("settings")
          }}
        >
          <span className="icon"></span>
          <span>Settings</span>
        </button>
        <button className="profile_pic_user_name_container"
          onClick={() => {
            setIsDropDownVisible(!isDropDownVisible)
          }}
        >
          <span className="profile_pic">
            <img src={`${user_profile_pic} ? ${user_profile_pic} : /images/avatar.png`} alt="" />
          </span>
          <span className="user_name">{user_name}</span>
        </button>
      </div>
      <div className={`dropdown${isDropDownVisible ? ' displayed_flex' : ''}`}>
        <AdminLink
          url_link="/admin/profile_section"
          icon_url={"url('/images/profile.png')"}
          description="Profile"
          is_sidebar_link={true}
          to={"profile"}
        />
        <AdminLink
          url_link="/admin/settings_section"
          icon_url={"url('/images/gear_solid.png')"}
          description="Settings"
          is_sidebar_link={true}
          to={"settings"}
        />
        <AdminLink
          url_link="/logout"
          icon_url={"url('/images/logout.png')"}
          description="Logout"
          is_sidebar_link={true}
        />
      </div>
    </div>
  );
};

export default SchoolCommonNav;
