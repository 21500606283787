import Footer from "../../components/layout/Footer";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import "../../css/admin.css";
import { firstName, lastName } from "../../globalConfigs/userLoginInfo";
import '../../css/studentPortal.css';
import '../../css/teacherPortal.css';
import TeacherPortalSideBar from "../../components/layout/teacherPortalSideBar";
import teacher_icon_eye from "../../svg/eye.svg";
import { useState } from "react";
import TeacherPortalStudentList from "./teacherPortalComponents/teacherPortalStudentList";
import { alertTitleClasses } from "@mui/material";
import manage_parent_icon_left_arrow from "../../svg/leftarrow.svg";
import manage_parent_icon_right_arrow from "../../svg/rightarrow.svg";
import TeacherPortalCreateExamForm from "./teacherPortalComponents/teacherPortalCreateExamForm";
import TeacherPortalGradingResultsForm from "./teacherPortalComponents/teacherPortalGradingResultsForm";
import TeacherPortalUploadResultsDisplay from "./teacherPortalComponents/teacherPortalUploadResultsDisplay";


const TeacherPortalUploadResults = () => {
  const school_logo_url = "https://picsum.photos/seed/picsum/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const user_profile_pic = "https://picsum.photos/id/28/200/300"; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const are_there_new_notifications = true; //THIS WILL BE FETCHED FROM STATE WHEN REDUX TOOLKIT STATE MANAGEMENT IS IMPLEMENTED
  const [ showgradeExamForm, setShowgradeExamForm] = useState(false);
  const [ overlay, setOverlay] = useState("none");
  const [classID, setClassID] = useState(null);
  
  const showgradeExam = (class_id) => {
    setClassID(class_id);
    setOverlay("block");
    setShowgradeExamForm(true);
  }

  const hideshowgradeExam = () => {
    setShowgradeExamForm(false);
    setOverlay("none");
  }
  return (
    <section className="admin admin_styles">
      <SchoolCommonNav
        school_logo_url={school_logo_url}
        user_name={firstName}
        user_profile_pic={user_profile_pic}
        are_there_new_notifications={are_there_new_notifications}
      />
      <div className="container">
        <TeacherPortalSideBar />
        <div className="content">
          <div className="about_school">
            <h1>Uploading results</h1>

           

            <div className="teacher-portal-table-title">VIew results per subject,  add teacher’s comment and upload</div>


            <div className="teacher-portal-tabs-container">
                <div style={{display: `${overlay}`}} className="teacher-portal-student-list-container">
                       {showgradeExamForm == true ? <TeacherPortalUploadResultsDisplay onclose={hideshowgradeExam} /> : ""}
                </div>
                <div className="teacher-portal-tab">
                     {/* ------------------------------------- */}
                     <div className="teacher-portal-table-container">
                        <table className="teacher-portal-table">
                            <tr>
                                <th>Course/Subject</th>
                                <th>Level/Grade</th>
                                <th>Class(es)</th>
                                <th>View class grades and add teacher’s comment</th>
                                <th>Upload Results</th>
                            </tr>
                            <tr>
                                <td>Data 1</td>
                                <td>Data 2</td>
                                <td>Row 1</td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/grade-exam-pen.png"} /></td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/uploading-results.png"} /></td>
                            </tr>
                            <tr>
                                <td>Row 1</td>
                                <td>Data 1</td>
                                <td>Data 4</td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/grade-exam-pen.png"} /></td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/uploading-results.png"} /></td>
                            </tr>
                            <tr>
                                <td>Row 1</td>
                                <td>Data 1</td>
                                <td>Data 7</td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/grade-exam-pen.png"} /></td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/uploading-results.png"} /></td>
                            </tr>
                            <tr>
                                <td>Row 1</td>
                                <td>Data 1</td>
                                <td>gggg</td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/grade-exam-pen.png"} /></td>
                                <td onClick={() => {showgradeExam(100)}}><img src={"/images/uploading-results.png"} /></td>
            
                            </tr>
                        </table>
                        {/* ------------------------------------- */}
                    </div>

                </div>

            </div>


            {/* ------------------pagination--------------- */}
            <div className='teacher_portal_pagination_container'>
                <div className='teacher_portal_pagination_items_container'>
                        <div className=''><img src={manage_parent_icon_left_arrow} /></div>
                        <div className='teacher_portal_page_items'>1</div>
                        <div className='teacher_portal_page_items'>2</div>
                        <div className='teacher_portal_page_items'>3</div>
                        <div className='teacher_portal_page_items'>4</div>
                        <div className=''><img src={manage_parent_icon_right_arrow} /></div>
                </div>
            </div>
            {/* pagination ends---------------------------- */}
           
          </div>

          


        </div>
        
      </div>
      
      <Footer />
    </section>
  );
};

export default TeacherPortalUploadResults;