import "../../css/dynamic_settings.css";
import AdminLeftSidebarNav from "../../components/layout/AdminLeftSidebarNav";
import Footer from "../../components/layout/Footer";
import Nav from "../../components/layout/Nav";
import ParentPortalSideBar from "../../components/layout/parentPortalSideBar";
import SchoolCommonNav from "../../components/layout/SchoolCommonNav";
import Sidebar from "../../components/layout/sideBar";
import StudentPortalSideBar from "../../components/layout/studentPortalSideBar";
import FormInput from "../../components/widgets/FormInput";
import { useState } from "react";

const DynamicSettings = () => {
  const role = "PARENT";
  const profileData = {
    profile_pic_url: "https://picsum.photos/seed/picsum/200/300",
    fname: "Jane",
    lname: "Doe", 
    email: "janed@gmail.com", 
    school_logo_url: "https://picsum.photos/id/14/200/300",
  }; // API integration: this would be fetched and stored in state using Redux toolkit

  const full_name = `${profileData["fname"]} ${profileData["lname"]}`;
  const [changePasswordFormData, setChangePasswordFormData] = useState({
    password: "",
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  function checkCurrentPassword() {

  }

  function updatePassword() {

  }

  function addInputDataToFormData(
    input,
    input_name,
    setterFunc,  
    formDataObjName,
    showConsoleLog 
  ) {
    setterFunc((prevFormData) => ({
      ...prevFormData,
      [input_name]: input,
    }));
    if (showConsoleLog) console.log(formDataObjName);  
  }

  return (
    <div className="dynamic_settings">
      {role === "PARENT" || role === "SUPER_ADMIN" ? (
        <SchoolCommonNav
          school_logo_url={"/images/logo_container.svg"}
          school_name={"aassaas"}
          user_name={full_name}
          user_profile_pic={profileData["profile_pic_url"]}
          are_there_new_notifications={false}
        />
      ) : (
        <SchoolCommonNav
          school_logo_url={profileData["school_logo_url"]}
          school_name={"aassaas"}
          user_name={full_name}
          user_profile_pic={profileData["profile_pic_url"]}
          are_there_new_notifications={false}
        />
      )}
      <div className="left_nav_and_main_content_container">
        {role === "SUPER_ADMIN" ? (
          <Sidebar />
        ) : role === "ADMIN" ? (
          <AdminLeftSidebarNav />
        ) : // role === "TEACHER" ? not yet developed
        // <TeacherLeftSidebarNav /> not yet developed
        role === "STUDENT" ? (
          <StudentPortalSideBar />
        ) : (
          <ParentPortalSideBar />
        )}
        <div className="dynamic_settings_content">
          <h1>Settings Section</h1>
          <h2>Welcome,&nbsp;{full_name}</h2>
          <div className="dynamic_settings_content__container">
            <form action="#" className="dynamic_settings_content__container__form">
              <h3>Change your password</h3>
              <FormInput
                type="password"
                required
                id={`current_password`}
                placeholder="Enter your current password"
                title="Enter your current password"
                name="current_password"
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                }}
                value={currentPassword}
              />
              <FormInput
                type="password"
                required
                id={`password`}
                placeholder="Enter a new password"
                title="Enter a new password"
                name="password"
                onChange={(e) => {
                  addInputDataToFormData(e.target.value, "password");
                }}
                value={changePasswordFormData?.password}
              />
              <FormInput
                type="password"
                required
                id={`repeat_password`}
                placeholder="Repeat new password"
                title="Repeat new password"
                name="repeat_password"
                onChange={(e) => {
                  setRepeatPassword(e.target.value);
                }}
                value={repeatPassword}
              />
              <button type="submit" className="submit_change_password">Change password</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DynamicSettings;
