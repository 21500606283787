




export const user_name = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
export const firstName = localStorage.getItem('firstName');
export const lastName = localStorage.getItem('lastName');
export const token = localStorage.getItem('token');
export const roles = localStorage.getItem('roles');
export const role = localStorage.getItem('role');
export const schoolID = localStorage.getItem('schoolID') ? localStorage.getItem('schoolID') : "";
export const userID = localStorage.getItem('userID');

export const school_logo_url = localStorage.getItem('logo');
export const school_name = localStorage.getItem('schoolName');
export const are_there_new_notifications = true;
export const school_address = localStorage.getItem('schoolAddress');
export const user_profile_pic = localStorage.getItem('logo');

console.log(token);
console.log(school_name);

//API Headers
export const apiHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};