import React from 'react';
import '../../css/reportCard.css'
import ReportCard from './studentReportCard';// Import your ReportCard component

const ReportCardModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Don't render anything if the modal is closed

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <ReportCard /> {/* Display the report card here */}
      </div>
    </div>
  );
};

export default ReportCardModal;
